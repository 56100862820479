(function(WebAccounts, $, undefined) {    
    if(!WebAccounts.forms.length) {
        return;
    }

    var allFormElements = WebAccounts.forms.find('.form-element');

    if(!allFormElements.length) {
        return;
    }

	/**
	 * DEPRECATED
	 * @param {*} element 
	 * @param {*} elementType 
	 */
	function passwordMeterValidation(element, elementType) {
		var elementRegex = WebAccounts.regex[elementType],
			elementValue = element.val() ? element.val().replace(/\s/g, '') : "",
			passwordMeterGroupCounter = 0,
			passwordMeter = "";

		if (elementRegex.small_letters.test(elementValue)) {
			passwordMeterGroupCounter++;
		}
		if (elementRegex.big_letters.test(elementValue)) {
			passwordMeterGroupCounter++;
		}
		if (elementRegex.digits.test(elementValue)) {
			passwordMeterGroupCounter++;
		}
		if (elementRegex.symbols.test(elementValue)) {
			passwordMeterGroupCounter++;
		}

		if (passwordMeterGroupCounter === 1 && !elementRegex.very_weak.test(elementValue)) {
			passwordMeterGroupCounter--;
		} else if (passwordMeterGroupCounter === 2 && !elementRegex.weak.test(elementValue)) {
			passwordMeterGroupCounter--;
		} else if (passwordMeterGroupCounter === 3 && !elementRegex.good.test(elementValue)) {
			passwordMeterGroupCounter--;
		} else if (passwordMeterGroupCounter === 4 && !elementRegex.great.test(elementValue)) {
			passwordMeterGroupCounter--;
		}

		switch(passwordMeterGroupCounter) {
			case 1:
				passwordMeter = "very-weak";
				break;
			case 2:
				passwordMeter = "weak";
				break;
			case 3:
				passwordMeter = "good";
				break;
			case 4:
				passwordMeter = "great";
				break;
			default:
				passwordMeter = "";
				break;
		}

		return passwordMeter;
	}

	/**
	 * Sets the validation text. The text should be within the
	 * validation-text html attribute of the specific element,
	 * the funciton will set it as the text for the label and 
	 * span element.
	 * 
	 * @param {Object} element The jQuery element which failed its validation
	 */
	WebAccounts.setElementValidationText = function(element) {
		var elementType = element.attr('name') ? element.attr('name').toLowerCase() : "",
		 	textElementLabel = $(element).parent().find('label.error-message[for=' + elementType + ']'),
		 	textElementSpan = $(element).parent().find('span[data-for="'+ elementType +'"]');

		textElementLabel.text('Please enter your ' + element.attr('validation-text'));
		textElementSpan.text('Please enter your ' + element.attr('validation-text'));
	}

	/**
	 * @param {Object} event The input event
	 */
    WebAccounts.onInput = function (event) {
		var element = $(this),
			elementType = element.attr('name') ? element.attr('name').toLowerCase() : "",
			currentForm = element.parents('form'),
			currentFormID = currentForm.attr('id');

		if (element.val() && element.val().trim().length > 0) {
			element.addClass('not-empty-field');
		} else {
			element.removeClass('not-empty-field');
			WebAccounts.setElementValidationText(element);
		}

		if (elementType.indexOf('postcode') !== -1 && element.val() && element.val().trim()) {
			element.val(element.val().toUpperCase());
		}

		//All elements who are not a name
		if (elementType.indexOf('name') === -1 && element.val().trim().length == 0) {
			WebAccounts.setElementValidationText(element);
		}

		//The elements with a name type
		if (elementType.indexOf('name') !== -1 && element.val().trim().length > 0) {
			var elementRegex = WebAccounts.regex['name'];
			var elementRegexAdditional = /^[a-zA-Z\s]{0,}$/;
			var textElementLabel = $(element).parent().find('label[for=' + elementType + ']');
			var textElementSpan = $(element).parent().find('span[data-for="'+ elementType + '"]');

			if(element.val().trim().match(elementRegexAdditional) === null){
				textElementLabel.text('Please include letters only for your ' + element.attr('validation-text'));
				textElementSpan.text('Please include letters only for your ' + element.attr('validation-text'));
			} else if (elementRegex.test(element.val().trim()) === false) {
				textElementLabel.text('Please enter a valid ' + element.attr('validation-text'));
				textElementSpan.text('Please enter a valid ' + element.attr('validation-text'));
			}
		}


		//Deprecated 
		if (currentForm.hasClass('has-password-meter-validation')) {
			if (element.hasClass('password-meter-validation') && elementType.indexOf('password') !== -1) {
				var otherPasswordField = currentForm.find('input[name*="password"]:not(.password-meter-validation)'),
					otherPasswordElementType = otherPasswordField.attr('name') ? otherPasswordField.attr('name').toLowerCase() : "",
					passwordElementLabel = otherPasswordField.parent().find('label[for=' + otherPasswordElementType + ']'),
					passwordElementSpan = otherPasswordField.parent().find('span[data-for="' + otherPasswordElementType + '"]');

				// WebAccounts.passwordMeterValidationAttributes(element, passwordMeterValidation(element, 'password_meter'));

				var result = WebAccounts.validatePassword(element, otherPasswordField);
				if(!result.valid) {
					passwordElementLabel.text(result.message);
					passwordElementSpan.text(result.message);
				}

				if (currentForm.hasClass('live-validation')) {
					if (otherPasswordField.length > 0 && WebAccounts.isFormElementValid(otherPasswordField)) {
						WebAccounts.triggerFormElementValidClass(currentForm, otherPasswordField, (WebAccounts.isFormElementValid(otherPasswordField) && otherPasswordField.val() === element.val()));
					}

					WebAccounts.triggerFormElementValidClass(currentForm, element, WebAccounts.isFormElementValid(element));
				}

			} else if (elementType.indexOf('password') !== -1) {
				var otherPasswordField = currentForm.find('input[name*="password"].password-meter-validation'),
					passwordElementLabel = element.parent().parent().find('label[for=' + elementType + ']'),
					passwordElementSpan = element.parent().find('span[data-for="' + elementType + '"]');

				var result = WebAccounts.validatePassword(element, otherPasswordField);
				if(!result.valid) {
					passwordElementLabel.text(result.message);
					passwordElementSpan.text(result.message);
				}

				if (currentForm.hasClass('live-validation') && currentForm.find('.password-meter-validation').length > 0 && WebAccounts.isFormElementValid(currentForm.find('.password-meter-validation'))) {
					WebAccounts.triggerFormElementValidClass(currentForm, element, (WebAccounts.isFormElementValid(element) && currentForm.find('.password-meter-validation').val() === element.val()));
				}
			}
		} else if (currentForm.hasClass('live-validation')) {
			WebAccounts.triggerFormElementValidClass(currentForm, element, WebAccounts.isFormElementValid(element));
		}

		if (currentForm.hasClass('live-validation') && elementType.indexOf('password') === -1) {
			WebAccounts.triggerFormElementValidClass(currentForm, element, WebAccounts.isFormElementValid(element));
		}
	};
	
	allFormElements.on('input', WebAccounts.onInput );

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
import { parseQueryString, getElement } from "./common/helpers";

export class OBFManager {
    constructor() {
        this.parsedUrl = parseQueryString();
        this.triggerBtn = getElement("[data-book-btn=true]");
        this.triggerList = [
            "new_booking",
            "restored_booking"
        ]
    }

    open() {
        if (this.parsedUrl.hasOwnProperty('trigger')
            && this.triggerList.includes(this.parsedUrl.trigger)) {
            if (typeof obfOptions === 'object') {
                obfOptions.action = this.parsedUrl.trigger;
                obfOptions.payload = this.parsedUrl['payload']||{};
            }
            this.triggerBtn.click();
        }
    }
}
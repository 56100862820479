import { getCookie, setCookie } from "./helpers";
import { WAReminderPopups } from "./reminder-popups";

export class MembershipReminders {
    constructor(membershipStatuses, region = 'UK') {
        this.region = region;
        this.shouldRender = false;
        this.membershipStatuses = membershipStatuses;
    }

    render() {
        // the user is not a member
        if (this.membershipStatuses == null) {
            return null;
        }

        let modal = new WAReminderPopups(this.region);

        if (getCookie("shown-expired-membership") == null
            && this.membershipStatuses.expired) {
            setCookie("shown-expired-membership", "shown", 365);
            modal.renderExpiredMembership();
        }

        if (getCookie("shown-renew-membership") == null
            && this.membershipStatuses.renewReminder
            && !this.membershipStatuses.expired) {
            setCookie("shown-renew-membership", "shown", 1);
            modal.renderRenewMembership();
        }
    }
}
(function(WebAccounts, $, undefined) {
    if(!WebAccounts.forms.length) {
        return;
    }

    WebAccounts.fileElements = WebAccounts.forms.find('input[type=file]');

    if(!WebAccounts.fileElements.length) {
        return;
    }
    
    /**
     * Used in the form settings to change the profile image.
     * Files that would be accepted would be jpeg/png/jpg and must be 
     * under 2MB
     * 
     * @param {Object} event The input change event, not used
     */
    WebAccounts.fileElementsChange = function(event) {
        var element = $(this);

        if (!element.length) {
            return;
        }

        //Goes through the file elements
        for (var i = 0; i < element.length; i += 1) {
            var currentFileElement = $(element[i]),
                currentFileElementParent = currentFileElement.parents('.image-upload-holder'),
                currentFileElementErrorMessage = currentFileElement.parents('form').find('label[for=image_avatar]:not(.image-upload)'),
                currentFileElementSize = 0,
                isCurrentFileExtensionValid = false,
                fileReader = new FileReader(),
                currentFile = "";

            if (currentFileElement[0] && //Element exists 
                currentFileElement[0].files && //Has files
                currentFileElement[0].files[0]) { //These files are set

                currentFileElementSize = currentFileElement[0].files[0].size;
                isCurrentFileExtensionValid = (currentFileElement[0].files[0].type === 'image/jpeg' ||
                    currentFileElement[0].files[0].type === 'image/png' ||
                    currentFileElement[0].files[0].type === 'image/jpg');

                if (currentFileElementSize < 2 * 1024 * 1024 && isCurrentFileExtensionValid) {

                    if (!currentFileElementErrorMessage.is(':animated') && currentFileElementErrorMessage.hasClass('visible')) {
                        WebAccounts.fadeInElement(currentFileElementErrorMessage);
                    }

                    //On file read 
                    fileReader.onload = function (e) {
                        currentFile = e.target.result;

                        if (!currentFileElementParent.length) {
                            return;
                        }

                        currentFileElementParent.css({
                            'background': 'url(' + currentFile + ')',
                            'background-repeat': 'no-repeat',
                            'background-size': 'cover',
                            'background-position': 'center center'
                        });
                        currentFileElementParent.addClass('image-visible');
                    };

                    fileReader.readAsDataURL(currentFileElement[0].files[0]);
                } else {

                    if (currentFileElementSize > 2 * 1024 * 1024) {
                        currentFileElementErrorMessage.text('Please upload image under 2MB size');
                    } else if (!isCurrentFileExtensionValid) {
                        currentFileElementErrorMessage.text('Please upload valid image file (.jpg, .png, .jpeg)');
                    }

                    if (!currentFileElementErrorMessage.is(':animated') && !currentFileElementErrorMessage.hasClass('visible')) {
                        currentFileElementErrorMessage.slideDown(200, function(){
                            $(this).addClass('visible');
                        });
                    }
                }
            }
        }
    };

    WebAccounts.fileElements.on('change', WebAccounts.fileElementsChange);

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
(function(WebAccounts, $, undefined) {
    WebAccounts.actionButtons = $('[data-action]:not(.inactive)');
    WebAccounts.inactiveActionButtons = $('[data-action].inactive');

    //If there are no action buttons continue:
    if(WebAccounts.actionButtons.length < 1) {
        return;
    }

    /**
     * When clicking an element with a data-action attribute, we find the
     * targeted element with a data-action-item between its siblings and children.
     * Fades out the different elements on the screen and fades the data-action target
     * element... Currently works on a dropdown menu only.
     */
    WebAccounts.actionButtonClick = function(event) {
        event.preventDefault();

        var element = $(this),
            visibleActionElement = $('[data-action-item].visible'),
            actionElementSelector = element.attr('data-action'),
            actionElement = element.parent().find('[data-action-item="' + actionElementSelector + '"]'),
            isOverlayAnimated = WebAccounts.overlayElements.is(':animated'),
            isOverlayVisible = WebAccounts.overlayElements.hasClass('visible'),
            isActionElementDropdownMenu = actionElementSelector == 'dropdown-menu';

        WebAccounts.fadeOutElement(visibleActionElement);
        visibleActionElement.parent().find('[data-action]').removeClass('menu-visible');


        if (!isOverlayAnimated && isOverlayVisible && isActionElementDropdownMenu) {
            if (!WebAccounts.bodyElement.hasClass('sidebar-opened')) {
                // WebAccounts.fadeOutElement(WebAccounts.overlayElements);
            } else {
                // WebAccounts.overlayElements.removeClass('visible');
            }
        }

        if (actionElement.is(':animated')) {
            return;    
        }

        if (!actionElement.hasClass('visible')) {
            element.addClass('menu-visible');

            // if (!isOverlayAnimated && !isOverlayVisible && isActionElementDropdownMenu) {
            //     WebAccounts.fadeInElement(WebAccounts.overlayElements);
            // }

            // if ($('.service-card-item.clone').length) {
            //     $('.service-card-item.clone .service-card-close-btn').trigger('click');
            //     WebAccounts.overlayElements.stop().fadeIn(200);
            // }

            actionElement.fadeIn(200, function () {
                actionElement.addClass('visible');
            });
            return
        }

        element.removeClass('menu-visible');

        // if (!isOverlayAnimated && isOverlayVisible && isActionElementDropdownMenu) {
        //     WebAccounts.fadeOutElement(WebAccounts.overlayElements);
        // }

        WebAccounts.fadeOutElement(actionElement);
    };

    WebAccounts.actionButtons.on('click', WebAccounts.actionButtonClick);
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
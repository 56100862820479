import { getElement } from "./helpers";

export class WALoader{
    constructor(){
        this.element = getElement('#loader-wrapper');
    }

    show(){
        this.element.classList.add('visible')
    }

    hide(){
        this.element.classList.remove('visible')
    }
}
import { getElement, parseBoolean, trans } from "../common/helpers";

(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('EditBookingTask')) {
        WebAccounts.EditBookingTask = {
            elements: $('[data-trigger="edit-service"]'),
            postApiEndpoint: "/api/booking/temp/edit_requests",
            isV2: false,  // Compatibility issues
            requestPayload: {
                bookingId: undefined,
                reasonId: undefined,
                comment: undefined
            },
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    if (!WebAccounts.EditBookingTask.hasOwnProperty('exist') ||
        !WebAccounts.EditBookingTask.exist()) {
        return;
    }

    // /** Constructor */
    WebAccounts.EditBookingTask.construct = function () {
        const xrmEditBypass = WebAccounts.EditBookingTask.elements.data("shouldBypass");
        if (parseBoolean(xrmEditBypass)) {
            WebAccounts.EditBookingTask.elements.on('click', WebAccounts.EditBookingTask.editBooking);
        } else {
            WebAccounts.EditBookingTask.elements.on('click', WebAccounts.EditBookingTask.openReasonsModal);
        }
    }
    /** Ativate edit booking */
    WebAccounts.EditBookingTask.editBooking = function (e) {
        WebAccounts.EditBookingTask.buildEditObject().then((result) => {
            if (result) {
                $(e.currentTarget).parents(".service-meta-information").attr('data-book-btn', true).click();
                $(e.currentTarget).parents(".service-meta-information").removeAttr('data-book-btn');
            }
        })
    }

    /** build edit object */
    WebAccounts.EditBookingTask.buildEditObject = function () {
        WebAccounts.EditBookingTask.buildPayload()
        let addressId = undefined;

        return new Promise((resolve, reject) => {
            if (typeof obfOptions === 'object') {
                obfOptions.bookingId = WebAccounts.EditBookingTask.requestPayload.bookingId;
                obfOptions.addressId = addressId;
                obfOptions.bookingEditType = "serviceChoices";
            }

            resolve(true);
        });
    }
    /**
     * @param {Event} e
     */
    WebAccounts.EditBookingTask.openReasonsModal = function (e) {
        e.preventDefault();

        /** Re-initialize request payload */
        WebAccounts.EditBookingTask.requestPayload = {
            bookingId: undefined,
            reasonId: null,
            comment: undefined
        };

        WebAccounts.EditBookingTask.buildPayload();

        WebAccounts.Utilities.ShowModal({
            customClass: 'wa-pop-up wa-edit-booking-modal',
            title: `<p class="push-up">${trans('editBookingTask.title')}</p>`,
            html: `<div class="client-reason-text-area">
                        <textarea name="client-edit-reason" placeholder="${trans('editBookingTask.placeholder')}"></textarea>
                   </div>`,
            preConfirm: () => {
                let clientEditReason = $('.client-reason-text-area textarea[name="client-edit-reason"]');

                if (clientEditReason.length) {
                    clientEditReason = clientEditReason.val() || "";

                    WebAccounts.EditBookingTask.requestPayload.comment = clientEditReason.trim();
                }

                return WebAccounts.EditBookingTask.validate();
            },
            confirmButtonText: trans('editBookingTask.confirmBtn'),
        }).then((result) => {
            if (result) {
                WebAccounts.EditBookingTask.send();
            }
        });
    }


    /**
     * Make request to the Backend with the client reason
     * 
     * @param {integer} id 
     */
    WebAccounts.EditBookingTask.send = function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': csrfToken
            }
        });
        dataLayer.push({
            'event': 'successfulSubmit',
            'additionalNotes': WebAccounts.EditBookingTask.requestPayload.comment
        });


        $.ajax({
            url: WebAccounts.EditBookingTask.postApiEndpoint,
            type: 'POST',
            data: WebAccounts.EditBookingTask.requestPayload,
            success: (response) => {

                if (!response.hasOwnProperty('errors')) {
                    let modalOptions = {
                        title: `<span class="">${trans('editBookingTask.success.title')}</span>`,
                        text: trans('editBookingTask.success.text'),
                        confirmButtonText: trans('editBookingTask.success.confirmBtn'),
                    }
                    if (WebAccounts.EditBookingTask.isV2) {
                        modalOptions = {
                            html: response['successes']['edit_requests']['success']['message'],
                            confirmButtonText: trans('editBookingTask.success.confirmBtn'),
                        }
                    }
                    return WebAccounts.Utilities.ShowModal(modalOptions);
                }

                return WebAccounts.Utilities.ShowModal({
                    title: `<span class="">${trans('general.errors.generic')}</span>`,
                    confirmButtonText: trans('general.errors.genericConfirmBtn'),
                });
            }
        });
    }

    /**
     * @return {Promise}
     */
    WebAccounts.EditBookingTask.validate = function () {
        return new Promise((resolve, reject) => {
            let valid = false;
            if (WebAccounts.EditBookingTask.requestPayload.comment !== undefined
                && WebAccounts.EditBookingTask.requestPayload.comment.length > 3) {
                resolve();
            }

            dataLayer.push({
                'event': "failedSubmit"
            });

            reject(trans('editBookingTask.errors.addComment'));
        });
    }

    /**
     * Add to the payload all needed information from the service meta information container
     */
    WebAccounts.EditBookingTask.buildPayload = function () {
        let bookingMetaInformation = WebAccounts.EditBookingTask.elements.parents('.service-meta-information');
        WebAccounts.EditBookingTask.requestPayload.bookingId = bookingMetaInformation.data('bookingId') || undefined;
    }

    WebAccounts.EditBookingTask.construct();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

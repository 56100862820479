import { TipTask } from '../booking-tasks/tip-task'
export class WAHandlers {
    tipTask(event) {
        event.preventDefault()
        event.stopPropagation()
        const bookingId = event.currentTarget.dataset.bookingId
        const task = new TipTask()
        task.render(bookingId);
    }

    loaderTimeout() {
        setTimeout(WebAccounts.removeLoader, 3000);
    }
}
(function(WebAccounts, $, undefined) {    
    if(!WebAccounts.forms.length) {
        return;
    }

    WebAccounts.allCheckboxElements = WebAccounts.forms.find('input[type=checkbox]');

    if(!WebAccounts.allCheckboxElements.length) {
        return;
    }

    WebAccounts.checkboxChange = function() {
        var element = $(this),
            elementType = element.attr('name') ? element.attr('name').toLowerCase() : "",
            currentForm = element.parents('form'),
            currentFormID = currentForm.attr('id');

        //Nothing goes pass here
        if (!currentForm.hasClass('live-validation')) {
            return;
        }

        //DEPRECATED
        if (WebAccounts.isFormElementValid(element)) {
            for (var i = 0; i < WebAccounts.allCheckboxElement.length; i += 1) {
                WebAccounts.triggerFormElementValidationClass(currentForm, $(WebAccounts.allCheckboxElement[i]), true);
            }
        } else {
            WebAccounts.triggerFormElementValidationClass(currentForm, element, isFormElementValid(element));
        }
    };

    WebAccounts.allCheckboxElements.on('change', WebAccounts.checkboxChange);

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
import { WAModal } from "./wa-modal";
import { trans } from "./helpers";

export class WAReminderPopups {
    constructor(region = "UK") {
        this.modal = new WAModal();
        this.region = region;
    }


    /**
     * @returns self
     */
    renderRenewMembership() {
        this.modal.setOptions({
            title: trans('membership.reminders.renew.title'),
            html: this.__buildBenefitsSection('renew'),
            confirmButtonText: trans('membership.reminders.confirmBtn'),
            cancelButtonText: trans('membership.reminders.cancelBtn'),
            showCloseButton: true,
            showCancelButton: true
        }).appendClasses(['wa-membership-expire-soon']);

        this.modal.render().then(
            confirm => { window.location = window.location.origin + "/fantastic-membership-club?section=renew"; },
            cancel => { }
        );

        return this;
    }

    /**
     * @returns self
     */
    renderExpiredMembership() {

        this.modal.setOptions({
            title: trans('membership.reminders.expire.title'),
            html: this.__buildBenefitsSection('expired'),
            confirmButtonText: trans('membership.reminders.confirmBtn'),
            cancelButtonText: trans('membership.reminders.cancelBtn'),
            showCloseButton: true,
            showCancelButton: true
        }).appendClasses(['wa-membership-expired']);

        this.modal.render().then(
            confirm => { window.location = window.location.origin + "/fantastic-membership-club?section=renew"; },
            cancel => { }
        );

        return this;
    }

    __buildBenefitsSection(type) {
        let container = `<div class="row">`;
        container += this.__getTitle(type);
        container += this.__getBenefits(type);
        container += `</div>`;

        return container;
    }

    __getTitle(type) {
        switch (type) {
            case 'expired':
                return `<p>${trans('membership.reminders.expire.benefitsTitle')}:</p>`
            case 'renew':
                return `<p>${trans('membership.reminders.renew.benefitsTitle')}:</p>`
            default:
                return ""
        }
    }

    __getBenefits(type) {
        let benefitsHTML = "";
        const benefits = trans('membership.reminders.benefits');
        try { benefits.forEach(benefit => benefitsHTML += `<li>${benefit}</li>`); } catch (e) { }
        
        return `<ul class="col-xs-9 col-xs-offset-2 text-align-left push-up wa-list wa-bullets">
                    ${benefitsHTML}
                </ul>`
    }
}
const { trans } = require("../common/helpers");

(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('SessionsTask')) {
        WebAccounts.SessionsTask = {
            elements: $('[data-component="session-list"]'),
            loadMoreBtn: $('[data-btn-action="load-more-sessions"]'),
            sessionsContainer: $('[data-component="sessions-container"]'),
            sessionsListing: $('[data-listing="sessions"]'),
            regularId: undefined,
            sessions: {},
            monthOffset: 0,
            apiEnpoinTemplate: "/api/booking/{regularId}/regular-sessions/{monthsOffset}",
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }


    if (!WebAccounts.SessionsTask.hasOwnProperty('exist') ||
        !WebAccounts.SessionsTask.exist()) {
        return;
    }

    /**
     * Retrieves regular id from the component meta data
     */
    WebAccounts.SessionsTask.setRegularId = () => {
        WebAccounts.SessionsTask.regularId = WebAccounts.SessionsTask.elements.data('regularId');
    }

    /** Constructor */
    WebAccounts.SessionsTask.construct = function () {
        WebAccounts.SessionsTask.loaderElement = WebAccounts.SessionsTask.elements.find('.miniloader-holder');

        WebAccounts.SessionsTask.setRegularId();

        WebAccounts.SessionsTask.buildRequestPath();

        WebAccounts.SessionsTask.get();
        if (window.location.hash) {
            WebAccounts.Utilities.scrollTo(window.location.hash);
        }

        WebAccounts.SessionsTask.loadMoreBtn.on('click', WebAccounts.SessionsTask.loadMore);
    }

    WebAccounts.SessionsTask.buildRequestPath = () => {
        WebAccounts.SessionsTask.apiEnpoint = WebAccounts.SessionsTask.apiEnpoinTemplate.replace('{regularId}', WebAccounts.SessionsTask.regularId);
        WebAccounts.SessionsTask.apiEnpoint = WebAccounts.SessionsTask.apiEnpoint.replace('{monthsOffset}', WebAccounts.SessionsTask.monthOffset);
    };

    /**
     * Renders all available sessions
     * 
     * @param {HttpResponse} response 
     */
    WebAccounts.SessionsTask.render = response => {
        if (!!!Object.keys(response.data).length) {
            return WebAccounts.SessionsTask.getNextMonthSessions();
        }

        for (let month in response.data) {
            if (WebAccounts.SessionsTask.sessions.hasOwnProperty(month)) {
                let monthDataAttr = month.split(' ').join('-');

                let monthContainer = $(`[data-month="${monthDataAttr}"]`);

                monthContainer.append(WebAccounts.SessionsTask.generateListEntries(response.data[month]));

            } else {
                let monthDataAttr = month.split(' ').join('-');
                let readyList = '';
                let startRow = `
                        <div class="col-xs-12 push-up" data-month="${monthDataAttr}">
                            <span class="service-session-timestamp push-down" data-animate="fadeIn">${month}</span>`,
                    endRow = `</div>`,
                    sessionRows = WebAccounts.SessionsTask.generateListEntries(response.data[month]);

                readyList = `${startRow}${sessionRows}${endRow}`;

                WebAccounts.SessionsTask.sessionsListing.append(readyList);
            }
        }
        WebAccounts.Utilities.triggerAnimations();
        WebAccounts.SessionsTask.loaderElement.fadeOut();

        WebAccounts.SessionsTask.mergeSessions(response.data);
    }
    /**
     * Retrieves all available sessions from WA
     */
    WebAccounts.SessionsTask.get = () => {
        return $.ajax({
            url: WebAccounts.SessionsTask.apiEnpoint,
            method: "GET",
            success: WebAccounts.SessionsTask.render
        })
    }

    /**
     * Retrieves all available sessions for 1 month offset
     * 
     * @param {Event} event 
     */
    WebAccounts.SessionsTask.loadMore = (event) => {
        event.preventDefault();
        // $(event.currentTarget).remove();
        WebAccounts.SessionsTask.getNextMonthSessions();
    }

    /**
     * Merges all available sessions by month
     * 
     * @param {JSON} sessions 
     */
    WebAccounts.SessionsTask.mergeSessions = (sessions) => {
        for (let month in sessions) {
            try {
                WebAccounts.SessionsTask.sessions[month] = WebAccounts.SessionsTask.sessions[month].concat(sessions[month]);
            } catch (error) {
                WebAccounts.SessionsTask.sessions[month] = sessions[month];
            }
        }
    }

    /**
     * Retrive sessions for the next month from the last month offset
     */
    WebAccounts.SessionsTask.getNextMonthSessions = () => {
        WebAccounts.SessionsTask.loaderElement.fadeIn();

        WebAccounts.SessionsTask.monthOffset++;
        WebAccounts.SessionsTask.buildRequestPath();
        WebAccounts.SessionsTask.get();
    };

    WebAccounts.SessionsTask.generateListEntries = (entries) => {
        let listEntries = '';
        entries.forEach(row => {
            listEntries += `<div class='service-session-row' data-animate="fadeIn" style="display:none;">
                                <div>
                                    <span class="service-session-title"> <a class="bold" href="/bookings/${WebAccounts.SessionsTask.regularId}/get-session/${row.objectId}">${row.formattedTimeSlot.day}</a></span>
                                    <span class="service-session-timestamp  wa-font"><span class="wa-icon-clock">&nbsp;</span> ${row.formattedTimeSlot.hour}, ${row.formattedEstimatedTime}</span>
                                </div>
                                <div class=" text-align-right">
                                    <a href="/bookings/${WebAccounts.SessionsTask.regularId}/get-session/${row.objectId}">${trans('sessionsTask.manageCTA')}</a>
                                </div>
                            </div>`
        });

        return listEntries;
    }

    document.addEventListener('waConfigurationsLoaded', e=>{
        WebAccounts.SessionsTask.construct();
    })
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

(function (WebAccounts, $, undefined) {
    //Global Variables
    WebAccounts.currentYear = new Date().getFullYear();
    WebAccounts.allMonths = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    WebAccounts.allYears = [(WebAccounts.currentYear - 1).toString(), WebAccounts.currentYear.toString()];

    //GLOBAL REGEX
    WebAccounts.regex = {
        name: /^([^!{}()_,=@#$%^&*]){1,40}$/,
        expiryDate: /^[0-9]{2}\/[0-9]{2,4}$/,
        address: [
            {
                type: 'default',
                pattern: /\w{4,}/
            },
            {
                type: 'bg',
                pattern: /[а-яА-Я]{4,}/
            },
            {
                type: 'hu',
                pattern: /[A-Za-záéúőóüöÁÉÚŐÓÜÖ]{4,}/
            }
        ],
        city: [
            {
                type: 'default',
                pattern: /\w{4,}/
            },
            {
                type: 'bg',
                pattern: /[а-яА-Я]{4,}/
            },
            {
                type: 'hu',
                pattern: /[A-Za-záéúőóüöÁÉÚŐÓÜÖ]{4,}/
            }
        ],
        email: /^[a-zA-Z0-9!\“\”#$%\"\\&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!\“\”#$%\ \"\\&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
        phone: /\W([0-9+\s]{9,18})\W|^([0-9+\s]{9,18})\W|\W([0-9+\s]{9,18})$|^([0-9+\s]{9,18})$/, //old
        password: /^.{1,}$/,
        postcode: [
            {
                type: 'uk',
                pattern: /^([A-Z]{1,2})([0-9][0-9A-Z]?)\s*([0-9])([A-Z]{2})$/
            },
            {
                type: 'aus',
                pattern: /^([0-9]{4})$/
            },
            {
                type: 'usa',
                pattern: /^([0-9]{5})$/
            },
            {
                type: 'bg',
                pattern: /^([0-9]{4})$/
            },
            {
                type: 'hu',
                pattern: /^([0-9]{4})$/
            },
            {
                type: 'ie',
                pattern: /^([a-zA-Z]{1}[0-9]{2}|D6W)[ ]?[0-9a-zA-Z]{4}$/
            }
        ],
        password_meter: {
            very_weak: /.{2,}/,
            weak: /.{4,}/,
            good: /.{6,}/,
            great: /.{8,}/,
            small_letters: /[a-z]+/,
            big_letters: /[A-Z]+/,
            digits: /\d+/,
            symbols: /[$@$!%*?&]+/
        },
        credit_cards: [
            {
                type: 'amex',
                pattern: /^3[47]/,
                valid_length: [15]
            },
            {
                type: 'visa_electron',
                pattern: /^(4026|417500|4508|4844|491(3|7))/,
                valid_length: [16]
            },
            {
                type: 'visa',
                pattern: /^4/,
                valid_length: [16, 19]
            },
            {
                type: 'mastercard',
                pattern: /^5[1-5]/,
                valid_length: [16]
            },
            {
                type: 'maestro',
                pattern: /^(5018|5020|5038|6304|6759|676[1-3])/,
                valid_length: [12, 13, 14, 15, 16, 17, 18, 19]
            }
        ]
    };


})(window.WebAccounts = window.WebAccounts || {}, jQuery);

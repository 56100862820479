import { trans } from './common/helpers';

(function (WebAccounts, $, undefined) {
    WebAccounts.DeactivateAccount = {
        el: $("#deactivateAccountForm"),
        submitBtn: $("#requestDelete"),
        reasonsSelect: $('select[name="comment"]'),
        animationStep: 300,
        exists: function () {
            return this.el.length;
        }
    }

    if (!WebAccounts.DeactivateAccount.exists()) {
        return;
    }

    WebAccounts.DeactivateAccount.ini = function () {
        this.reasonsSelect.on('change', () => {
            let newlySelectedValue = this.reasonsSelect.val(),
                selector = '';


            switch (newlySelectedValue) {
                case 'duplicate_account':
                    selector = null;
                    break;
                case 'spam_mail':
                    selector = '#delete-account-section .too-many-emails';
                    break;
                default:
                    selector = '#delete-account-section .comment-field';
                    break;
            }

            var openedElements = this.el.find('.opened'),
                selectedElement = $(selector);

            if (selectedElement.hasClass('opened')) {
                return;
            }

            if (openedElements.length > 0) {
                openedElements.slideUp(this.animationStep);
                openedElements.removeClass('opened');
            }

            selectedElement.slideDown(this.animationStep);
            selectedElement.addClass('opened');
        });

        this.submitBtn.on('click', () => {
            let passwordEl = this.el.find('[name="password"]'),
                passwordFeedBack = $('.error-message#requiredPassword');
            passwordFeedBack.hide();
            if (passwordEl.val() === '') {
                passwordFeedBack.show();
                return;
            }
            WebAccounts.Utilities.ShowModal({
                title: trans('deactivateAccount.confirmation.title'),
                text: trans('deactivateAccount.confirmation.text'),
                confirmButtonText: trans('deactivateAccount.confirmation.confirmBtn'),
                showCancelButton: true,
            }).then((result) => {
                if (result) {
                    WebAccounts.DeactivateAccount.makeCall();
                }
            });
        });
    };

    WebAccounts.DeactivateAccount.changeReason = function () {

    };

    WebAccounts.DeactivateAccount.makeCall = function () {

        $.ajax({
            url: WebAccounts.DeactivateAccount.el.attr('action'),
            type: WebAccounts.DeactivateAccount.el.attr('method'),
            data: WebAccounts.DeactivateAccount.el.serialize(),
            success: function (result) {
                if (result.errors) {
                    WebAccounts.Utilities.ShowModal({
                        title: trans('deactivateAccount.error.title'),
                        text: trans('deactivateAccount.error.text'),
                    });
                } else {
                    WebAccounts.Utilities.ShowModal({
                        html: result['successes']['request_delete_account']['success']['message'],
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        location.reload();
                    });
                };
            }
        });
    };


    WebAccounts.DeactivateAccount.ini();

})(window.WebAccounts = window.WebAccounts || {}, jQuery);

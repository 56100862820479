const { trans } = require("../common/helpers");

(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('EditPlanTask')) {
        WebAccounts.EditPlanTask = {
            elements: $('[data-trigger="edit-plan"]'),
            reasonsNodes: undefined,
            fetchApiEndpoint: '/api/booking/edit_regular_plan_reasons',
            postApiEndpoint: '/api/booking/temp/edit_requests',
            radioBtnText: undefined,
            isV2: false,
            requestPayload: {
                bookingId: undefined,
                reasonId: undefined,
                comment: undefined
            },
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    WebAccounts.EditPlanTask.construct = function () {
        WebAccounts.EditPlanTask.elements.on('click', WebAccounts.EditPlanTask.openModal);
    };
    /**
     * After reasons are loaded creates html nodes with reasons information
     * 
     * @param {JSON} response 
     */
    WebAccounts.EditPlanTask.buildReasonsNodes = function (reasons) {
        let startList = `<div class="modal-container">
            <ul class="unstyled-list text-align-left modal-reasons-list">`,
            endList = `</ul></div>`;

        reasons.forEach((item, key) => {
            startList += `
                 <li>
            <label class="cancelReasonItem custom-radio wa-flex wa-items-centered" for="reason-${key}">
            <input class="cancel-reason" id="reason-${key}" type="radio" name="edit_plan_reason" value="${item.name}" data-id="${item.id}"">
            <span class="wa-radio"></span>
                    <span class="cancelReasonName">${item.name}</span>
            </label>
        </li>`;
        });

        WebAccounts.EditPlanTask.reasonsNodes = startList + endList;
    }

    /**
     * 
     * @param {Event} event 
     */
    WebAccounts.EditPlanTask.openModal = function (event) {
        event.preventDefault();
        event.stopPropagation();

        const bookingInformationElement = $(event.currentTarget).parents('.service-meta-information');

        WebAccounts.EditPlanTask.requestPayload = {
            bookingId: bookingInformationElement.data('bookingId'),
            reasonId: undefined,
            comment: undefined
        };

        WebAccounts.showLoader();
        /**
         * After all reasons are loaded, renders modal
         */
        WebAccounts.EditPlanTask.getCustomReasons().then(() => {
            WebAccounts.hideLoader();

            WebAccounts.Utilities.ShowModal({
                customClass: 'wa-pop-up wa-edit-plan-modal',
                title: `<p class="push-xs-up">${trans('editPlanTask.title')}</p>`,
                html: WebAccounts.EditPlanTask.reasonsNodes,
                preConfirm: () => {
                    return new Promise((resolve, reject) => {
                        const textArea = $('.client-reason-text-area textarea');
                        if (textArea.length && textArea.val().length > 3) {
                            WebAccounts.EditPlanTask.requestPayload.comment = textArea.val();
                            resolve(true)
                        } else {
                            dataLayer.push({
                                'event': "failedSubmit"
                            });
                        }

                        reject(trans('editPlanTask.errors.addComment'));
                    });
                },
                confirmButtonText: trans('editPlanTask.confirmBtn')
            }).then((result) => {
                if (result) {
                    WebAccounts.EditPlanTask.send();

                }
            });

            swal.disableConfirmButton();

            const listItems = $(".modal-container ul li");

            listItems.on('click', 'label>input', WebAccounts.EditPlanTask.modifyCheckBoxes)
        })
    }
    /**
     * Modify the default behavior of input[type=checkbox]
     */
    WebAccounts.EditPlanTask.modifyCheckBoxes = function (e) {
        swal.enableConfirmButton();

        const modalContainer = $('.modal-container'),
            currentCheckbox = $(e.currentTarget),
            errorText = $(".swal2-validationerror"),
            oldTextArea = modalContainer.find('.client-reason-text-area');

        WebAccounts.EditPlanTask.requestPayload.reasonId = currentCheckbox.data('id');
        WebAccounts.EditPlanTask.radioBtnText = currentCheckbox.val();

        (errorText.length) ? errorText.slideUp() : null;

        if (oldTextArea.length) {
            oldTextArea.slideUp(() => {
                modalContainer.find('.client-reason-text-area').remove();
                WebAccounts.EditPlanTask.addTextArea(modalContainer, currentCheckbox);
            });
        } else {
            WebAccounts.EditPlanTask.addTextArea(modalContainer, currentCheckbox);
        }
    };

    /**
     * @param {HTMLElement} container 
     * @param {HTMLElement} element
     */
    WebAccounts.EditPlanTask.addTextArea = function (container, element) {
        element.parents('li').append(`
        <div class="client-reason-text-area" style='display:none;'>
            <textarea name="comment" placeholder="${trans('editPlanTask.placeholder')}"></textarea>
        </div>`);

        container.find('.client-reason-text-area').slideDown();
    }


    /**
     * Makes request to the WA for all custom reasons
     * 
     * @return {Promise}
     */
    WebAccounts.EditPlanTask.getCustomReasons = function () {

        return new Promise((resolve, reject) => {
            // if there are already loaded stop execution
            if (WebAccounts.EditPlanTask.areCachedReasons()) {
                return resolve();
            }

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': csrfToken
                }
            });

            // make request to WebAccounts API;
            $.ajax({
                type: "POST",
                url: WebAccounts.EditPlanTask.fetchApiEndpoint,
                success: response => {
                    WebAccounts.EditPlanTask.handleResponse(response);
                    resolve();
                }
            });
        });
    }

    /**
     * Handles errors if there are any
     * triggers buildReasons with the data from the response
     * 
     * @param {JSON} response 
     */
    WebAccounts.EditPlanTask.handleResponse = function (response) {
        if (response.hasOwnProperty('errors')) {
            response.errors.forEach(element => {
                console.log(new Error(element.message));
            });
            return;
        }

        WebAccounts.EditPlanTask.buildReasonsNodes(response.edit_regular_plan_reasons);
    }

    /**
     * Sends the change for the plan to WA and renders success Modal
     */
    WebAccounts.EditPlanTask.send = function () {

        dataLayer.push({
            'event': 'successfulSubmit',
            'radioChoice': WebAccounts.EditPlanTask.radioBtnText,
            'additionalNotes': WebAccounts.EditPlanTask.requestPayload.comment
        });

        $.ajax({
            url: WebAccounts.EditPlanTask.postApiEndpoint,
            type: 'POST',
            data: WebAccounts.EditPlanTask.requestPayload,
            success: (response) => {

                if (!response.hasOwnProperty('errors')) {
                    let modalContainer = {
                        title: `<span class="">${trans('editPlanTask.success.title')}</span>`,
                        text: trans('editPlanTask.success.text'),
                        confirmButtonText: trans('editPlanTask.success.confirmBtn'),
                    }

                    if (WebAccounts.EditPlanTask.isV2) {
                        modalContainer = {
                            html: response['successes']['edit_requests']['success']['message'],
                            confirmButtonText: "Ok",
                        }
                    }

                    return WebAccounts.Utilities.ShowModal(modalContainer);
                }

                return WebAccounts.Utilities.ShowModal({
                    title: `<span class="">${trans('general.errors.generic')}</span>`,
                    confirmButtonText: trans('general.errors.genericConfirmBtn'),
                });
            }
        });
    }

    WebAccounts.EditPlanTask.areCachedReasons = function () {
        return WebAccounts.EditPlanTask.reasonsNodes !== undefined;
    }


    WebAccounts.EditPlanTask.construct();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

import { getQueryStringValue, findFirst, scrollTo } from "./helpers";

export class PageInteractor {

    constructor() {
        this.sectionName = null;
        this.sectionElement = null;
        this.bootstrap();
    }

    /**
     * Simulate user action over an element
     * 
     * @param {String} action 
     * @param {Callable} callback 
     */
    simulate(action, callback = null) {
        if (this.sectionElement) {
            this[action]().then((status) => {
                if (callback) {
                    callback(this);
                }
            });
        }

        return this;
    }

    /**
     * @returns void
     */
    bootstrap() {
        this.sectionName = getQueryStringValue('section') || getQueryStringValue('action') || null;
        this.sectionElement = this.sectionName ? findFirst(this.possibleSelectors()) : null;
    }

    /**
     * Manually set section
     * @param {String} name 
     */
    forSection(name){
        this.sectionName = name
        this.sectionElement = this.sectionName ? findFirst(this.possibleSelectors()) : null;
    }

    /**
     * @returns {Promise}
     */
    click() {
        return new Promise((resolve, reject) => {
            this.sectionElement.click();
            resolve(true);
        })
    }

    /**
     * @returns {Promise}
     */
    scrollTo() {
        return scrollTo(this.sectionElement);
    }

    /**
     * @returns {String}
     */
    getSectionName() {
        return this.sectionName;
    }

    /** 
     * @return array
     */
    possibleSelectors() {
        return [
            `[data-simulation="${this.sectionName}"]`,
            `#${this.sectionName}`,
            `.${this.sectionName}`,
            this.sectionName
        ];
    }
}
(function(WebAccounts, $, undefined) {
    WebAccounts.viewPasswordButton = WebAccounts.forms.find('.view-password-btn');
    if(WebAccounts.viewPasswordButton.length) {
        /**
         * On a click of a .view-password-button element will find the closest
         * input with an "*assword" name, and will change its type to text.
         */
        WebAccounts.viewPasswordButton.on('click', function(event) {
            var element = $(this),
                inputElement = element.parent().find('input[name*="assword"]');

            if (!element.is(':animated')) {
                if (!element.hasClass('password-visible')) {
                    element.addClass('password-visible');
                    inputElement.attr('type', 'text');
                } else {
                    element.removeClass('password-visible');
                    inputElement.attr('type', 'password');
                }
            }
        });
    }
    
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
import {trans} from '../common/helpers';

(function (WebAccounts, $, undefined) {

    WebAccounts.Settings.PhoneHolder = {
        el: $('.account-settings-sub-item-wrapper.account-settings-phones-holder'),
        exists: function () {
            return !!this.el.length;
        }
    };

    if (!WebAccounts.Settings.PhoneHolder.exists()) {
        return;
    }

    WebAccounts.Settings.PhoneHolder.initialize = function () {
        this.addPhoneButton = $('.account-settings-phones-holder .add-phone-button')[0];
        this.alertNotificationHolder = $('#alertNotificationHolder');
        this.alertNotificationSuccess = this.alertNotificationHolder.find('.alert .alert-message');
        this.phoneCount = this.el.find('.phone-list-item').length | 0;
        this.alertNotificationHolder.hide();

        $('#add-phone').on('click', this.addPhone);
        $(document).on('click', '.remove-phone', this.removePhone);
        $(document).on('click', '#save-phone', this.validatePhone);
    };

    WebAccounts.Settings.PhoneHolder.addPhone = function (event) {
        event.preventDefault();

        var currentButton = $(this),
            addPhoneHolder = $('.add-phone-details-section');
        if (!addPhoneHolder.is(':animated')) {
            if (!currentButton.hasClass('visible')) {
                currentButton.addClass('holder-visible');
                addPhoneHolder.stop().slideDown(function () {
                    currentButton.addClass('visible');
                });
            } else {
                currentButton.removeClass('holder-visible');
                addPhoneHolder.stop().slideUp(function () {
                    currentButton.removeClass('visible');
                });
            }
        }
    };

    WebAccounts.Settings.PhoneHolder.clearAfterSubmit = function () {
        $('.add-phone-details-section').slideUp();
        $('.holder-visible').removeClass('holder-visible');
        $('#add-number').val('');
    };

    WebAccounts.Settings.PhoneHolder.handleErrors = function (error) {

        let phoneHolder = $("#account-settings-phones-holder");
        let phoneErrorContainer = phoneHolder.find('.error-message');
        phoneErrorContainer.empty();
        let errorKey = error.hasOwnProperty('phone') ? "phone" : 'phones';

        error[errorKey].forEach((err, message) => {
            phoneErrorContainer.append(`<p>${err.message || err}</p>`)
        });

        phoneErrorContainer.slideDown()
    }

    WebAccounts.Settings.PhoneHolder.savePhone = function (number) {
        let requestData = {
            "_token": csrfToken,
            "phone": number
        },
            loaderElement = $('.miniloader.phon-loader');

        $.ajax({
            url: '/api/account-settings/phone',
            type: 'POST',
            data: requestData,
            success: result => {

                let parsedResult = '';
                loaderElement.removeClass('visible');
                loaderElement.removeAttr('style')
                if (!result) {
                    return;
                }
                if (result.hasOwnProperty('errors')) {
                    this.handleErrors(result.errors);
                    return;
                }
                let phone_info = $('\
                    <div class="row account-settings-sub-item-parent">\
                        <div class="col-md-8 col-xs-11 account-settings-sub-item-holder">\
                            <div class="account-settings-phone row-item">\
                                <input type="hidden" name="value" value="'+ number + '">\
                                \<div class="radio-star-holder">\
                                        <input type="radio" data-wa-type="phones" id="primary-phone-'+ result['successes']['phones']['data'].id + '" name="primary-phone" value="' + result['successes']['phones']['data'].id + '"/>\
                                        <label for="primary-phone-'+ result['successes']['phones']['data'].id + '">\
                                            <span data-icon></span>\
                                        </label>\
                                </div>\
                                <div class="phone-detail form-element" id="phone-'+ result['successes']['phones']['data'].id + '" >\
                                    <p>'+ number + '</p>\
                                </div>\
                                <span class="remove-phone wa-bin" data-icon></span>\
                                <div id="loader" class="loading address-removing hide"></div>\
                                <div class="tooltip-holder">\
                                    <p>Cannot delete - minimum 1 phone number required</p>\
                                </div>\
                            </div>\
                        </div>\
                    </div>\
                ');

                WebAccounts.Settings.PhoneHolder.addPhoneButton.parentNode.insertBefore(phone_info[0],
                    WebAccounts.Settings.PhoneHolder.addPhoneButton);
                WebAccounts.Settings.PhoneHolder.alertNotificationHolder.show();
                WebAccounts.Settings.PhoneHolder.phoneCount++;

                this.clearAfterSubmit();

                WebAccounts.Utilities.ShowModal({
                    title: result['successes']['phones']['success'].message||"Phone number has been successfully added!",
                    type: "success"
                });
            },
            error: result => {
                this.handleErrors(result.responseJSON)
                loaderElement.removeClass('visible');
                loaderElement.slideUp();
            }
        });


    };

    WebAccounts.Settings.PhoneHolder.validatePhone = function (event) {
        event.preventDefault();

        let accountSettings = $(this).parents('.account-settings-holder'),
            number = $('#add-number').val(),
            number_text = 'number',
            errorMessage = $(accountSettings).find('.error-message'),
            loaderElement = $('.miniloader.phon-loader'),
            messages = {
                empty:trans('accountSettings.phone.empty'),
                invalid: trans('accountSettings.phone.error')
            }

        if (WebAccounts.isEmpty(number)) {
            errorMessage.html(messages.empty);
            errorMessage.show();

            return;
            // return console.log('Phone field is not valid/filled.');
        }

        let numberIsValid = WebAccounts.regex['phone'].test(number);

        if (!numberIsValid) {
            errorMessage.html(messages.invalid);
            errorMessage.show();

            return;
            // return console.log('Phone number not validated!')
        }
        errorMessage.hide();

        loaderElement.addClass('visible');
        loaderElement.slideDown();

        WebAccounts.Settings.PhoneHolder.savePhone(number);
    };

    WebAccounts.Settings.PhoneHolder.removePhone = function (event) {
        event.preventDefault();

        var phone = $(this),
            phone_input = phone.siblings('div.phone-detail'),
            phone_id = phone.siblings('div.phone-detail').attr('id'),
            isLast = $('span[class*=remove]').length <= 1,
            tooltipHolder = $('.tooltip-holder'),
            loaderElement = phone.siblings('.address-removing'),
            removingButton = phone,
            defaultPhone = phone.parents('.phone-list-item').find('input[id^="primary-phone-"]:checked');


        if (WebAccounts.Settings.PhoneHolder.phoneCount > 1 &&
            !!isLast) {
            return;
        }

        var phone_parts = phone_id.split("phone-").map(function (v) { return v.trim(); });
        var alertText = "";
        var requestData = {
            "_token": csrfToken,
            "phoneId": phone_parts[1]
        }

        removingButton.fadeOut();
        loaderElement.removeClass('hide');

        if (defaultPhone.length > 0) {
            alertText = `<span class="warning-alert">${trans('accountSettings.phone.delete.text')}</span>`;
        }

        WebAccounts.Utilities.ShowModal({
            title: trans('accountSettings.phone.delete.title'),
            text: alertText,
            type: 'warning',
            confirmButtonText: trans('accountSettings.phone.delete.confirmBtn'),
            showCancelButton: true
        }).then(isConfirm => {
            WebAccounts.Settings.PhoneHolder.phoneCount--;

            $.ajax({
                url: '/api/account-settings/phone',
                type: 'DELETE',
                data: requestData,
                success: result => {
                    loaderElement.addClass('hide');
                    removingButton.fadeIn();

                    if (result.hasOwnProperty('errors')) {

                    } {
                        WebAccounts.Utilities.ShowModal({
                            title: 'Deleted!',
                            text: result['successes']['phones']['success'].message||'Your phone number has been deleted.',
                            type: 'success',
                        });

                        phone.parent().parent().parent().remove();
                    }
                },
                error: function (result) {
                    loaderElement.addClass('hide');
                    removingButton.fadeIn();
                    WebAccounts.Settings.PhoneHolder.phoneCount++;
                }
            });
        }, function (dismiss) {
            loaderElement.addClass('hide');
            removingButton.fadeIn();

            if (dismiss === 'cancel') {

            }
        });
    };

    // on press enter page brokes send to /editPhone
    $(document).keypress(function (e) {
        if (e.which == 13) {
            e.preventDefault();
            $('#save-phone').click();
        }
    });

    WebAccounts.Settings.PhoneHolder.initialize();

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
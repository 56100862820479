(function(WebAccounts, $, undefined) {
    WebAccounts.serviceCardElements.serviceCardFilterHolder = {
        el: $('.history-wrapper'),
        exist: function() {
            return this.el.length > 0;
        },
    };

    if(!WebAccounts.serviceCardElements.serviceCardFilterHolder.exist()) {
        return;
    }

    /**
     * Filter the history service items by type/status/month/year.
     * Sets the selection of filters and passes it to another function,
     * to hide/show different items.
     * 
     * @see {@link WebAccounts.serviceCardElements.serviceCardFilterHolder} - Shows/hides different service items
     */
    WebAccounts.serviceCardElements.cardFilter = function() {
        var currentFilter = $(this),
        currentSelected = currentFilter.val(),
        filterType = '',
        filterStatus = '',
        filterMonth = '',
        filterYear = '';

        currentFilter.trigger('blur');

        if (currentFilter.hasClass('service-card-filter-type')) {
            filterType = currentSelected;
        } else if (currentFilter.hasClass('service-card-filter-status')) {
            filterStatus = currentSelected;
        } else if (currentFilter.hasClass('service-card-filter-month')) {
            filterMonth = currentSelected;
        } else if (currentFilter.hasClass('service-card-filter-year')) {
            filterYear = currentSelected;
        }

        if (!filterType) {
            filterType = $('.service-card-filter.service-card-filter-type').val();
        }

        if (!filterStatus) {
            filterStatus = $('.service-card-filter.service-card-filter-status').val();
        }

        if (!filterMonth) {
            filterMonth = $('.service-card-filter.service-card-filter-month').val();
        }

        if (!filterYear) {
            filterYear = $('.service-card-filter.service-card-filter-year').val();
        }


        if (!!filterType || !!filterStatus || !!filterMonth || !!filterYear) {
            $('.load-more').css({
                'display': 'none'
            });
        }

        WebAccounts.serviceCardElements.serviceCardFilterHolder($('.history-elements-holder'), filterType, filterStatus, filterMonth, filterYear);
    }

    /**
     * Fades out the whole service holder, and fades in each item one by one.
     * @param {Object} serviceCardHolder - The holder for the service card items
     * @param {String} filterType - The filter type one of these all/booking/quote/all. DEPRECATED
     * @param {String} filterStatus - The status by which to filter the service card items. DEPRECATED
     * @param {String} filterMonth - The month by which to filter the service card items.
     * @param {String} filterYear - The year by which to filter the service card items.
     */
    WebAccounts.serviceCardElements.serviceCardFilterHolder = function(serviceCardHolder, filterType, filterStatus, filterMonth, filterYear) {
        serviceCardHolder = (serviceCardHolder && serviceCardHolder.length > 0 && $(serviceCardHolder).length > 0) ? serviceCardHolder : $('.history-elements-holder');
        if (serviceCardHolder.length == 0) return;

        filterType = (!filterType || (filterType !== 'all' && filterType !== 'booking' && filterType !== 'quote')) ? "all" : filterType;
        filterStatus = (!filterStatus || bookingStatuses.indexOf(filterStatus) === -1) ? "all" : filterStatus;
        filterMonth = (!filterMonth || WebAccounts.allMonths.indexOf(filterMonth) === -1) ? 0 : filterMonth;
        filterYear = (!filterYear || WebAccounts.allYears.indexOf(filterYear) === -1) ? 0 : filterYear;

        var serviceCardWrapper = serviceCardHolder.parents('.content-holder'),
            serviceCardParent = serviceCardWrapper.find('.content-inner-holder'),
            serviceCardParentFiltered = serviceCardParent,
            serviceCardParentAnimationDuration = Number(serviceCardParent.css('animation-duration').split('s')[0]),
            serviceCardElements = serviceCardHolder.find('.service-card-item'),
            serviceCardElementsParent = serviceCardElements.parent(),
            serviceCardElementsFiltered = serviceCardElementsParent,
            serviceCardFilterString = "";

        serviceCardWrapper.height(serviceCardWrapper.height());

        serviceCardHolder.fadeOut(300, function () {

            serviceCardParent.find('.title-holder.border-bottom').removeClass('border-bottom');

            if (filterType === 'booking' || filterType === 'quote') {
                serviceCardElementsFiltered = serviceCardElementsFiltered.filter('.' + filterType);
                serviceCardFilterString += '.' + filterType;
            }

            if (bookingStatuses.indexOf(filterStatus) !== -1) {
                serviceCardElementsFiltered = serviceCardElementsFiltered.filter('.' + filterStatus);
                serviceCardFilterString += '.' + filterStatus;
            }

            if (WebAccounts.allMonths.indexOf(filterMonth) !== -1) {
                serviceCardParentFiltered = serviceCardParentFiltered.filter('.month-' + filterMonth);
            }

            if (WebAccounts.allYears.indexOf(filterYear) !== -1) {
                serviceCardParentFiltered = serviceCardParentFiltered.filter('.year-' + filterYear);
            }

            if (serviceCardElementsParent.length !== serviceCardElementsFiltered.length) {
                serviceCardElementsParent.css({
                    'display': 'none'
                });
            } else {
                serviceCardElementsFiltered = serviceCardElementsParent;
            }

            serviceCardElementsFiltered.css({
                'display': 'block'
            });

            if (!serviceCardFilterString) {
                serviceCardFilterString = '.service-card-item';
            }

            if (!serviceCardElementsFiltered.length) {
                serviceCardParentFiltered = $();
            }

            if (serviceCardParent.length !== serviceCardParentFiltered.length) {
                serviceCardParent.css({
                    'display': 'none'
                });
            } else {
                serviceCardParentFiltered = serviceCardParent;
            }

            if (serviceCardParentFiltered.length > 0) {
                serviceCardParentFiltered = serviceCardParentFiltered.filter(function(currentParentIndex) {
                    if (!$(this).find(serviceCardFilterString).length > 0) {
                        $(this).css({
                        'display': 'none'
                        });
                        return false;
                    } else {
                        return true;
                    }
                });
            }

            if(serviceCardParentFiltered.find(serviceCardFilterString).length > 0) {
                serviceCardParentFiltered.css({
                    'display': 'block'
                });
            }

            serviceCardParentFiltered.each(function(currentServiceCardRowIndex) {
                var currentServiceCardElement = $(this),
                    currentServiceCardDelay = (currentServiceCardRowIndex / 10);

                // if (currentServiceCardRowIndex !== 0) {
                    currentServiceCardElement.find('.title-holder').addClass('border-bottom');
                // }

                currentServiceCardElement.css({
                    '-webkit-animation-delay': currentServiceCardDelay +'s',
                    '-moz-animation-delay': currentServiceCardDelay +'s',
                    'animation-delay': currentServiceCardDelay + 's',
                    '-webkit-animation-duration': '0.3s',
                    '-moz-animation-duration': '0.3s',
                    'animation-duration': '0.3s'
                });
            });

            serviceCardParentAnimationDuration = serviceCardParentFiltered.length > 0 ? Number(serviceCardParentFiltered.css('animation-duration').split('s')[0]) : 0;

            serviceCardParentFiltered.find(serviceCardFilterString).each(function (currentServiceCardIndex) {
                var currentServiceCardElement = $(this),
                    currentServiceCardDelay = (currentServiceCardIndex / 10) + serviceCardParentAnimationDuration;

                currentServiceCardElement.css({
                    '-webkit-animation-delay': currentServiceCardDelay +'s',
                    '-moz-animation-delay': currentServiceCardDelay +'s',
                    'animation-delay': currentServiceCardDelay + 's',
                    '-webkit-animation-duration': '0.3s',
                    '-moz-animation-duration': '0.3s',
                    'animation-duration': '0.3s'
                });

            });

            serviceCardParentFiltered.find(serviceCardFilterString).addClass('fade-in-delayed-side fade-in-left');

            setTimeout(function() {
                serviceCardHolder.fadeIn(300, function() {
                    serviceCardWrapper.height('auto');
                });
            }, 300);
        });
    }

    $(document).on('change', 'select.service-card-filter', WebAccounts.serviceCardElements.cardFilter );

})(window.WebAccounts = window.WebAccounts || {}, jQuery);


const { trans } = require("../common/helpers");

(function (WebAccounts, $, undefined) {
    WebAccounts.Settings.AccountHolder = {
        el: $('.account-settings-addresses-holder'),
        exists: function () {
            return !!this.el.length;
        }
    };

    if (!WebAccounts.Settings.AccountHolder.exists()) {
        return;
    }

    /**
     * Initializes the functions handling the address settings card
     * 
     * @return  void 
     */
    WebAccounts.Settings.AccountHolder.initialize = function () {
        this.addAddressButton = $('.account-settings-addresses-holder .miniloader')[0];
        this.alertNotificationHolder = $('#alertNotificationHolder');
        this.alertNotificationSuccess = this.alertNotificationHolder.find('.alert .alert-message');
        this.addressesCount = this.el.find('.address-list-item').length | 0;

        this.alertNotificationHolder.hide();


        $('#add-address').on('click', this.openForm);
        $(document).on('click', '.remove-address', WebAccounts.Settings.AccountHolder.removeAddress);
        $(document).on('click', '#save-address', WebAccounts.Settings.AccountHolder.saveForm);
    };

    /**
     * The function opens the address forms
     * 
     * @param    event    The passed click event
     */
    WebAccounts.Settings.AccountHolder.openForm = function (event) {
        event.preventDefault();

        var currentButton = $(this),
            addAddressHolder = $('.add-address-details-section');

        if (addAddressHolder.is(':animated')) {
            return;
        }

        if (!addAddressHolder.hasClass('visible')) {
            currentButton.addClass('holder-visible');
            addAddressHolder.stop().slideDown(function () {
                $(this).addClass('visible');
            });
            return;
        }

        currentButton.removeClass('holder-visible');
        addAddressHolder.stop().slideUp(function () {
            $(this).removeClass('visible');
        });
    };

    /**
     * Fires the validation for the form, and sets an error message if any.
     * If no errors occur it fires the add address form
     * 
     * @param {Object} event - A submit event
     * @see {@link WebAccounts.Settings.AccountHolder.addAddress} - Where the request data is fired
     */
    WebAccounts.Settings.AccountHolder.saveForm = function (event) {
        event.preventDefault();

        var accountSettingsParent = $(this).parents('.account-settings-parent-holder'),
            address1 = $('#add-address-1'),
            postcode = $('#add-postcode'),
            city = $('#add-city'),
            isAddressFormValid = WebAccounts.Settings.AccountHolder.validateAddress(address1, postcode, city);

        if (!isAddressFormValid.valid) {
            var errorMessage = $(accountSettingsParent).find('.error-message'),
                errorMessageHTML = document.createElement('div'),
                invalidFieldsLength = isAddressFormValid.invalidMessages.length;

            if (invalidFieldsLength > 0) {
                for (var i = invalidFieldsLength - 1; i >= 0; i--) {
                    var newParagraph = document.createElement('p'),
                        textNode = document.createTextNode(isAddressFormValid.invalidMessages[i]);

                    newParagraph.appendChild(textNode);
                    errorMessageHTML.appendChild(newParagraph);
                }
            }

            errorMessage.html(errorMessageHTML);
            errorMessage.show();
            return;
            // return console.log('Address Form is not valid.');
        }

        var requestData = {
            "_token": csrfToken,
            "address1": address1.val().trim(),
            "postcode": postcode.val().trim(),
            "city": city.val().trim()
        };

        WebAccounts.Settings.AccountHolder.addAddress(requestData);
    };

    /**
     * Validates the address form
     * 
     * @param  {Object} address - A jQuery object of the address input
     * @param  {Object} postcode - jQuery object of the postcode input
     * @param  {Object} city - jQuery object of the city input
     * @return  {Object} - The return object with these properties, is the form valid, the error message and the number of incorrect fields.
     */
    WebAccounts.Settings.AccountHolder.validateAddress = function (address, postcode, city) {
        var response = {
            valid: true,
            invalidMessages: [],
            incorrectFieldsCount: 0
        },
            invalidMessages = {
                address:  trans('accountSettings.address.error'),
                postcode:  trans('accountSettings.postcode.error'),
                city: trans('accountSettings.city.error'),
                allFields:  trans('accountSettings.fields.error'),
            }

        if (!WebAccounts.isFormElementValid(address)) {
            response.valid = false;
            response.incorrectFieldsCount++;
            response.invalidMessages.push(invalidMessages.address);
        }

        if (!WebAccounts.isFormElementValid(postcode)) {
            response.valid = false;
            response.incorrectFieldsCount++;
            response.invalidMessages.push(invalidMessages.postcode);
        }

        if (!WebAccounts.isFormElementValid(city)) {
            response.valid = false;
            response.incorrectFieldsCount++;
            response.invalidMessages.push(invalidMessages.city);
        }

        if (arguments.length === response.incorrectFieldsCount) {
            response.invalidMessages.push(invalidMessages.allFields);
        }


        return response;
    };

    /**
     * Fires a jQuery event to the /account-settings/saveAddresses endpoint,
     * and handles the response.
     * 
     * @param {Object} requestData - The passed request data, constructed in AccountHolder.saveForm
     */
    WebAccounts.Settings.AccountHolder.addAddress = function (requestData) {
        let errorMessage = $('.error-message'),
            loaderElement = $('.miniloader.addr-loader');

        errorMessage.hide();
        loaderElement.addClass('visible');
        loaderElement.slideDown();

        $.ajax({
            type: 'POST',
            url: '/api/account-settings/address',
            data: requestData,
            success: result => {

                loaderElement.removeClass('visible');
                if (!result) {
                    return;
                }

                if (result.hasOwnProperty('errors')) {
                    this.handleErrors(result.errors);
                    return;
                }

                var address_info = $('\
                    <div class="row account-settings-sub-item-parent old">\
                        <div class="col-md-8 col-xs-11 account-settings-sub-item-holder">\
                            <div class="account-settings-address  row-item">\
                            <input type = "hidden" name = "postcode" value="'+ requestData.postcode +'" >\
                            <input type="hidden" name="address_line_one" value="'+ requestData.address1 +'">\
                            <input type="hidden" name="city" value="'+ requestData.city +'">\
                                <div class="radio-star-holder">\
                                        <input type="radio" data-wa-type="addresses" id="primary-address-'+ result.successes["addresses"]["data"].id + '" name="primary-address" value="' + result.successes['addresses']['data'].id + '"/>\
                                        <label for="primary-address-'+ result.successes["addresses"]["data"].id + '">\
                                            <span data-icon></span>\
                                        </label>\
                                </div>\
                                <div class="address-detail" class="form-element" id="address-'+ result.successes["addresses"]["data"].id + '" >\
                                    <p>' + requestData.address1 + ', ' + requestData.postcode + ', ' + requestData.city + ' </p>\
                                </div>\
                                <span class="remove-address wa-bin" data-icon></span>\
                                <div id="loader" class="loading address-removing hide"></div>\
                                <div class="tooltip-holder">\
                                    <p>Cannot delete - minimum 1 address required</p>\
                                </div>\
                            </div>\
                        </div>\
                    </div>\
                ');

                WebAccounts.Settings.AccountHolder.addAddressButton.parentNode.insertBefore(address_info[0], WebAccounts.Settings.AccountHolder.addAddressButton);
                WebAccounts.Settings.AccountHolder.alertNotificationHolder.show();

                WebAccounts.Settings.AccountHolder.clearForm();
                WebAccounts.Settings.AccountHolder.addressesCount++;
                WebAccounts.Utilities.ShowModal({
                    title: result.successes['addresses']['success'].message||"Address has been successfully added!",
                    type: 'success'
                });

            },
            error: function (result) {

                loaderElement.removeClass('visible');
                loaderElement.slideUp();

                WebAccounts.Settings.AccountHolder.clearForm();

            }
        });

        $('.add-address-details-section').slideUp();
        $('.holder-visible').removeClass('holder-visible');
    };

    WebAccounts.Settings.AccountHolder.handleErrors = error => {
        let errorMessage = $('#addresses-internal');
        errorMessage.empty();

        error.addresses.forEach(element => {
            let textNode = document.createElement('P')
            textNode.innerText = element.message;
            errorMessage.append(`<p>${element.message}</p>`)
        });
        errorMessage.show();
        setTimeout(() => {
            errorMessage.fadeOut();
        }, 10000)
    }

    WebAccounts.Settings.AccountHolder.clearForm = function () {
        $('#add-address-1').val('');
        $('#add-postcode').val('');
        $('#add-city').val('');
    }

    /**
     * Creates an object with the data for the address to be removed
     * and passes it to the account-settings/saveAddresses endpoint
     */
    WebAccounts.Settings.AccountHolder.removeAddress = function (event) {
        event.preventDefault();

        var removingButton = $(this),
            address_input = removingButton.siblings('.address-detail'),
            address_id = removingButton.siblings('.address-detail').attr('id'),
            isLast = $('span[class*=remove]').length <= 1,
            tooltipHolder = $('.tooltip-holder'),
            loaderElement = removingButton.siblings('.address-removing'),
            defaultAddress = removingButton.parents('.address-list-item').find('input[id^="primary-address-"]:checked');

        if (WebAccounts.Settings.AccountHolder.addressesCount <= 1 &&
            !!isLast) {
            return;
        }

        var address_parts = address_id.split("address-").map(function (v) { return v.trim(); });
        var requestData = {
            "_token": csrfToken,
            "addressId": address_parts[1]
        }
        var alertText = '';

        removingButton.fadeOut();
        loaderElement.removeClass('hide');

        if (defaultAddress.length > 0) {
            alertText = `<span class="warning-alert">${trans('accountSettings.address.delete.text')}</span>`;
        }
        
        WebAccounts.Utilities.ShowModal({
            title: trans('accountSettings.address.delete.title'),
            text: alertText,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: trans('accountSettings.address.delete.confirmBtn'),
            showCloseButton: true
        }).then(function(isConfirm) {
            WebAccounts.Settings.AccountHolder.addressesCount--;

            $.ajax({
                url: '/api/account-settings/address',
                type: 'DELETE',
                data: requestData,
                success: result => {
                    loaderElement.addClass('hide');
                    removingButton.fadeIn();
                    if (!result) {
                        return;
                    }
                    if (result.hasOwnProperty('errors')) {
                        this.handleErrors(result.errors);
                    }
                    console.log(result)
                    WebAccounts.Utilities.ShowModal({
                        title: result.successes['addresses']['success'].message||"Your address has been successfully deleted",
                        type:"success"
                    });

                    removingButton.parent().parent().parent().remove();
                },
                error: function (result) {
                    WebAccounts.Settings.AccountHolder.addressesCount++;
                    loaderElement.addClass('hide');
                    removingButton.fadeIn();
                }
            });
        }, function (dismiss) {
            loaderElement.addClass('hide');
            removingButton.fadeIn();

            // if (dismiss === 'cancel') {

            // }
        });
    };

    // on press enter page brokes send to /editPhone
    $(document).keypress(function (e) {
        if (e.which == 13) {
            e.preventDefault();
            $('#save-address').click();
        }
    });

    //Initialize the address settings card
    WebAccounts.Settings.AccountHolder.initialize();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
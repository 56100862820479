class Locale {
    constructor(template) {
        this.locale = '';
        this.template = template;
        this.localeDir = '/accounts/build/lang/';
        this.fallbackLocale = '';
        this.fallbackTranslations = {};
        this.translations = {};
    }

    /**
     * Get translation from dot path
     * 
     * @param {string} path 
     * @example locale.trans('login.sign-in')
     * @return {null|string}
     */
    trans(path, wildcards) {
        const components = path.split('.');
        let trans = this._findIn(this.translations, components);

        if (trans == null) {
            trans = this._findIn(this.fallbackTranslations, components);
        }
        if (typeof wildcards == "object" && trans != null) {
            for (const wildcard in wildcards) {
                if (wildcards.hasOwnProperty(wildcard)) {
                    trans = trans.replace(`|:${wildcard}:|`, wildcards[wildcard]);
                }
            }
        }
        return trans;
    }

    /**
     * Load locale files
     * 
     * @param {string} locale 
     * 
     * @return {Promise}
     */
    load(locale) {
        this.locale = locale;
        return this._loadTrans();
    }

    /**
     * Load fallback locale files
     * 
     * @param {string} locale 
     * 
     * @return {Promise}
     */
    loadFallback(locale) {
        this.fallbackLocale = locale;
        if (this.locale != this.fallbackLocale) {
            return this._loadTrans('fallback')
        }
    }

    /**
     * Finds translation in all trans files
     * 
     * @param {Object} collection 
     * @param {Array<string>} components 
     */
    _findIn(collection, components) {
        let trans = collection;
        for (let i = 0; i < components.length; i++) {
            if (trans.hasOwnProperty(components[i])) {
                trans = trans[components[i]];
                continue;
            }
            return null;
        }
        return trans;
    }

    /**
     * Loads given type of Translations:
     *  fallbackTranslations
     *  translations
     * 
     * @param {string} type ['fallback']
     * @return {Promise} 
     */
    _loadTrans(type) {
        return new Promise(async (resolve, reject) => {
            const locale = type == 'fallback' ? this.fallbackLocale : this.locale;
            try {
                const transFile = await fetch(`${this.localeDir}${this.template}/${locale}.json?${Math.round(Math.random()*1000)}`);
                const jsonData = await transFile.json()
                if (type == 'fallback') {
                    this.fallbackTranslations = jsonData;
                    return resolve('loaded');
                }
                this.translations = jsonData;
                return resolve('loaded');
            } catch (error) {
                console.error(`There was an error loading "${this.localeDir}"`)
                reject(error)
            }
        })
    }
}

export default Locale;

import { WAModal } from "../common/wa-modal";
import { ScriptLoader } from "../common/script-loader";
import { CheckoutProvider } from "../payments/checkout-provider";
import { parseBoolean, getElement, getMetaTagContent, trans, ucfirst, isCallable, deleteElements } from "../common/helpers";
import { BraintreeProvider } from "../payments/braintree-provider";
import { CardCollector } from '../common/card-collector'
import { StripeProvider } from "../payments/stripe-provider";
import * as BraintreeValidator from 'card-validator'
import CARD_BRANDS from "../common/card-const"
import { getProviderByType } from "../common/payment-provider-type-map";

(function (WebAccounts, $, undefined) {
    WebAccounts.Settings.CardHolder = {
        el: $('.account-settings-cards-holder'),
        threeDSecureEnabled: false,
        threeDSecure: null,
        paymentProviderPubKey: null,
        providerInstance: null,
        action_type: null,
        cardCollector: null,
        loaderElement: $('.miniloader.card-loader'),
        exists: function () {
            return !!this.el.length;
        }
    };


    if (!WebAccounts.Settings.CardHolder.exists()) {
        return;
    }

    WebAccounts.Settings.CardHolder.loadDependencies = async (payment_provider_type) => {
        switch (payment_provider_type) {
            case "checkout":
                return await (new ScriptLoader(['https://cdn.checkout.com/js/frames.js'])).load();
            case "stripe":
                return await (new ScriptLoader(['https://js.stripe.com/v3/'])).load();
            case "braintree":
                await (new ScriptLoader(['https://js.braintreegateway.com/js/braintree-2.32.1.min.js'])).load();
                await (new ScriptLoader(['https://js.braintreegateway.com/web/3.48.0/js/client.min.js'])).load();
                await (new ScriptLoader(['https://js.braintreegateway.com/web/3.48.0/js/data-collector.min.js'])).load();
                return await (new ScriptLoader(['https://js.braintreegateway.com/web/3.48.0/js/three-d-secure.min.js'])).load();
            default:
                console.log("No payment provider loaded.")
        }
        return null;
    }

    /**
     * Opens the Credit Card form
     * @param {Object} event - The click event
     */
    WebAccounts.Settings.CardHolder.openForm = async (event) => {
        event.preventDefault();

        var currentButton = $(this),
            addPaymentHolder = $('.add-card-details-section'),
            paymentForm = getElement('#add-details-form'),
            paymentFormLoader = getElement('#payment-form-loader'),
            loader = getElement('#add-card-provider-loader');
        loader.classList.remove('visible')

        if (addPaymentHolder.is(':animated')) {
            return;
        }

        if (!addPaymentHolder.hasClass('visible')) {
            loader.classList.add('visible')
            const response = await WebAccounts.Settings.CardHolder.getUserPaymentSetup(WebAccounts.Settings.CardHolder.payment_method_id);
            WebAccounts.Settings.CardHolder.preparePaymentProvider(response);
            loader.classList.remove('visible')
            paymentForm.classList.remove('hidden')
            paymentFormLoader.classList.remove('visible')

            currentButton.addClass('holder-visible');
            addPaymentHolder.stop().slideDown(function () {
                $(this).addClass('visible');
            });
            return;
        }

        currentButton.removeClass('holder-visible');
        addPaymentHolder.stop().slideUp(function () {
            $(this).removeClass('visible');
        });
    };

    WebAccounts.Settings.CardHolder.handleBraintreeExceptions = (exception) => {
        WebAccounts.Settings.CardHolder.loaderElement.removeClass('visible');
        WebAccounts.Settings.CardHolder.loaderElement.slideUp();
        const errorMessage = $('.account-settings-cards-holder .error-message'),
            addDetailsForm = $('#add-details-form');
        try {
            if (exception.response.hasOwnProperty('error')) {
                let $errContainer;

                $errContainer = $(`#${exception.response.error.code}`)

                $errContainer.append(`<p class="error-message js-val-error" style="color: red;">${exception.response.error.message}</p >`);
            } else {
                errorMessage.html(trans('general.errors.generic'));
            }
        } catch (error) {
            errorMessage.html(trans('general.errors.generic'));
        }

        // alertNotificationHolder.append(alert_notification_error[0], alertNotificationHolder);
        WebAccounts.Settings.CardHolder.alertNotificationError.show();
        addDetailsForm.find('input, label').addClass('error');
    }

    WebAccounts.Settings.CardHolder.handleErrors = errors => {
        let message = trans('cardCollection.cardDecline');
        try {
            message = errors.paymethods[0]['message'];
        } catch (error) { }
        let errorMessage = $('#declinedCard');
        errorMessage.html(message);
        errorMessage.show();
    }

    WebAccounts.Settings.CardHolder.removeCard = function (event) {
        event.preventDefault();

        var removingButton = $(this);
        var cardID = removingButton.siblings('.card-detail').attr('id');

        var loaderElement = removingButton.siblings('.address-removing');

        var cardParts = cardID.split("card-").map(function (v) {
            return v.trim();
        });

        var requestData = {
            "_token": csrfToken,
            "cardId": cardParts[1]
        }

        removingButton.fadeOut();
        loaderElement.removeClass('hide');

        WebAccounts.Utilities.ShowModal({
            title: 'Are you sure?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then(function (isConfirm) {
            $.ajax({
                url: '/api/account-settings/paymethods',
                type: 'DELETE',
                data: requestData,
                success: function (result) {
                    loaderElement.addClass('hide');
                    removingButton.fadeIn();

                    if (result.hasOwnProperty('errors')) {
                        WebAccounts.Utilities.ShowModal({
                            title: result['errors']['paymethods'][0].message || 'This card has a pending payment!',
                            type: 'error'
                        });
                    } else {
                        WebAccounts.Utilities.ShowModal({
                            title: result['successes']['paymethods']['success'].message || 'Your card details have been successfully deleted!',
                            type: 'success'
                        });

                        removingButton.parent().parent().parent().remove();
                    }

                    loaderElement.addClass('hide');
                    removingButton.fadeIn();
                },
                error: function (result) {
                    loaderElement.addClass('hide');
                    removingButton.fadeIn();
                }
            });
        }, function (dismiss) {
            loaderElement.addClass('hide');
            removingButton.fadeIn();
        });

    };

    WebAccounts.Settings.CardHolder.getUserPaymentSetup = (paymentMethodID) => {
        return new Promise((res, rej) => {
            $.ajax({
                url: '/api/user/paymethod_setup',
                type: 'GET',
                data: { payment_method_id: paymentMethodID },
                success: (result) => {
                    if (result.data && result.data && result.data.paymethod_setup) {
                        return res(result.data.paymethod_setup);
                    }
                    rej(result)
                }
            })
        })
    }

    WebAccounts.Settings.CardHolder.initialize = function () {
        this.alertNotificationHolder = $('#alertNotificationHolder');
        this.alertNotificationSuccess = this.alertNotificationHolder.find('.alert.alert-success');
        this.alertNotificationError = this.alertNotificationHolder.find('.alert.alert-danger');
        this.newCardErrorMessage = $('.account-settings-new-card-error label');
        this.brandImagePath = '/images/card-logos/';
        this.addCardButton = $(WebAccounts.Settings.CardHolder.el).find(".account-settings-sub-item-add-new")[0];

        try {
            const is3DSecured = getMetaTagContent('payment-provider-3ds');
            WebAccounts.Settings.CardHolder.threeDSecureEnabled = parseBoolean(is3DSecured);
        } catch (error) {
            WebAccounts.Settings.CardHolder.threeDSecureEnabled = false;
        }

        $('#add-card').on('click', this.openForm);
        $(document).on('click', '.remove-card', WebAccounts.Settings.CardHolder.removeCard);

        WebAccounts.Settings.CardHolder.payment_method_id = $('meta[name="payment-method-id"]').attr('content');
    };

    WebAccounts.Settings.CardHolder.makeRequest = function (requestData, cardDetails) {
        let addDetailsForm = $('#add-details-form'),
            errorMessage = $('.account-settings-cards-holder .error-message');

        $.ajax({
            url: '/api/account-settings/paymethods',
            type: 'POST',
            data: requestData,
            success: async result => {
                WebAccounts.Settings.CardHolder.loaderElement.removeClass('visible');

                if (!result) {
                    errorMessage.html(trans('general.errors.generic'));
                    errorMessage.show();
                    return;
                }

                if (result.hasOwnProperty('errors')) {
                    if (WebAccounts.Settings.CardHolder.payment_provider_type == "Checkoutcom") {
                        WebAccounts.Utilities.ShowModal({
                            title: result['errors']['paymethods'][0]['message'],
                            type: 'error',
                        });
                        WebAccounts.Settings.CardHolder.checkoutTokenizer.unblock();
                        return;
                    }

                    WebAccounts.Settings.CardHolder.getUserPaymentSetup(WebAccounts.Settings.CardHolder.payment_method_id)
                        .then((refreshedResponse) => {
                            console.log(refreshedResponse)
                            WebAccounts.Settings.CardHolder.preparePaymentProvider(refreshedResponse);
                        });

                    WebAccounts.Settings.CardHolder.handleErrors(result.errors);
                    return;
                }


                const paymethodId = result['successes']['paymethods']['data'].id;

                if (cardDetails !== undefined) {

                    addDetailsForm.find('input, label').removeClass('error');

                    $('.holder-visible').removeClass('holder-visible');

                    $('.add-card-details-section').slideUp(1000, function () {
                        $('#card-expiry').val('');
                        $("#card-number").val('');
                        $("#card-cvv").val('');
                        $("#card-name").val('');
                    });

                    errorMessage.hide();

                    if (window.hasOwnProperty('card')) {
                        $(window.card.$el).trigger('reset');
                        $(window.card.$cardContainer).remove();

                        window.card.render();
                        window.card.attachHandlers();
                        window.card.handleInitialPlaceholders();
                    }

                    let cardDescriptionParagraph = `**** **** **** ${cardDetails.lastFourSymbols}`;

                    if (cardDetails.cardDescription != '') {
                        cardDescriptionParagraph = cardDetails.cardDescription + ' ' + '<span style="position: relative; top: 0; right: 0px;" class="hidden-xs hidden-sm hidden-md">( **** **** **** ' + cardDetails.lastFourSymbols + ' )</span>';
                    }

                    var siteUrl = document.domain,
                        imageExtension = '.png',
                        brandName = cardDetails.brand.toLowerCase().split(' ').join('-'),
                        imageCheckUrl = WebAccounts.Settings.CardHolder.brandImagePath + brandName + imageExtension;

                    if (!WebAccounts.imageExists(imageCheckUrl)) {
                        brandName = 'undefine';
                    }

                    var imageUrl = WebAccounts.Settings.CardHolder.brandImagePath + brandName + imageExtension;
                    var card_info = $('\<div class="row account-settings-sub-item-parent">\
                                        <div style="font-size: 14px;" class="col-sm-8 col-xs-11 account-settings-sub-item-holder">\
                                            <div class="account-settings-card">\
                                                \
                                                <div class="show-info-detail card-detail" class="form-element" id="card-' + paymethodId + '" >\
                                                    <p> ' + cardDescriptionParagraph + ' </p>\
                                                    <span>\
                                                        <img src="' + imageUrl + '" title="' + cardDetails.brand + '" alt="' + cardDetails.brand + '" width="50px">\
                                                    </span>\
                                                </div>\
                                                <span class="remove-card wa-bin" data-icon></span>\
                                                <div id="loader" class="loading address-removing hide"></div>\
                                            </div>\
                                        </div>\
                                    </div>');


                    WebAccounts.Settings.CardHolder.addCardButton.parentNode.insertBefore(card_info[0], WebAccounts.Settings.CardHolder.addCardButton);
                    WebAccounts.Settings.CardHolder.alertNotificationSuccess.show();
                }
                WebAccounts.Utilities.ShowModal({
                    title: result['successes']['paymethods']['success'].message || 'Your card details have been successfully added!',
                    type: 'success',
                    allowOutsideClick: false,
                    onClose: () => location.reload()
                }).then(() => location.reload());
            },
            error: function (result) {
                errorMessage.html(trans('general.errors.generic'));
                errorMessage.show();
            }
        });
    }

    WebAccounts.Settings.CardHolder.sendStripePayload = (response) => {
        if (response.hasOwnProperty('error')) {
            WebAccounts.Settings.CardHolder.loaderElement.removeClass('visible');
            WebAccounts.Utilities.ShowModal({
                title: response.error.message,
                allowOutsideClick: false,
            });
            return;
        }

        let token = null;

        if (response.hasOwnProperty('setupIntent')) {
            token = response.setupIntent.payment_method;
        } else if (response.hasOwnProperty('paymentIntent')) {
            token = response.paymentIntent.id;
        }

        WebAccounts.Settings.CardHolder.makeRequest({
            '_token': csrfToken,
            'payload': {
                "payment_method_id": WebAccounts.Settings.CardHolder.payment_method_id,
                "setup": {
                    "type": WebAccounts.Settings.CardHolder.action_type,
                    "payload": {
                        "token": token
                    }
                },
            }
        })
    };


    WebAccounts.Settings.CardHolder.getChallenge = () => {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/paymethods/${WebAccounts.Settings.CardHolder.payment_method_id}/challenge`,
                type: 'GET',
                data: {
                    '_token': csrfToken
                },
                success: result => {
                    resolve(result.data.three_d_security_two_challenge)
                }
            })
        })
    }

    WebAccounts.Settings.CardHolder.getChallengeToken = () => {
        return new Promise(async (resolve, reject) => {
            let response = await WebAccounts.Settings.CardHolder.getChallenge();
            resolve(response.challenge_token);
        })
    }


    WebAccounts.Settings.CardHolder.initCheckout = (payload) => {
        const submitButton = getElement('#save-details');
        WebAccounts.Settings.CardHolder.checkoutTokenizer = new CheckoutProvider(WebAccounts.Settings.CardHolder.payment_provider_pub_key, '#add-details-form', submitButton)
        WebAccounts.Settings.CardHolder.checkoutTokenizer.init((token) => {
            const requestData = {
                '_token': csrfToken,
                'payload': {
                    "payment_method_id": WebAccounts.Settings.CardHolder.payment_method_id,
                    "data": {
                        "token": token,
                    },
                }
            };

            WebAccounts.Settings.CardHolder.makeRequest(requestData)
        }, (error) => {
            // console.log(event);
        }, () => { // adds additional behavior to the submit button
            WebAccounts.Settings.CardHolder.loaderElement.addClass('visible');
            WebAccounts.Settings.CardHolder.loaderElement.slideDown();
        })
    }

    WebAccounts.Settings.CardHolder.initStripe = (data) => {
        const submitButton = getElement('#save-details'),
            errContainer = getElement('#dynamic-error-message'),
            paymentProvider = getProviderByType(data.type),
            StripePayment = new StripeProvider(data.payload.public_key, '#add-details-form', submitButton);
        StripePayment.setIntent(paymentProvider.options.intent);
        StripePayment.setChallengeToken(() => data.payload.client_secret);

        StripePayment.onSuccess(WebAccounts.Settings.CardHolder.sendStripePayload);

        StripePayment.setCustomValidator((event) => {
            if (data.payload.unsupported_brands && Array.isArray(data.payload.unsupported_brands)) {
                let result = event.brand && data.payload.unsupported_brands.indexOf(event.brand) === -1;
                if (!result) {
                    errContainer.style.display = 'block';
                    errContainer.innerText = trans('payments.errors.unsupported')
                } else {
                    errContainer.style.display = 'none';
                }
                return result;
            }

            return true;
        });

        StripePayment.onStartProcessing(() => {
            submitButton.classList.add('disabled')
            WebAccounts.Settings.CardHolder.loaderElement.addClass('visible');
            WebAccounts.Settings.CardHolder.loaderElement.slideDown();
        });
        return StripePayment;
    }

    WebAccounts.Settings.CardHolder.configureStripe = (data) => {
        const paymentProvider = getProviderByType(data.type);
        WebAccounts.Settings.CardHolder.providerInstance.setIntent(paymentProvider.options.intent);
        WebAccounts.Settings.CardHolder.providerInstance.setChallengeToken(() => data.payload.client_secret);

        return WebAccounts.Settings.CardHolder.providerInstance;
    }

    WebAccounts.Settings.CardHolder.initBraintree = (data) => {
        const paymentProvider = new BraintreeProvider();
        const braintreeProvider = getProviderByType(data.type)

        const addCardBTN = getElement('#save-details');
        addCardBTN.classList.remove('disabled')
        paymentProvider.setApiKey(data.payload.public_key);

        const enabled3DS = braintreeProvider.options._3ds

        paymentProvider.set3DS(enabled3DS);
        if (data.payload.hasOwnProperty('amount')) {
            paymentProvider.setVerificationAmount(data.payload.amount);
        }
        WebAccounts.Settings.CardHolder.cardCollector = new CardCollector();
        WebAccounts.Settings.CardHolder.cardCollector.setCustomFieldValidation({
            number: (collectorInstance) => {
                const cardData = BraintreeValidator.number(collectorInstance.cardDefinition['number'].value);
                console.log(cardData);
                if (data.payload.unsupported_brands
                    && Array.isArray(data.payload.unsupported_brands)
                    && cardData.card && cardData.card.type && cardData.isValid) {

                    const brand = CARD_BRANDS[cardData.card.type] || cardData.card.type;
                    let isUnsupported = data.payload.unsupported_brands.indexOf(brand) !== -1;

                    if (isUnsupported && brand) {
                        collectorInstance.errorBag.push({
                            field: collectorInstance.cardDefinition['number'].cachedNode,
                            error: trans('payments.errors.unsupported')
                        });
                    }
                }
                if (!cardData.isValid) {
                    collectorInstance.errorBag.push({
                        field: collectorInstance.cardDefinition['number'].cachedNode,
                        error: trans('payments.errors.number')
                    });
                }
            }
        })

        $('#save-details').on('click', WebAccounts.Settings.CardHolder.processBraintree);

        return paymentProvider;
    }

    WebAccounts.Settings.CardHolder.configureBraintree = (data) => {
        WebAccounts.Settings.CardHolder.providerInstance = new BraintreeProvider();
        WebAccounts.Settings.CardHolder.providerInstance.setApiKey(data.payload.public_key);

        const braintreeProvider = getProviderByType(data.type)
        const enabled3DS = braintreeProvider.options._3ds

        WebAccounts.Settings.CardHolder.providerInstance.set3DS(enabled3DS);
        if (data.payload.hasOwnProperty('amount')) {
            WebAccounts.Settings.CardHolder.providerInstance.setVerificationAmount(data.payload.amount);
        }

        return WebAccounts.Settings.CardHolder.providerInstance;
    }
    WebAccounts.Settings.CardHolder.clearAllErrors = () => {
        deleteElements('[add-card-error]');
    }

    /**
     * Show all elements that notify user for some kind of client error
     * Errors are stored inside Errors object created while validating
     */
    WebAccounts.Settings.CardHolder.handleValidationErrors = () => {
        WebAccounts.Settings.CardHolder.cardCollector.errorBag.forEach(item => {
            let error = document.createElement('p');
            error.setAttribute('class', 'error-message js-val-error');
            error.setAttribute('add-card-error', 1);
            error.setAttribute('style', 'color: red; display:block;');
            error.innerText = item.error.trim();

            item.field.parentElement.parentElement.appendChild(error);
        });
    }


    /**
         * Get the variables from the form. Passes them to the validation method
         * depending on its response either sets a valid error message or sends 
         * an ajax request to handle the credit card submit
         * 
         * @param {Object} event - The submit event
         */
    WebAccounts.Settings.CardHolder.processBraintree = function (event) {
        event.preventDefault();
        event.stopPropagation();
        WebAccounts.Settings.CardHolder.loaderElement.addClass('visible');
        WebAccounts.Settings.CardHolder.loaderElement.slideDown();
        WebAccounts.Settings.CardHolder.cardCollector.extractCardInformation();

        if (WebAccounts.Settings.CardHolder.cardCollector.errorBag.length) {
            WebAccounts.Settings.CardHolder.clearAllErrors()
            WebAccounts.Settings.CardHolder.loaderElement.removeClass('visible');
            WebAccounts.Settings.CardHolder.handleValidationErrors();
            return;
        }

        WebAccounts.Settings.CardHolder.clearAllErrors()
        try {
            WebAccounts.Settings.CardHolder.execBraintree();
        } catch (e) {
            console.log(e);
        }
    };

    WebAccounts.Settings.CardHolder.execBraintree = async () => {
        const cardData = WebAccounts.Settings.CardHolder.cardCollector.generate();
        WebAccounts.Settings.CardHolder.providerInstance.setCardInformation(cardData);

        try {
            let braintreeResponse = await WebAccounts.Settings.CardHolder.providerInstance.tokenize();
            if (WebAccounts.Settings.CardHolder.providerInstance.is3DSActive) {
                braintreeResponse = await WebAccounts.Settings.CardHolder.providerInstance.signToken(braintreeResponse);
            }

            const payload = {
                '_token': csrfToken,
                'payload': {
                    "description": cardData.name,
                    "payment_method_id": WebAccounts.Settings.CardHolder.payment_method_id,
                    "setup": {
                        "type": WebAccounts.Settings.CardHolder.action_type,
                        "payload": {
                            "token": braintreeResponse.response.nonce,
                            "device_data": braintreeResponse.dataCollectorInstance.deviceData
                        }
                    },
                }
            }

            const cardMetaData = { lastFourSymbols: braintreeResponse.response.details.lastFour, cardDescription: cardData.name, brand: braintreeResponse.response.details.cardType };

            WebAccounts.Settings.CardHolder.makeRequest(payload, cardMetaData);
        } catch (exception) {
            // error
            const refreshedResponse = await WebAccounts.Settings.CardHolder.getUserPaymentSetup(WebAccounts.Settings.CardHolder.payment_method_id)

            WebAccounts.Settings.CardHolder.preparePaymentProvider(refreshedResponse);

            const modal = new WAModal()
            let msg = "";
            if (exception.hasOwnProperty('code')) {

                if (exception.code == 47500) {
                    msg = trans('cardCollection.threeDS')

                } else {
                    msg = `<p>${exception.message}</p>`
                }

            } else {
                msg = trans('cardCollection.cardDecline');
            }

            modal.setOptions({
                html: msg
            }).render();

            WebAccounts.Settings.CardHolder.loaderElement.removeClass('visible');
            WebAccounts.Settings.CardHolder.loaderElement.slideUp();


            WebAccounts.Settings.CardHolder.handleBraintreeExceptions(exception);

            throw new Error(exception.error);

        }
    }

    WebAccounts.Settings.CardHolder.preparePaymentProvider = async (response) => {
        const paymentProvider = getProviderByType(response.type);
        WebAccounts.Settings.CardHolder.action_type = response.type;
        WebAccounts.Settings.CardHolder.payment_provider_type = ucfirst(paymentProvider['name']);

        let a = await WebAccounts.Settings.CardHolder.loadDependencies(paymentProvider['name']);
        console.log(a);
        const providerType = ucfirst(paymentProvider['name']);

        const initMethod = WebAccounts.Settings.CardHolder.providerInstance == null ? `init${providerType}` : `configure${providerType}`;
        if (WebAccounts.Settings.CardHolder.hasOwnProperty(initMethod) && isCallable(WebAccounts.Settings.CardHolder[initMethod])) {
            WebAccounts.Settings.CardHolder.providerInstance = WebAccounts.Settings.CardHolder[initMethod](response);
        }
    }
    document.addEventListener('waConfigurationsLoaded', _ => WebAccounts.Settings.CardHolder.initialize())

})(window.WebAccounts = window.WebAccounts || {}, jQuery);

import { parseBoolean, isCallable } from "../common/helpers";

export class CheckoutProvider {
    constructor(pub_key, container, submit_btn) {
        this.pub_key = pub_key;
        this.is_active = true;
        this.container = container;
        this.submit_btn = submit_btn;
        this.styles = this.__setStyles();
        this.localisation = this.__setLocalisation();
    }

    /**
     * Initialize and registers callbacks for success and fail tokenisation
     * 
     * @param {Object} successCallback - Required
     * @param {Object|Null} failCallback
     */
    init(successCallback, failCallback, submitCallback) {
        Frames.init({
            publicKey: this.pub_key,
            containerSelector: this.container,
            localisation: this.localisation,
            style: this.styles,
            cardValidationChanged: () => {
                // if all fields contain valid information, the Pay now
                // button will be enabled and the form can be submitted
                if (!Frames.isCardValid()) {
                    this.submit_btn.classList.remove('filled')
                    this.submit_btn.classList.add('disabled')
                } else {
                    this.submit_btn.classList.add('filled')
                    this.submit_btn.classList.remove('disabled')
                }
            },
            cardTokenised: (event) => successCallback(event.data.cardToken),
            cardTokenisationFailed: (event) => isCallable(failCallback) ? failCallback(event) : null
        });

        this.submit_btn.addEventListener('click', this.__submit.bind(this, submitCallback));
    }

    /**
     * Unblocks all fields after sumbission
     * 
     * @link Docs at : https://docs.checkout.com/docs/customize-frames
     * @return {Object}
     */
    unblock() {
        Frames.unblockFields();
    }

    /**
     * 
     */
    activate() {
        this.__setIsActive(true);

        return this;
    }

    /**
     * 
     */
    deactivate() {
        this.__setIsActive(false);

        return this;
    }

    /**
     * @param {Boolean} bool 
     */
    __setIsActive(bool) {
        this.is_active = bool;
    }

    /**
     * Submit card details
     */
    __submit(submitCallback, event) {
        event.preventDefault();
        if (this.is_active && Frames.isCardValid()) {
            if (isCallable(submitCallback)) {
                submitCallback();
            }
            Frames.submitCard();
        }
    }

    /**
     * Sets the localisation used by Frame library
     * @link Docs at : https://docs.checkout.com/docs/customize-frames
     * @return {Object}
     */
    __setLocalisation() {
        return {
            cardNumberPlaceholder: "Card Number",
            expiryMonthPlaceholder: "Month",
            expiryYearPlaceholder: "Year",
        }
    }

    /**
     * Sets the styles used by Frame library 
     * @link Docs at : https://docs.checkout.com/docs/customize-frames
     * @return {Object}
     */
    __setStyles() {
        return {
            '.input-group label.icon': {
                top: "10%"
            },
            '.input-group .input-control': {
                fontSize: '14px'
            },
            '.embedded .card-form .input-group': {
                borderRadius: '5px'
            },
            '.embedded .card-form .input-group.focus:not(.error)': {
                border: '1px solid #00a7e3',
                color: "#00a7e3",
                opacity: 1
            },
            '.embedded .card-form .input-group': {
                border: "1px solid #cecece;",
                padding: "6px 35px 5px 15px",
                height: "50px"
            },
            '.embedded .card-form .input-group': {
                border: "1px solid #cecece;",
                'border-radius': '5px',
                padding: "6px 35px 5px 15px",
                height: "50px"
            },
            '.embedded .card-form .input-group.focus:not(.error)': {
                borderColor: "#00a7e3",
                color: "#00a7e3!important",
                opacity: 1
            },
            '.embedded .card-form .input-group.focus:not(.error) input': {
                color: "#00a7e3!important",
                fontSize: "14px"
            },
            '.embedded .card-form .input-group input::-webkit-input-placeholder': {
                color: "rgba(102, 102, 102, 0.8)"
            }
        }
    }
    setShouldSubmit(){};
}
import { parseBoolean } from "../common/helpers";

export class BraintreeProvider {

    constructor() {
        this.bin = null;
        this.apiKey = "";
        this._3DSAuth = null;
        this.amount = 0;
        this.nonce = null;
        this.is3DSActive = false;
        this.shouldSignNonce = false;
        this.dataCollectorOnly = false;
        this.cardInformation = {
            cvv: '',
            name: '',
            number: '',
            expirationDate: ''
        };
        this.event = null;
        this.__initEvent();
    }

    /**
     * 
     * @param {Boolean} isActive 
     */
    signNonceMode(isActive) {
        this.shouldSignNonce = isActive;
    }

    /**
     * 
     * @param {Boolean} isActive 
     */
    metaDataGeneration(isActive) {
        this.dataCollectorOnly = isActive;
    }

    /**
     * 
     * @param {String} token 
     */
    setNonce(token) {
        this.nonce = token;
    }

    /**
     * @return {Promise}
     */
    tokenize() {
        braintree.client.create({
            authorization: this.apiKey
        }, this.generateBraintreeToken.bind(this));

        return this.event;
    }

    signToken(data) {
        this.__initEvent()

        this.__signToken(
            {
                amount: this.amount,
                nonce: data.response.nonce,
                bin: data.response.details.bin
            },
            data.dataCollectorInstance
        )

        return this.event;
    }

    /**
     * 
     * @param {String} apiKey 
     */
    setApiKey(apiKey) {
        this.apiKey = apiKey || null;

        return this;
    }

    /**
     * 
     * @param {String} apiKey 
     */
    setBin(bin) {
        this.bin = bin || null;

        return this;
    }
    /**
     * 
     * @param {Boolean} active 
     */
    set3DS(active) {
        this.is3DSActive = parseBoolean(active);

        return this;
    }

    /**
     * 
     * @param {Number} amount 
     */
    setVerificationAmount(amount) {
        this.amount = amount || 0;

        return this;
    }

    /**
     * 
     * @param {Json} cardInformation 
     */
    setCardInformation(cardInformation) {
        this.cardInformation = cardInformation || null;

        return this;
    }

    /**
     * Private Methods
     */
    /**
     * 
     * @param {*} err 
     * @param {*} client 
     */
    async generateBraintreeToken(err, client) {
        if (this.is3DSActive && !this.dataCollectorOnly) {
            await this.__init3DS(client)
        }

        // Initialize braintree client data collector
        braintree.dataCollector.create({
            client: client,
            kount: true
        }).then((dataCollectorInstance) => {
            if (this.dataCollectorOnly) {
                this.resolvePromise({
                    response: null,
                    dataCollectorInstance
                });
                this.dataCollectorOnly = false;
                return true
            }

            if (this.shouldSignNonce) {
                let signTokenData = {
                    amount: this.amount,
                    nonce: this.nonce
                };

                if (this.bin !== null) {
                    signTokenData = Object.assign({}, signTokenData, { bin: this.bin })
                }

                this.__signToken(signTokenData, dataCollectorInstance)
                return true;
            }

            const BraintreeCardInformation = {
                cvv: this.cardInformation.cvv,
                number: this.cardInformation.number,
                cardholderName: this.cardInformation.name,
                expirationDate: this.cardInformation.expirationDate
            };
            // after the data collector is initialized
            // request tokenization of the card from Braintree
            client.request({
                endpoint: 'payment_methods/credit_cards',
                method: 'post',
                data: {
                    creditCard: BraintreeCardInformation
                }
            }, (err, response) => {
                // Callback function after the tokenization is finished
                if (err) {
                    this.rejectPromise({ error: err, response: response });
                    return false;
                }
                this.resolvePromise({
                    response: response.creditCards[0],
                    dataCollectorInstance
                });
            });
        });
    }

    /**
     * Sends token to the Braintree servers for 3d sign request
     * 
     * @param {Json} tokenInformation 
     * @param {Json|null} dataCollectorInstance 
     */
    __signToken(tokenInformation, dataCollectorInstance = null) {
        this._3DSAuth.verifyCard({
            ...tokenInformation,
            onLookupComplete: (data, next) => {
                next()
            }
        }).then((response) => {
            if (dataCollectorInstance == null) {
                dataCollectorInstance = { deviceData: null }
            }

            if (this.__is3DSSuccessful(response)) {
                this.resolvePromise({
                    response,
                    dataCollectorInstance
                });
                return true;
            }

            this.rejectPromise({ code: 47500, message: "3ds failed" });
        }).catch(err => {
            if (err) {
                this.rejectPromise({ code: 47500, message: "3ds failed" });
                return false;
            }
        });
    }

    /**
     * Initialize 3DS module from the Braintree SDK
     * 
     * @param {BraintreeClient} clientInstance 
     */
    __init3DS(clientInstance) {
        return new Promise((resolve, reject) => {
            braintree.threeDSecure.create({
                version: 2,
                client: clientInstance
            }, (threeDSecureErr, threeDSecureInstance) => {
                if (threeDSecureErr) {
                    reject(threeDSecureErr)
                    console.error(`Something went wrong  : ${threeDSecureErr}`);
                    return;
                }
                this._3DSAuth = threeDSecureInstance;
                resolve(true)
            });
        })
    }

    /**
     * Checks if the card has been successfully 3D Secured
     *  
     * @param {Json} cardObject 
     */
    __is3DSSuccessful(cardObject) {
        return ((cardObject.hasOwnProperty('verificationDetails') && cardObject.verificationDetails.liabilityShiftPossible && cardObject.verificationDetails.liabilityShifted) ||
            (cardObject.hasOwnProperty('liabilityShiftPossible') && cardObject.liabilityShiftPossible && cardObject.liabilityShifted))
    }

    __initEvent() {
        this.event = new Promise((resolve, reject) => {
            this.resolvePromise = resolve;
            this.rejectPromise = reject;
        });
    }
    setShouldSubmit() { }
}
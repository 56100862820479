import { isMobile, getElement, getElements } from "./helpers";

export class MobileAppBanner {

    constructor(tmpPath) {
        this.ctx = getElement('body');
        this.beforeLoadHandlers = [];
        this.created = false;
        this.templateUrl = `${window.location.origin}/${tmpPath}`;
        this.template = "";
        this.afterClose = null;
    }

    async handle() {
        if (!isMobile()) {
            return null;
        }

        this.beforeLoad();

        this.template = await this.loadTemplate();
        this.show();
    }

    addBeforeLoadHandlers(handler) {
        this.beforeLoadHandlers.push(handler);
    }

    beforeLoad() {

        this.beforeLoadHandlers.forEach(callable => {
            (this.isCallable(callable)) ? callable(this.ctx):null;
        });
        
        return this;
    }

    /**
     * Renders the banner on the page
     */
    show() {
        this.scrollTop();
        let node = document.createElement('div');
        this.ctx.classList.add("disable-scroll");
        node.innerHTML = this.template.trim();
        this.ctx.append(node.childNodes[0]);
        this.addEventListeners();
    }

    /**
     * Load template from the local server
     * 
     * @return Promise
     */
    loadTemplate() {
        return new Promise((resolve, reject) => {
            let http = new XMLHttpRequest();
            http.open("GET", this.templateUrl);

            http.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    resolve(this.responseText);
                }
            };
            http.onerror = function () {
                reject('Err');
            };

            http.send();
        });
    }

    /**
     * @return void
     */
    scrollTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    /**
    * @return void
    */
    addEventListeners() {
        let closeBtns = getElements("[data-wa-action='close']") || [];
        closeBtns.forEach(btn => {
            btn.addEventListener('click', this.closeBanner.bind(this));
        });
    }

    /**
     * Finds and closes the banner
     * 
     * @param {MouseEvent} event 
     */
    closeBanner(event) {
        event.preventDefault();

        let target = event.target.getAttribute('data-wa-target');
        let targetedNode = getElement(target);

        if (targetedNode) {
            targetedNode.classList.add('hidden');
            this.ctx.classList.remove("disable-scroll");
        }

        if (this.isCallable(this.afterClose)) {
            this.afterClose(this.ctx)
        }
    }

    isCallable(callable) {
        return callable !== null && callable !== 'undefined' && typeof callable === "function";
    }
}
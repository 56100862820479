(function (WebAccounts, $, undefined) {
    // Get the element that holds the password fields
    WebAccounts.Settings.PasswordHolder = {
        el: $('.account-settings-passwords-holder'),
        errorTemplate: (field, message, container) => {
            container.parents(".account-settings-sub-item-holder").find('.alert-container').empty().append(`<label class="error-message visible error" for="${field}">${message}</label>`)
        },
        exists: function () {
            return this.el.length;
        }
    };
    // check for existence and further implementation of the code
    // stop the code if the element does not exist
    if (!WebAccounts.Settings.PasswordHolder.exists()) {
        return;
    };
    // Get the fields that will be manipulated
    WebAccounts.Settings.PasswordHolder.initialize = function () {
        let $el = $(this.el);
        this.oldPassword = $el.find("#old_password");
        this.newPassword = $el.find("#password");
        this.confirmPassword = $el.find("#confirm_new_password");
        this.saveButton = $el.find("#saveButton");
        this.saveButton.on('click', WebAccounts.Settings.PasswordHolder.savePassword);
    };
    WebAccounts.Settings.PasswordHolder.savePassword = function (event) {
        event.preventDefault();
        WebAccounts.Settings.PasswordHolder.send()
    }
    // Clear the inputs
    WebAccounts.Settings.PasswordHolder.clearForm = function () {
        WebAccounts.Settings.PasswordHolder.oldPassword.val('');
        WebAccounts.Settings.PasswordHolder.newPassword.val('');
        WebAccounts.Settings.PasswordHolder.confirmPassword.val('');
    }
    // send to the WebAccounts route
    WebAccounts.Settings.PasswordHolder.send = function () {
        $('.alert-container').empty();
        let ajaxStream = $.ajax({
            method: "POST",
            url: "/api/account-settings/change-password",
            data: this.el.parents("form").serialize(),
            success: response => {
                if (response.hasOwnProperty('errors')) {
                    this.handleErrors(response.errors);
                } else {
                    let msg = "Your password has been successfully updated!";
                    try {
                        msg = response.successes['change_password']['success'].message;
                    } catch (error) {
                        console.warn(error);
                    }
                    $('.alert-container').empty();
                    WebAccounts.Settings.PasswordHolder.clearForm();
                    WebAccounts.Utilities.ShowModal({ title: msg, type: 'success' });
                }
            },
            error: error => {
                console.log(error);
            }
        });
    };

    WebAccounts.Settings.PasswordHolder.handleErrors = errors => {
        errors.change_password.forEach(error => {
            let fieldName, fieldContainer;
            switch (error.code) {
                case 5533:
                    fieldName = "old_password";
                    fieldContainer = WebAccounts.Settings.PasswordHolder.oldPassword;
                    break;
                case 5516:
                    fieldName = "password";
                    fieldContainer = WebAccounts.Settings.PasswordHolder.newPassword;
                    break;
                case 5532:
                    fieldName = "confirm_new_password";
                    fieldContainer = WebAccounts.Settings.PasswordHolder.confirmPassword;
                    break;
                default:
            }

            WebAccounts.Settings.PasswordHolder.errorTemplate(fieldName, error.message, fieldContainer)
        });

    };

    WebAccounts.Settings.PasswordHolder.initialize();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
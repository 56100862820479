import { getElement, makeElement ,scrollTo, trans} from "../common/helpers";

class AddNoteRequestPayload {
    constructor(bookingId, note) {
        this.booking_id = bookingId;
        this.note = note;
    }

    /**
     * @return {boolean}
     */
    validate() {
        return this.booking_id && this.note.length;
    }

    /**
     * @return {JSON}
     */
    buildPayload() {
        return {
            booking_id: this.booking_id,
            note: this.note
        }
    }
}
(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('AddNoteTask')) {
        WebAccounts.AddNoteTask = {
            elements: $('[data-trigger="add-note"]'),
            taskContainer: getElement(".wa-comment-container"),
            apiEndpoint: '/api/booking/add-note',

            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    if (!WebAccounts.AddNoteTask.hasOwnProperty('exist') ||
        !WebAccounts.AddNoteTask.exist()) {
        return;
    }

    /** Constructor */
    WebAccounts.AddNoteTask.construct = function () {
        WebAccounts.AddNoteTask.elements.on('click', WebAccounts.AddNoteTask.send);
    }

    /**
     * Send comment to the Booking resource
     * 
     * @param {Event} event
     */
    WebAccounts.AddNoteTask.send = function (event) {
        WebAccounts.loaderElement.addClass('visible');

        WebAccounts.AddNoteTask.buildPayload()
            .then(WebAccounts.AddNoteTask.makeRequest)
            .catch(WebAccounts.AddNoteTask.validationFail);
    }

    /**
     * @return {Promise}
     */
    WebAccounts.AddNoteTask.buildPayload = () => {
        return new Promise((resolve, reject) => {
            const submitButton = $(event.currentTarget),
                bookingInformationElement = submitButton.parents('.service-meta-information'), // This element contains meta information about the booking
                bookingId = bookingInformationElement.data('bookingId');

            let noteTextArea = submitButton.parents('form').find('textarea'),
                // Initialize Request
                addNotePayload = new AddNoteRequestPayload(bookingId, noteTextArea.val()),
                // Get the error container ready
                errorMsg = noteTextArea.parents('form').find('p.alert-notification');

            if (errorMsg) {
                errorMsg.remove();
            }

            // Validate request payload
            if (!addNotePayload.validate()) {
                reject(noteTextArea);
            }

            resolve([addNotePayload, noteTextArea]);
        })
    }
    /**
     * 
     * 
     * @param {Array<object>} params 
     */
    WebAccounts.AddNoteTask.makeRequest = (params) => {
        let payload = params[0], element = params[1];

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': csrfToken
            }
        });

        // make request to WebAccounts API;
        $.ajax({
            type: "POST",
            data: payload,
            url: WebAccounts.AddNoteTask.apiEndpoint,
            success: response => {
                if (response.status === 'error' || parseInt(response.code) === 4000) {
                    WebAccounts.loaderElement.removeClass('visible');

                    return WebAccounts.Utilities.ShowModal({
                        title: trans('general.error.generic')
                    });
                }

                element.val("");
                const container = WebAccounts.AddNoteTask.taskContainer;
                $('[data-target="#customerNoteContainer"]').click();
                if (container !== null) {
                    WebAccounts.removeLoader();
                    const [dateString, timeString] = WebAccounts.AddNoteTask.getCurrentDateFormatted(response.meta.region || "UK");
                    const rowNode = makeElement(
                                `<div class="row push-up">
                                    <div class="col-xs-12 ">
                                        <div class="service-details-note-container">
                                            <div class="service-details-note">
                                                <div class="timestamp">
                                                    <span>${dateString}</span>
                                                    <span>${timeString.toUpperCase()}</span>
                                                </div>
                                                <div class="message">${payload.note}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>`);

                    container.prepend(rowNode);
                    scrollTo("#note-section-title");
                    return null;
                }
                location.reload();
            }
        });
    }

    /**
     * Handle validation fail
     * 
     * @param {HtmlElement} parentElement 
     */
    WebAccounts.AddNoteTask.validationFail = (parentElement) => {
        WebAccounts.loaderElement.removeClass('visible')
        parentElement.parents('form').prepend(`<p class="alert-notification">${trans('general.errors.addComment')}</p>`)
    }

    WebAccounts.AddNoteTask.getCurrentDateFormatted = (locale = "UK") => {
        const date = new Date;
        const dateString = date.toLocaleString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: locale == "UK" ? "Europe/London" : "Australia/Sydney"
        });

        let [dateStringFormatted, timeString ] = dateString.split(",").map(x => x.trim());

        timeString = parseInt(timeString.split(":")[0])>9?timeString:`${timeString}`;

        return [dateStringFormatted, timeString];
    }

    WebAccounts.AddNoteTask.construct();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
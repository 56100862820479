import { isCallable, trans } from "./helpers";

export class CardCollector {
    constructor(containerSelector = "[card-collector]") {
        this.getCardContainer(containerSelector);

        this.cachedNodes = false;

        this.cardDefinition = {
            number: {
                selector: 'card-number',
                removeWhiteSpaces: true,
                regex: /^([0-9]{12,19})$/,
                required: true,
                validatorCallback: null,
                cachedNode: null,
                value: null
            },
            cvv: {
                selector: 'card-cvv',
                regex: null,
                required: true,
                validatorCallback: null,
                cachedNode: null,
                value: null
            },
            expiration: {
                selector: 'card-expiry',
                removeWhiteSpaces: true,
                regex: /^[0-9]{2}\/[0-9]{2,4}$/,
                required: true,
                validatorCallback: null,
                cachedNode: null,
                value: null
            },
            name: {
                selector: 'card-name',
                removeWhiteSpaces: false,
                regex: /^([^!{}()_,=@#$%^&*]){2,40}$/,
                required: false,
                validatorCallback: null,
                cachedNode: null,
                value: null
            }
        }
        this.errorMessages = {
            number: {
                required: trans('cardCollection.number')
            },
            cvv: {
                required: trans('cardCollection.cvv')
            },
            expiration: {
                required: trans('cardCollection.expiry')
            },
            name: {
                required: trans('cardCollection.name')
            }
        }
    }

    /**
     * @param {string} containerSelector
     * 
     * @return {void}
     */
    getCardContainer(containerSelector) {
        let container = document.querySelector(containerSelector);

        if (!(container instanceof HTMLElement)) {
            throw Error((`Cannot find element with selector ${containerSelector}`));
        }

        this.container = container;
    }

    /**
     * Set custom selectors for card information
     * properties that does not exist in {this.cardDefinition} will be ignored
     * 
     * @param {Object} selectorsObject
     * 
     * @return {self}
     */
    setCustomSelectors(selectorsObject) {
        for (let key in selectorsObject) {
            if (this.cardDefinition[key] !== undefined) {
                this.cardDefinition[key].selector = selectorsObject[key];
            }
        }

        return this;
    }

    setCustomFieldValidation = (validationObject) => {
        for (let key in validationObject) {
            if (this.cardDefinition[key] !== undefined) {
                this.cardDefinition[key].validatorCallback = validationObject[key];
            }
        }

        return this;
    }

    /**
     * @return {void}
     */
    extractCardInformation() {
        if (this.cachedNodes) {
            this.updateData();
        } else {
            this.extractFrom(this.container)
            this.cachedNodes = true;
        }

        this.normalizeData();
        this.validate();

        return this;
    }

    validate() {
        this.errorBag = [];
        for (let key in this.cardDefinition) {
            if ((this.cardDefinition[key].required && this.cardDefinition[key].value == "")
                || (this.cardDefinition[key].regex !== null && !this.cardDefinition[key].regex.test(this.cardDefinition[key].value))) {
                this.errorBag.push({
                    field: this.cardDefinition[key].cachedNode,
                    error: this.errorMessages[key].required || 'Field is required!'
                });
            }
            if (isCallable(this.cardDefinition[key].validatorCallback)) {
                this.cardDefinition[key].validatorCallback(this);
            }
        }
    }

    /**
     * 
     */
    extractFrom(parentElem) {
        // for (const node of parentElem.childNodes) {
        [...parentElem.childNodes].forEach(node => {
            if (node['hasAttribute'] === undefined) {
                return;
            }

            let found = false;

            for (let key in this.cardDefinition) {
                if (this.cardDefinition[key].value != null) {
                    continue;
                }
                if (node.hasAttribute(this.cardDefinition[key].selector)) {
                    this.cardDefinition[key].value = node.value;
                    this.cardDefinition[key].cachedNode = node;
                    found = true;
                    break;
                }
            }

            if (node.childNodes.length && !found) {
                this.extractFrom(node);
            }
        });
    }

    normalizeData() {
        for (let key in this.cardDefinition) {
            if (this.cardDefinition[key].removeWhiteSpaces) {
                this.cardDefinition[key].value = this.cardDefinition[key].value.split("").map(val => {
                    return val.trim();
                }).join('');
                continue;
            }

            this.cardDefinition[key].value = this.cardDefinition[key].value.trim();
        }
    }

    updateData() {
        for (let key in this.cardDefinition) {
            this.cardDefinition[key].value = this.cardDefinition[key].cachedNode.value;
        }
    }

    generate() {
        const expirationArray = this.cardDefinition.expiration.value.split('/');

        return {
            number: this.cardDefinition.number.value,
            name: this.cardDefinition.name.value,
            expirationDate: this.cardDefinition.expiration.value,
            expirationMonth: expirationArray[0],
            expirationYear: expirationArray[1],
            cvv: this.cardDefinition.cvv.value
        }
    }
}

(function (WebAccounts, $, undefined) {
    // Get the element that holds the password fields
    WebAccounts.Settings.PersonalInfo = {
        el: $('#personal-info'),
        pictureContainer: $('#image_avatar'),
        firstName: $('#firstname'),
        lastName: $('#lastname'),
        birthDate: $('#birth_date'),
        submitBtn: $('#nameAvatar'),
        formData: new FormData(),
        endpoint: "/api/account-settings/change-personal-info",
        exists: function () {
            return this.el.length;
        }
    };

    // check for existence and further implementation of the code
    // stop the code if the element does not exist
    if (!WebAccounts.Settings.PersonalInfo.exists()) {
        return;
    };

    WebAccounts.Settings.PersonalInfo.addPicture = function (event) {
        let picture = this.files[0];
        WebAccounts.Settings.PersonalInfo.formData.set('image_avatar', picture);
    }

    WebAccounts.Settings.PersonalInfo.addPersonalInfo = function (event) {
        let fieldName = $(this).attr('name'),
            fieldValue = $(this).val();
        WebAccounts.Settings.PersonalInfo.formData.set(fieldName, fieldValue);
    }

    WebAccounts.Settings.PersonalInfo.handleErrors = function (errors) {
            errors.user.forEach(value => {
                let errContainer;
                switch (value.code) {
                    case 5514:
                        errContainer = $(`#err-first-name`);
                        break;
                    case 5515:
                        errContainer = $(`#err-last-name`);
                        break;
                    case 5528:
                        errContainer = $(`#err-birth-date-formatted`);
                    break;

                    default:
                        break;
                }
                if (errContainer) {
                    errContainer.empty().append(`${value.message}`).show();
                }
            });
    }

    WebAccounts.Settings.PersonalInfo.makeRequest = function (event) {
        event.stopPropagation();
        event.preventDefault();

        WebAccounts.Settings.PersonalInfo.formData.set('_token', $(this).parents('form').find('[name="_token"]').val());
       
        let ajaxStream = $.ajax({
            method: "POST",
            url: WebAccounts.Settings.PersonalInfo.endpoint,
            data: WebAccounts.Settings.PersonalInfo.formData,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: response => {
                if (!response) {
                    return;
                }
                let messages = '';
                for (const element in response.successes){
                    try { 
                        messages = response.successes[element].success.message;
                    } catch (error) {}
                };
                
                if (response.hasOwnProperty('errors')) {
                    $(".error-message").hide();
                    WebAccounts.Settings.PersonalInfo.handleErrors(response.errors);
                } else {
                    $('.header-menu-username').empty().append(`<span class='bold'>${WebAccounts.Settings.PersonalInfo.firstName.val()}</span> <span class='bold'>${WebAccounts.Settings.PersonalInfo.lastName.val()}</span>`)
                    $(".error-message").hide();
                    WebAccounts.Utilities.ShowModal({
                        title: messages || "Your personal information has been successfully updated!",
                        type:'success'
                    });
                }
            },
            error: error => {
                console.log(error);
            }
        });
    };

    WebAccounts.Settings.PersonalInfo.ini = function () {
        this.pictureContainer.on('change', this.addPicture);
        this.firstName.on('change', this.addPersonalInfo);
        this.lastName.on('change', this.addPersonalInfo);
        this.submitBtn.on('click', this.makeRequest)
        if (this.birthDate.length > 0){
            this.birthDate.on('change', this.addPersonalInfo);
        }
    }

    WebAccounts.Settings.PersonalInfo.ini();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
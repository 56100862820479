(function(WebAccounts, $, undefined) {

    WebAccounts.Settings.CreditCard = {};

    /**
     * Validates the credit card pattern
     * 
     * @param {String} value - The credit card value
     * @return {Object} A validation object, with properties, that describe is the card valid or not. 
     */
    WebAccounts.Settings.CreditCard.validateCreditCardPattern = function(value) {
        var validation = {
                valueType: undefined,
                status: false,
                isValidLength: false
            };

        for (var i = 0; i < WebAccounts.regex.credit_cards.length; i++) {
            var creditCardPattern = WebAccounts.regex.credit_cards[i];

            if (creditCardPattern.pattern.test(value)) {
                validation.valueType = creditCardPattern.type;
                validation.status    = true;
                validation.isValidLength = creditCardPattern.valid_length.indexOf(value.length) !== -1;

                break;
            } else {
                validation.valueType = undefined,
                validation.status    = false;
            }
        }

        return validation;
    };

    /**
     * Saves the details for a credit card item. Uses
     * 
     */
    WebAccounts.Settings.CreditCard.saveDetails = function(event) {
        event.preventDefault();

		var element = $(this),
			currentForm = element.parents('#add-details-form'),
			mainForm = element.parents('form'),
			currentCardNumber = currentForm.find('#card-number'),
			currentCardCVV = currentForm.find('#card-cvv'),
			currentCardName = currentForm.find('#card-name'),
			currentCardExpiry = currentForm.find('#card-expiry'),
			currentCardNumberValue = WebAccounts.removeWhiteSpaces(currentCardNumber.val()),
			currentCardExpiryValue = WebAccounts.removeWhiteSpaces(currentCardExpiry.val()),
			currentCardNameValue = WebAccounts.removeWhiteSpaces(currentCardName.val()),
			currentCardCVVValue = WebAccounts.removeWhiteSpaces(currentCardCVV.val()),
			isCardValid = false,
			isCardExpiryValid = false,
			isCardNameValid = false,
			isCardCVVValid = false,
			isCardNumberValid = false;

        var validationResult = WebAccounts.Settings.CreditCard.validateCreditCardPattern(currentCardNumberValue);

        isCardNumberValid = !!validationResult.valueType && validationResult.status && validationResult.isValidLength;
        isCardCVVValid = currentCardCVVValue.length === 3 || currentCardCVVValue.length === 4;
        isCardNameValid = WebAccounts.regex['name'].test(currentCardNameValue);
        isCardExpiryValid = WebAccounts.regex['expiryDate'].test(currentCardExpiryValue);

        isCardValid = isCardNumberValid && isCardCVVValid && isCardNameValid && isCardExpiryValid;

        if (!isCardValid) {
            return;
        }

        var cardStorage = mainForm.find('#card-storage'),
            cardStorageLength = cardStorage.find('input[type="hidden"][name^="userPayment-"]').length,
            currentCardHTML = '<input type="hidden" name="userPayment-',
            currentCardValue = [ currentCardNameValue, currentCardNumberValue, currentCardCVVValue, currentCardExpiryValue].join('###');

        if (cardStorageLength > 0) {
            currentCardHTML+= (cardStorageLength + 1);
        } else {
            currentCardHTML+= '1';
        }

        currentCardHTML+= '" value="' + currentCardValue + '">';

        cardStorage.append(currentCardHTML);
    }

    $(document).on('click', '#save-details', WebAccounts.Settings.CreditCard.saveDetails);
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
(function(WebAccounts, $, undefined) {    
    if(!WebAccounts.forms.length) {
        return;
    }

    WebAccounts.textElement = WebAccounts.forms.find('select');
    WebAccounts.areTextsValid = true;

    if(!WebAccounts.textElement.length) {
        return;
    }


    // if (WebAccounts.textElement.length > 0) {
    //     // var WebAccounts.areTextsValid = true;
    //     for (var i = 0; i < textElement.length; i += 1) {

    //         var textElementType = $(textElement[i]).attr('name') ? $(textElement[i]).attr('name').toLowerCase() : "",
    //             textElementLabel = $(textElement[i]).parent().parent().find('label[for="' + textElementType + '"].error-message'),
    //             textElementSpan = $(textElement[i]).parent().find('span[data-for="' + textElementType + '"]');

    //         if (!WebAccounts.areTextsValid) {
    //             break;
    //         }

    //         if (($(textElement[i]).length > 0 && !!$(textElement[i]).val())) {
    //             if ($(textElement[i]).val().trim().length > 0) {

    //                 WebAccounts.areTextsValid = WebAccounts.areTextsValid && isFormElementValid($(textElement[i]));
    //                 textElement.parent().find('span[data-for="'+ textElementType +'"]').text('Please enter a valid ' + textElement.attr('validation-text'));
    //                 textElement.parent().find('label[for=' + textElementType + ']').text('Please enter a valid '+ textElement.attr('validation-text'));
    //                 // textElement.text('Please enter your '+ textElement.attr('validation-text'));
    //             } else {

    //                 WebAccounts.areTextsValid = (formID === 'set-up-account-form');
    //                 textElement.parent().find('span[data-for="'+ textElementType +'"]').text('Please enter a valid ' + textElement.attr('validation-text'));
    //                 textElementLabel.text('Please enter your '+ textElement.attr('validation-text'));
    //             }
    //         } else {
    //             WebAccounts.areTextsValid = (formID === 'set-up-account-form');
    //             textElement.parent().find('span[data-for="'+ textElementType +'"]').text('Please enter a valid ' + textElement.attr('validation-text'));
    //             textElementLabel.text('Please enter your '+ textElement.attr('validation-text'));
    //         }
    //     }

    //     isFormValid = isFormValid && WebAccounts.areTextsValid;
    // }
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
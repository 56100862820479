(function(WebAccounts, $, undefined) {
    WebAccounts.Settings = {};

    /**
     * Creates a set of variables.
     * TODO: all strings should be loaded from some trans file
     */
    WebAccounts.Settings.variables = {
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
    };

    /**
     * On the settings page, we can have multiple phones/adresses/
     * credit card/etc. On the mouseenter of the delete button it will
     * show the tooltip, if there is only one item of those types.
     * @see {@link WebAccounts.Settings.hideTooltip} The function which later hides the tooltip
     */
    WebAccounts.Settings.showTooltip = function(e) {
        var accountSettingsHolder = $(this).parents('.account-settings-item'),
			isLast = $(accountSettingsHolder).find('span[class*=remove]').length <= 1,
			tooltipHolder = $(this).siblings('.tooltip-holder');

		if (isLast) {
			tooltipHolder.stop().fadeIn(200, function() {
				tooltipHolder.addClass('visible');
			});
		}
    };

    /**
     * On the settings page, we can have multiple phones/adresses/
     * credit card/etc. On the mouseleave of the delete button it will
     * hide the tooltip, if there is only one item of those types.
     * 
     * @see {@link WebAccounts.Settings.showTooltip} The function which shows the tooltip
     */
    WebAccounts.Settings.hideTooltip = function(e) {
		var accountSettingsHolder = $(this).parents('.account-settings-item'),
			isLast = $(accountSettingsHolder).find('span[class*=remove]').length <= 1,
			tooltipHolder = $(this).siblings('.tooltip-holder');

		if (isLast) {
			tooltipHolder.stop().fadeOut(200, function() {
				tooltipHolder.removeClass('visible');
			});
		}
	};

    /**
     * Is there an Ajax request loading
     */
    WebAccounts.Settings.AjaxLoading = false;

    /**
     * Checks whether the passed string is empty;
     * 
     * @param {String} str The passed string
     * @return {Boolean} After the whitespaces are removed do we have any length. 
     */
    WebAccounts.isEmpty = function (str){
        return !str.replace(/^\s+/g, '').length;
    }

    /**
     * Set one of the multiple addresses/credit card/phones to your default
     * of the account. It will send a request to the /setDefault endpoint
     * with the id of the option and its type. When an ajax request is loading,
     * stops further ajax request.
     * 
     * @see {@link WebAccounts.Settings.AjaxLoading}
     */
    WebAccounts.Settings.setDefault = function(e) {
        e.preventDefault();
        if(WebAccounts.Settings.AjaxLoading){
            e.stopPropagation();
            e.stopImmediatePropagation();
            return;
        }

        WebAccounts.loaderElement.addClass('visible');
        WebAccounts.Settings.AjaxLoading = true;

        let defaultCheckBox = $(this),
            objectId = defaultCheckBox.val(),
            typeOfResource = defaultCheckBox.data('waType'),
            hiddenInputs = defaultCheckBox.parents('.row-item').find('input[type="hidden"]');

        let requestData = {
            "_token": csrfToken,
            "resource": typeOfResource,
            "payload": {}
        };

        for (let index = 0; index < hiddenInputs.length; index++) {
            requestData.payload[hiddenInputs[index].name] = hiddenInputs[index].value
        }
        requestData.payload['default'] = 1;        
        requestData.payload['id'] = objectId;        

        $.ajax({
            url: '/api/account-settings/default',
            type: 'POST',
            data: requestData,
            success: result => {
                defaultCheckBox.checked = true;

                WebAccounts.Settings.AjaxLoading = false;

                WebAccounts.loaderElement.removeClass('visible');
            },
            error: result => {
                WebAccounts.Settings.AjaxLoading = false;
                console.log('Could not change the default resource: ' + result);
                WebAccounts.loaderElement.removeClass('visible');
            }
        });
    };

    $(document).on('change', 'input[name^=primary-]', WebAccounts.Settings.setDefault);
    $('.account-settings-holder').on('mouseenter', 'span[class*=remove]', WebAccounts.Settings.showTooltip);
	$('.account-settings-holder').on('mouseleave', 'span[class*=remove]', WebAccounts.Settings.hideTooltip);

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
const { trans } = require("../common/helpers");

(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('EditSessionTask')) {
        WebAccounts.EditSessionTask = {
            elements: $('[data-trigger="edit-session"]'),
            reasonsNodes: undefined,
            fetchApiEndpoint: '/api/booking/edit_booking_reasons',
            postApiEndpoint: '/api/booking/temp/edit_requests',
            isV2: false,
            radioBtnText: undefined,
            requestPayload: {
                bookingId: undefined,
                reasonId: undefined,
                comment: undefined
            },
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    WebAccounts.EditSessionTask.construct = function () {
        WebAccounts.EditSessionTask.elements.on('click', WebAccounts.EditSessionTask.openModal);
    };

    /**
     * After reasons are loaded creates html nodes with reasons information
     * 
     * @param {JSON} response 
     */
    WebAccounts.EditSessionTask.buildReasonsNodes = function (response) {
        let startList = `<div class="modal-container">
            <ul class="unstyled-list text-align-left modal-reasons-list">`,
            endList = `</ul></div>`;

        response.forEach((item, key) => {
            startList += `
                 <li>
                    <label class="cancelReasonItem custom-radio wa-flex wa-items-centered" for="reason-${key}">
                    <input class="cancel-reason" id="reason-${key}" type="radio" name="edit_plan_reason" value="${item.name}" data-id="${item.id}"">
                    <span class="wa-radio"></span>
                            <span class="cancelReasonName">${item.name}</span>
                    </label>
                </li>`;
        });

        WebAccounts.EditSessionTask.reasonsNodes = startList + endList;
    }

    /**
     * @param {Event} event 
     */
    WebAccounts.EditSessionTask.openModal = function (event) {
        event.preventDefault();
        event.stopPropagation();
        let sessionInfo = $(event.currentTarget).parents('.service-meta-information');
        WebAccounts.EditSessionTask.requestPayload = {
            bookingId: sessionInfo.data('bookingId'),
            reasonId: undefined,
            comment: undefined
        };

        WebAccounts.EditSessionTask.getCustomReasons().then(() => {
            WebAccounts.Utilities.ShowModal({
                customClass: 'wa-pop-up wa-edit-session-modal',
                title: `<p class="push-up">${trans('editSessionTask.title')}</p>`,

                html: WebAccounts.EditSessionTask.reasonsNodes,
                preConfirm: () => {
                    return new Promise((resolve, reject) => {
                        const textArea = $('.client-reason-text-area textarea');
                        if (textArea.length && textArea.val().length > 3) {
                            WebAccounts.EditSessionTask.requestPayload.comment = textArea.val().trim();
                            resolve("valid")
                        } else {
                            dataLayer.push({
                                'event': "failedSubmit"
                            });
                            reject(trans('editSessionTask.errors.addComment'));
                        }
                    });
                },
                confirmButtonText: trans('editSessionTask.confirmBtn'),
            }).then(() => {

                WebAccounts.EditSessionTask.send();
            });

            swal.disableConfirmButton();

            const listItems = $(".modal-container ul li");

            listItems.on('click', 'label>input', WebAccounts.EditSessionTask.modifyCheckBoxes)

        })


    }
    /**
     * Modify the default behavior of input[type=checkbox]
     */
    WebAccounts.EditSessionTask.modifyCheckBoxes = function (e) {
        swal.enableConfirmButton();
        const modalContainer = $('.modal-container'),
            currentCheckbox = $(e.currentTarget),
            errorText = $(".swal2-validationerror"),
            oldTextArea = modalContainer.find('.client-reason-text-area');

        WebAccounts.EditSessionTask.requestPayload.reasonId = currentCheckbox.data('id');
        WebAccounts.EditSessionTask.radioBtnText = currentCheckbox.val();

        (errorText.length) ? errorText.slideUp() : null;

        if (oldTextArea.length) {
            oldTextArea.slideUp(() => {
                modalContainer.find('.client-reason-text-area').remove();
                WebAccounts.EditSessionTask.addTextArea(modalContainer, currentCheckbox);
            });
        } else {
            WebAccounts.EditSessionTask.addTextArea(modalContainer, currentCheckbox);
        }
    };

    /**
     * @param {HTMLElement} container 
     * @param {HTMLElement} element
     */
    WebAccounts.EditSessionTask.addTextArea = function (container, element) {
        element.parents('li').append(`
        <div class="client-reason-text-area" style='display:none;'>
            <textarea name="clientReason" placeholder="${trans('editSessionTask.placeholder')}"></textarea>
        </div>`);
        container.find('.client-reason-text-area').slideDown();
    }

    /**
     * Makes request to the WA get all reasons
     *  
     * @return {Promise}
     */
    WebAccounts.EditSessionTask.getCustomReasons = function () {

        return new Promise((resolve, reject) => {
            // if there are already loaded stop execution
            if (WebAccounts.EditSessionTask.areCachedReasons()) {
                return resolve();
            }

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': csrfToken
                }
            });

            // make request to WebAccounts API;
            $.ajax({
                type: "POST",
                url: WebAccounts.EditSessionTask.fetchApiEndpoint,
                success: response => {
                    WebAccounts.EditSessionTask.handleResponse(response);
                    resolve();
                }
            });
        });
    }

    /**
     * Determines if the reasons are already loaded 
     */
    WebAccounts.EditSessionTask.areCachedReasons = () => {
        return WebAccounts.EditSessionTask.reasonsNodes !== undefined;
    }
    /**
     * Handles errors if there are any
     * triggers buildReasons with the data from the response
     * 
     * @param {JSON} response 
     */
    WebAccounts.EditSessionTask.handleResponse = function (response) {
        if (response.hasOwnProperty('errors')) {
            response.errors.forEach(element => {
                console.log(new Error(element.message));
            });
            return;
        }

        WebAccounts.EditSessionTask.buildReasonsNodes(response.edit_booking_reasons);
    }

    /**
     * Make request to the Backend with the client reason
     * Renders appropriate modal 
     */
    WebAccounts.EditSessionTask.send = function () {
        dataLayer.push({
            'event': 'successfulSubmit',
            'radioChoice': WebAccounts.EditSessionTask.radioBtnText,
            'additionalNotes': WebAccounts.EditSessionTask.requestPayload.comment
        });
        $.ajax({
            url: WebAccounts.EditSessionTask.postApiEndpoint,
            type: 'POST',
            data: WebAccounts.EditSessionTask.requestPayload,
            success: (response) => {

                if (!response.hasOwnProperty('errors')) {
                    let modalOptions = {
                        title: `<span class="">${trans('editSessionTask.success.title')}</span>`,
                        text: trans('editSessionTask.success.text'),
                        confirmButtonText: trans('editSessionTask.success.confirmBtn'),
                    }
                    if (WebAccounts.EditSessionTask.isV2) {
                        modalOptions = {
                            html: response['successes']['edit_requests']['success']['message'],
                            confirmButtonText: trans('editSessionTask.success.confirmBtn'),
                        };
                    }

                    return WebAccounts.Utilities.ShowModal(modalOptions);
                }

                return WebAccounts.Utilities.ShowModal({
                    title: `<span class="">${trans('general.errors.generic')}</span>`,
                    confirmButtonText: trans('general.errors.genericConfirmBtn'),
                });
            }
        });
    }

    WebAccounts.EditSessionTask.construct();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

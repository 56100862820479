(function(WebAccounts, $, undefined) {  
    
    //Define the object that will have methods and attributes
    //handling function coresponding to the service card items
    WebAccounts.serviceCardElements = {
        elements: $('.service-card-item'),     
        exist: function() {
            return this.elements.length > 0;
        }
    };

    if(!WebAccounts.serviceCardElements.exist()) {
        return;
    }

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
import { getElement } from "./helpers";

export class ScriptLoader {
    constructor(script_list = []) {
        this.scriptDependencies = script_list;
        this.scriptPromises = [];
        this.loadedScriptsHashes = [];
    }

    /**
     * Adds collection of scripts paths for loading
     * 
     * @param {Array|String} scriptsPaths
     * @return {ScriptLoader} self
     */
    add(scriptsPaths) {
        this.scriptDependencies = scriptsPaths.isArray() ?[...this.scriptDependencies, ...scriptsPaths] : [...this.scriptDependencies, scriptsPaths];
            
        return this;
    }

    /**
     * Initialize load state
     * 
     * @type public
     */
    load() {
        return this.__loadDependencies();
    }
    /**
     * Load needed scripts
     * 
     * @type private
     * @return self
     */
    __loadDependencies() {
        return new Promise((resolve, reject) => {
            let script;

            this.scriptDependencies.forEach(depLink => {
                const b64Link = btoa(depLink);;
                
                if(getElement(`[data-hash="${b64Link}"]`)){
                    return;
                }
                script = document.createElement('script');
                script.dataset.hash = b64Link;
                script.src = depLink;
                document.head.appendChild(script);
                this.__resolveScript(script);
            });

            Promise.all(this.scriptPromises).then((statuses) => {
                resolve(true);
            }).catch(statuses => {
                reject('Script could not be loaded.');
            });
        })
    }

    /**
     * Adds promise that will be resolved or rejected after given script is loaded
     * all scripts have to be successuffully loaded to continue with execution
     * @type private
     * @param {DOMElement} script 
     */
    __resolveScript(script) {
        this.scriptPromises.push(new Promise((resolve, reject) => {
            script.onload = () => resolve(true);
            script.onerror = () => reject(false);
        }));
    }
}
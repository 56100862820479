(function(WebAccounts, $, undefined) {
    /**
     * All the forms, that are not with the sign up class
     */
    WebAccounts.forms = $('form:not(.sign-up)');

    /**
     * The "other" type of fields. 
     */
    WebAccounts.otherFieldTypes = ['name', 'email','address','city','phone','password','postcode'];


    /**
     * Check if the string contains the passed string
     * @param {String} haystack The string to be searched
     * @param {String} needle The string to be looked for
     */
    WebAccounts.stringHas = function(haystack,needle) {
        return haystack.indexOf(needle) !== -1;
    };

    /**
     * Passed a jQuery, then gets its name attribute.
     * If the name attribute doesn't contain checkbox,
     * select or radio in its name, we have no correct
     * regex for it. Otherwise checks first for a direct,
     * regex and after that 
     * 
     * @param {Object} element 
     * @return {String|Bool}  Either a regex to match with, or false.   
     */
    WebAccounts.getCorrectRegex = function(element) {
        var elementName = element.attr('name') ? element.attr('name').toLowerCase() : "",
            isCheckbox = WebAccounts.stringHas(elementName,'checkbox'),
            isSelect = WebAccounts.stringHas(elementName,'select'),
            isRadio = WebAccounts.stringHas(elementName,'radio'),
            elementRegex = false;

        if (!isCheckbox &&
            !isSelect &&
            !isRadio) {

            if(elementName in WebAccounts.regex) {
                return WebAccounts.regex[elementName];
            }

            for(var i = 0; i < WebAccounts.otherFieldTypes.length; i++) {
                if(WebAccounts.stringHas(elementName,
                    WebAccounts.otherFieldTypes[i])) {
                    return WebAccounts.regex[WebAccounts.otherFieldTypes[i]];
                }
            }
        }

        return elementRegex;
    }

    /**
     * When passed two password elements, matches them and creates a response object. 
     * @see {@link WebAccounts.isFormElementValid} Checks is the element valid
     * @param {Object} elementOne - The first element
     * @param {Object} elementTwo - The second element
     * @return {Object} A response object, with a valid property, which is either true|false or message
     */
    WebAccounts.validatePassword = function ( elementOne, elementTwo ) {
        var response = {
                valid: true,
                message: null
            },
            messages = {
                minLength: 'The passwords should be at least 5 characters!',
                noMatch:'The passwords do not match!',
                minRequirments: 'The passwords should be at least 5 characters and should match!'
            };

        if(elementOne.val() !== elementTwo.val()) {
            if(!WebAccounts.isFormElementValid(elementOne) || !WebAccounts.isFormElementValid(elementTwo)) {
                response.message = messages.minRequirments;
            } else {
                response.message = messages.noMatch;
            }
            
            response.valid = false;
        } else if(!WebAccounts.isFormElementValid(elementOne) || !WebAccounts.isFormElementValid(elementTwo)) {
            response.message = messages.length;
            response.valid = false;
        }

        return response;			
    }

    //M.N. TODO
    //No checking for required element on live
    /**
     * Checks if the passed element is valid. 
     * @param {Object} element The jQuery element to be validated.
     * @return {Bool} True if valid, false if not.
     * @see {@link WebAccounts.getCorrectRegex} Gets the correct regex based on the name attribute
     */
    WebAccounts.isFormElementValid = function(element) {
        var isCurrentElementValid = false,
            elementName = element.attr('name') ? element.attr('name').toLowerCase() : "",
            elementRegex = WebAccounts.regex[elementName],
            isCity = WebAccounts.stringHas(elementName,'city'),
            isAddress = WebAccounts.stringHas(elementName,'address'),
            isPostcode = WebAccounts.stringHas(elementName,'postcode'),
            isCheckbox = WebAccounts.stringHas(elementName,'checkbox'),
            isSelect = WebAccounts.stringHas(elementName,'select'),
            isRadio = WebAccounts.stringHas(elementName,'radio');



        // TODO: remove this line after Dimitar pushes the changes.
        if (WebAccounts.stringHas(elementName,'title')) {
            isSelect = true;
        }

        if (isCheckbox || isRadio) {
            isCurrentElementValid = element.is(':checked');
        } else if (isSelect) {
            isCurrentElementValid = !!element.val() && (element.val() !== element.find('option:eq(0)').val());
        } else if (isPostcode || isCity || isAddress) {
            elementRegex = WebAccounts.getCorrectRegex(element);
            for (var i = 0; i < elementRegex.length; i += 1) {
                isCurrentElementValid = elementRegex[i].pattern && elementRegex[i].pattern.test(element.val().trim());
                if (isCurrentElementValid) {
                    break;
                }
            }

        } else {
            elementRegex = WebAccounts.getCorrectRegex(element);
            isCurrentElementValid = elementRegex && elementRegex.test(element.val().trim());
        }

        return element.length > 0 && isCurrentElementValid;
    }

    /**
     * Triggers the validation class for the specific element. Calls the 
     * triggerFormElementClass method, which in turn adds the error/success
     * class. 
     * @param {Object} form The form, if the form is ste set-up-account-form will do additional checks
     * @param {Object} element The element for which the class will be triggered
     * @param {Boolean} isElementValid Is the element valid
     */
    WebAccounts.triggerFormElementValidClass = function(form, element, isElementValid) {
        var elementType = element.attr('name') ? element.attr('name').toLowerCase() : "",
            elementLabel = element.parent().parent().find('label[for=' + elementType + ']'),
            elementSpan = element.parent().find('span[data-for="'+ elementType +'"]');

        if(form.attr('id') === 'set-up-account-form') {
            if (element && element.val() && element.val().trim().length > 0) {
                WebAccounts.triggerFormElementClass(form, isElementValid, element, elementLabel);
            }
        } else {
            WebAccounts.triggerFormElementClass(form, isElementValid, element, elementLabel);
        }
    };

    /**
     * Adds an error/valid class to the element. And adds/removes visible class
     * to the elementLabel and elementErrorSpan
     * 
     * @param {Object} currentForm The form where the element is
     * @param {Boolean} isElementValid Is the element valid
     * @param {Object} element The element that would be modified
     * @param {Object} elementLabel The label which would also be modified
     */
    WebAccounts.triggerFormElementClass = function(currentForm, isElementValid, element, elementLabel) {
        if(element.length < 1) {
            return;
        }

        var elementType = element.attr('name') ? element.attr('name').toLowerCase() : "";
        var elementErrorSpan = element.parents().find('span[data-for="' + elementType + '"]');

        if ( (currentForm.attr('id') === 'set-up-account-form' && element.val().trim() !== '')
            || currentForm.attr('id') !== 'set-up-account-form') {
            if (!isElementValid) {
                element.removeClass('valid').addClass('error');
                elementLabel.addClass('visible').addClass('error');
                elementErrorSpan.addClass('visible').addClass('error');
            } else {
                element.removeClass('error').addClass('valid');
                elementLabel.removeClass('visible').removeClass('error');
                elementErrorSpan.removeClass('visible').removeClass('error');
            }
        } else if (currentForm.attr('id') === 'set-up-account-form' && element.val().trim() === '') {
            element.removeClass('error').addClass('valid');
            elementLabel.removeClass('visible').addClass('error');
            elementErrorSpan.removeClass('visible').addClass('error');
        }
    };

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
import { trans } from '../common/helpers';

(function (WebAccounts, $, undefined) {
    if (!WebAccounts.forms.length) {
        return;
    }


    /**
     * Validates all the 'name' elements within the passed form.
     * The name elements are elements which contain "-name" or "Name"
     * within their name attribute. The valid name must have these:
     * - must exist
     * - has a value
     * - value isn't empty
     * 
     * @param {Object} currentForm - The form
     * @return {Boolean} Are those elements valid
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     */
    WebAccounts.validateNameElements = function (currentForm) {
        var textElement = currentForm.find('[name*=-name]:not(.novalidate)').add('[name*=Name]:not(.novalidate)'),
            areTextsValid = true,
            formID = currentForm.attr('id');

        if (!textElement.length) {
            return areTextsValid;
        }

        for (var i = 0; i < textElement.length; i += 1) {
            var specificElement = $(textElement[i]),
                textElementType = specificElement.attr('name') ? specificElement.attr('name').toLowerCase() : "",
                textElementLabel = specificElement.parent().parent().find('label[for="' + textElementType + '"].error-message'),
                textElementSpan = specificElement.parent().find('span[data-for="' + textElementType + '"]'),
                errorMessage = trans('general.errors.validEntry', { fieldName: specificElement.attr('validation-text') });

            if ((specificElement.length && //Element Exists 
                !!specificElement.val()) && //Element has got value
                specificElement.val().trim().length) { //The value isn't an empty string

                areTextsValid = WebAccounts.isFormElementValid(specificElement);
                textElementSpan.parent().find('span[data-for="' + textElementType + '"]').text(errorMessage);
                textElementLabel.parent().find('label[for=' + textElementType + ']').text(errorMessage);
            } else {

                areTextsValid = (formID === 'set-up-account-form');
                textElementSpan.parent().find('span[data-for="' + textElementType + '"]').text(errorMessage);
                textElementLabel.text('Please enter your ' + textElement.attr('validation-text'));
            }

            if (!areTextsValid) {
                break;
            }
        }

        return areTextsValid;
    };

    /**
     * Validates the address elements within the passed form
     * We check if the element exists, if it has a value and
     * the value is not an empty string. After that it will be
     * validated by the WebAccounts.isFormElementValid method.
     * 
     * @param {Object} currentForm The form in which elements need to be validated 
     * @return {Boolean} Are the address elements valid
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     */
    WebAccounts.validateAddressElement = function (currentForm) {
        var addressElements = currentForm.find('[name=address]:not(.novalidate)'),
            areAddressesValid = true,
            formID = currentForm.attr('id');

        if (!addressElements.length) {
            return areAddressesValid;
        }

        for (var i = 0; i < addressElements.length; i += 1) {
            if (($(addressElements[i]).length > 0 &&
                !!$(addressElements[i]).val()) &&
                $(addressElements[i]).val().trim().length > 0) {
                areAddressesValid = WebAccounts.isFormElementValid($(addressElements[i]));
            } else {
                areAddressesValid = (formID === 'set-up-account-form');
            }
        }

        return areAddressesValid;
    };

    //Probably DEPRECATED, since it triggers nowhere
    /**
     * Validates the password elements
     * @param {Object} currentForm The form in which elements need to be validated
     * @return {Boolean} Are the password fields valid.
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     */
    WebAccounts.validatePasswordElements = function (currentForm) {
        var passwordElement = currentForm.find('#password').length > 0 ? currentForm.find('#password:not(.novalidate)') : currentForm.find('[name*="password"]:not(.novalidate)'),
            isPasswordsValid = true,
            formID = currentForm.attr('id');

        if (passwordElement.length < 1) {
            return isPasswordsValid;
        }

        for (var i = 1; i < passwordElement.length; i += 1) {
            var specificElement = $(passwordElement[i]),
                passwordElementType = specificElement.attr('name') ? specificElement.attr('name').toLowerCase() : "",
                passwordElementLabel = specificElement.parent().parent().find('label[for=' + passwordElementType + ']'),
                passwordElementSpan = specificElement.parent().find('span[data-for="' + passwordElementType + '"]'),
                isPasswordsValid = true;

            var result = WebAccounts.validatePassword(specificElement, $(passwordElement[0]));

            if (!result.valid) {
                isPasswordsValid = result.valid;
                passwordElementLabel.text(result.message);
                passwordElementSpan.text(result.message);
            }

            WebAccounts.triggerFormElementClass(currentForm, result.valid, specificElement, passwordElementLabel);
        }

        return isPasswordsValid;
    };

    //DEPRECATED
    /**
     * Are the checkbox elements within the passed form, valid?
     *  
     * @param {Object} currentForm The form to be validated
     * @return {Boolean} Are the elements valid
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     */
    WebAccounts.areCheckboxesValid = function (currentForm) {
        var checkboxElement = currentForm.find('[name*=checkbox]:not(.novalidate)'),
            isCheckboxesValid = true;

        if (checkboxElement.length < 1) {
            return isCheckboxesValid;
        }

        isCheckboxesValid = checkboxElement.is(':checked');
        for (var i = 0; i < checkboxElement.length; i += 1) {
            WebAccounts.triggerFormElementClass(currentForm, isCheckboxesValid, $(checkboxElement[i]), $(checkboxElement[i]).parent().find('label'));
        }

        isFormValid = isFormValid && isCheckboxesValid;
    };

    //DEPRECATED
    /**
     * Are the radio elements within the passed form, valid?
     * 
     * @param {Object} currentForm The form to be validated
     * @return {Boolean} Are the elements valid
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     */
    WebAccounts.areRadioElementsValid = function (currentForm) {
        var radioElement = currentForm.find('[name*=radio]:not(.novalidate)'),
            isRadioValid = true;

        if (radioElement.length < 1) {
            return isRadioValid;
        }

        isRadioValid = radioElement.is(':checked');

        for (var i = 0; i < radioElement.length; i += 1) {
            WebAccounts.triggerFormElementClass(currentForm, isRadioValid, $(radioElement[i]), $(radioElement[i]).parent().find('label'));
        }

        return isRadioValid;
    }

    /**
     * Validates the recaptcha.
     * 
     * @param {Object} currentForm The form to be validated
     * @return {Boolean} Are the elements valid
     * @see {@link WebAccounts.triggerFormElementClass|This method changes the recaptcha label }
     */
    WebAccounts.recaptchaValidation = function (currentForm) {
        var grecaptchaElement = currentForm.find('.g-recaptcha:not(.novalidate)'),
            grecaptchaResponse = !(grecaptchaElement.length > 0 && grecaptcha);

        if (grecaptchaElement.length > 0 && grecaptcha) {
            grecaptchaResponse = !!grecaptcha.getResponse(createAccountID);
            WebAccounts.triggerFormElementClass(currentForm, grecaptchaResponse, grecaptchaElement, grecaptchaElement.parent().find('label'));
        }

        return grecaptchaResponse;
    }

    /**
     * DEPRECATED
     * Validates the "select" inputs
     * 
     * @param {Object} currentForm The form to be validated
     * @return {Boolean} Are the elements valid
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     * @see {@link WebAccounts.triggerFormElementClass|This method changes the recaptcha label}
     */
    WebAccounts.selectElementsValidation = function (currentForm) {
        var selectElement = currentForm.find('[name*=select]:not(.novalidate)'),
            isSelectValid = true;

        if (selectElement.length < 1) {
            return isSelectValid;
        }

        for (var i = 0; i < selectElement.length; i += 1) {
            isSelectValid = isSelectValid && WebAccounts.isFormElementValid($(selectElement[i]));
            WebAccounts.triggerFormElementClass(currentForm, WebAccounts.isFormElementValid($(selectElement[i])), $(selectElement[i]), $(selectElement[i]).parent().find('label'));
        }

        return isSelectValid;
    };

    /**
     * DEPRECATED
     * Validates the "phone" inputs
     * 
     * @param {Object} currentForm The form to be validated
     * @return {Boolean} Are the elements valid
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     */
    WebAccounts.phoneElementsValidations = function (currentForm) {
        var phoneElement = currentForm.find('[name*=phone]:not(.novalidate)'),
            isPhoneValid = true;

        if (!phoneElement.length) {
            return isPhoneValid;
        }

        for (var i = 0; i < phoneElement.length; i += 1) {
            if (!isPhoneValid) {
                break;
            }

            if ($(phoneElement[i]).val().trim().length > 0) {
                isPhoneValid = isPhoneValid && WebAccounts.isFormElementValid($(phoneElement[i]));
            }
        }

        return isPhoneValid;
    };

    /**
     * Validates all the elements within a form.
     * After the validation adds the live-validation class to the form.
     * 
     * @see {@link WebAccounts.isFormElementValid|A function which validates a field}
     * @see {@link WebAccounts.triggerFormElementClass|This method changes the recaptcha label}
     * @param {Object} form The form to be validated
     * @return {Void} 
     */
    WebAccounts.validateAllFields = function (form) {
        var elements = form.find('.form-element:not(.novalidate)');
        if (elements.length > 0) {
            for (var i = 0; i < elements.length; i += 1) {
                var currentElement = $(elements[i]),
                    elementType = currentElement.attr('name') ? currentElement.attr('name').toLowerCase() : "",
                    isCheckbox = elementType.indexOf('checkbox') !== -1,
                    isRadio = elementType.indexOf('radio') !== -1,
                    isCurrentElementValid = false;

                if (isCheckbox || isRadio) {
                    isCurrentElementValid = currentElement.is(':checked');
                } else {
                    isCurrentElementValid = WebAccounts.isFormElementValid($(elements[i]));
                }

                WebAccounts.triggerFormElementValidClass(form, currentElement, isCurrentElementValid);
            }
        }

        if (!form.hasClass('live-validation') && form.attr('id') !== 'set-up-account-form') {
            form.addClass('live-validation');
        }
    };

    //Mostly deprecated
    WebAccounts.submitForm = function (event, currentForm) {
        var formID = currentForm.attr('id'),
            successScreen = $('.success-screen'),
            createAccountEmailHolder = $('#create-account-email-holder'),
            createAccountHolder = $('.create-account-holder'),
            createAccountStep = createAccountHolder.find('.create-account-step'),
            goBackButton = $('.go-back-btn');

        if (formID != 'save-changes' &&
            formID != 'personal-info' &&
            formID !== 'card-form' &&
            formID !== 'forgotten-password-form' &&
            formID !== 'claim-account-form' &&
            formID !== 'setup-account-form' &&
            formID !== 'set-up-account-form' &&
            formID !== 'login-form' &&
            formID !== 'create-account-form' &&
            formID !== 'addresses-form' &&
            formID !== 'reset-password-form') {

            event.preventDefault();
            WebAccounts.loaderElement.removeClass('visible');
        }

        if (formID === 'new-password-form' && successScreen.length > 0) {
            $('.set-up-new-password-form').addClass('not-visible');
            successScreen.addClass('visible');
        }

        if (formID === 'forgotten-password-form' && successScreen.length > 0) {
            $('.forgotten-password-form').addClass('not-visible');
            successScreen.addClass('visible');
        }
    };

    /**
     * The main form validation for the forms
     * 
     * @param {Object} event The submit event.
     * @see {@link WebAccounts.validateAllFields| Validates all the fields}
     * @see {@link WebAccounts.validateNameElements| Validates all the "name" elements}
     * @see {@link WebAccounts.validateAddressElement| Validates all the "address" elements}
     * @see {@link WebAccounts.validatePasswordElements| Validates all the "password" elements}
     * @see {@link WebAccounts.areCheckboxesValid| Validates all the "checkboxes" elements}
     * @see {@link WebAccounts.areRadioElementsValid| Validates all the "radio" elements}
     * @see {@link WebAccounts.recaptchaValidation| Validates all the "recaptcha" elements}
     * @see {@link WebAccounts.selectElementsValidation| Validates all the "select" elements}
     * @see {@link WebAccounts.phoneElementsValidations| Validates all the "phone" elements}
     * @see {@link WebAccounts.submitForm| DEPRECATED}
     */
    WebAccounts.validateForm = function (event) {
        if (event.target.hasAttribute('passthrough')) {
            return;
        }

        // if (window.innerWidth > 767) {
        WebAccounts.loaderElement.addClass('visible');
        // }

        var currentForm = $(this),
            emailElement = currentForm.find('[name="email"]:not(.novalidate)'),
            passwordElement = currentForm.find('#password').length > 0 ? currentForm.find('#password:not(.novalidate)') : currentForm.find('[name*="password"]:not(.novalidate)'),
            isFormValid = false;

        isFormValid = (emailElement.length > 0 ? WebAccounts.isFormElementValid(emailElement) : true) &&
            (passwordElement.length > 0 ? WebAccounts.isFormElementValid(passwordElement) : true);

        WebAccounts.validateAllFields(currentForm);

        //MOST OF THEM ARE DEPRECATED
        isFormValid = WebAccounts.validateNameElements(currentForm) && isFormValid;
        isFormValid = WebAccounts.validateAddressElement(currentForm) && isFormValid;
        isFormValid = WebAccounts.validatePasswordElements(currentForm) && isFormValid;
        isFormValid = WebAccounts.areCheckboxesValid(currentForm) && isFormValid;
        isFormValid = WebAccounts.areRadioElementsValid(currentForm) && isFormValid;
        isFormValid = WebAccounts.recaptchaValidation(currentForm) && isFormValid;
        isFormValid = WebAccounts.selectElementsValidation(currentForm) && isFormValid;
        isFormValid = WebAccounts.phoneElementsValidations(currentForm) && isFormValid;

        if (!isFormValid) {
            event.preventDefault();
            WebAccounts.loaderElement.removeClass('visible');
            return;
        }

        //Does nothing
        WebAccounts.submitForm(event, currentForm)
    };

    WebAccounts.forms.on('submit', WebAccounts.validateForm);
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
import { findAllIn, findIn, getElement } from "../common/helpers";
import { ScriptLoader } from "../common/script-loader";
import { WALoader } from "../common/wa-loader";
import { WAModal } from "../common/wa-modal";
import { StripeProvider } from "../payments/stripe-provider";

export class TipTask {
    constructor() {
        this.__modal = new WAModal();
        this.__loader = new WALoader()
        this.paymentMethod = {};
        this.shouldResetCheckboxes = true;
        this.formState = 'old-card' /// States : old-card / new-card
        this.paymentProviderInstance = null;
        this.payload = {
            amount: 0
        }
    }


    async render(bookingId) {

        /** TO DO LOAD BOOKING DATA */

        this.__modal.appendClasses(['wa-pop-up', 'wa-rate-booking-modal', 'wa-pop-up-free'])
        this.__modal.setOptions({
            title: `<div class='wa-font rate-heart-container'><span class='wa-icon-heart cursor-pointer' data-is-fav="0" data-fav-type="unit" data-trigger="favorite-task" data-fav-id="32"></span></div><div class='group-of-three'><span class="wa-profile-pic dimmed left" ><img src='https://picsum.photos/90' /></span><span class="wa-profile-pic center" ><img src='https://picsum.photos/90' /></span> <span class="wa-profile-pic dimmed right" ><img src='https://picsum.photos/90' /></div><p class='push-up-2'> Sara Connors</p>`,
            html: `<p>Would you like to send your professional(s) a tip?</p>
                <div data-container='tip-amount' class='wa-flex push-up-2'>
                    <div data-amount='10' class='base-btn tip-btn cursor-pointer'> $10 </div>
                    <div data-amount='20' class='base-btn tip-btn cursor-pointer'> $20 </div>
                    <div data-amount='30' class='base-btn tip-btn cursor-pointer'> $30 </div>
                </div>
                <div class='push-up-2'>
                    <a href="#" data-drawer="1" class="wa-flex wa-items-centered wa-items-center wa-cta" data-target="#custom-amount-holder" data-icon-target="#custom-amount-icon">
                    <span class="glyphicon glyphicon-plus-sign" id="custom-amount-icon"></span>
                    <span style="padding-left: 5px">Add custom amount</span></a>
                </div>
                
                <div class="" style='display:none' id='custom-amount-holder'>
                    <form >
                        <div class="input-holder wa-floating-label">
                            <input class="form-element  wa-form-input hidden-spinner" type="number" id="custom-amount" name="custom-amount" placeholder="Amount" value="">
                            <label class="wa-label" for="custom-amount">Amount</label>
                        </div>
                    </form>
                </div>
            `
        })

        const modal = this.__modal.render();
        WebAccounts.Drawer.initInElement(getElement('.wa-rate-booking-modal'));
        modal.then(async (result) => {
            this.__modal.appendClasses(['wa-pop-up', 'wa-rate-booking-modal', 'wa-pop-up-free'])
            const html = await this.__renderPaymentComponents()
            this.__modal.setOptions({
                title: `<div class='wa-font rate-heart-container'><span class='wa-icon-heart cursor-pointer' data-is-fav="0" data-fav-type="unit" data-trigger="favorite-task" data-fav-id="32"></span></div><div class='group-of-three'><span class="wa-profile-pic dimmed left" ><img src='https://picsum.photos/90' /></span><span class="wa-profile-pic center" ><img src='https://picsum.photos/90' /></span> <span class="wa-profile-pic dimmed right" ><img src='https://picsum.photos/90' /></div><p class='push-up-2'> Sara Connors</p>`,
                html: html
            }).render();
            swal.disableConfirmButton();
            this.__preparePaymentFlow();
            this.__attachPaymentFlowEventListeners()
            WebAccounts.Drawer.initInElement(getElement('.wa-rate-booking-modal'));
        })
        swal.disableConfirmButton();
        this.__attachAmountEventListeners()
    }

    __renderNewCardComponent() {
        return `<div id="add-details-form" class="background-gray b-radius-5"></div>`
    }

    __attachAmountEventListeners() {
        const container = getElement(`.wa-pop-up`)
        if (container) {
            const amountBtns = findAllIn(container, '[data-amount]');
            const expandBtn = findIn(container, `[data-drawer]`);
            const customAmountField = findIn(container, `#custom-amount`);
            amountBtns.forEach(btn => {
                btn.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.payload.amount = parseInt(e.currentTarget.dataset.amount);
                    amountBtns.forEach(el => {
                        el.classList.remove('active')
                    })
                    const expandTarget = getElement(expandBtn.dataset.target);
                    if (expandTarget.getAttribute('drawer-state') === 'opened') {
                        expandBtn.click();
                    }
                    e.currentTarget.classList.add('active');
                    swal.enableConfirmButton();
                    customAmountField.value = '';
                })
            })
            expandBtn.addEventListener('click', (e) => {
                this.payload.amount = 0;
                amountBtns.forEach(el => {
                    el.classList.remove('active')
                })
                swal.disableConfirmButton();
            })
            customAmountField.addEventListener('input', e => {
                this.payload.amount = e.currentTarget.value;
                console.log(e.currentTarget.value);
                if (this.payload.amount) {
                    swal.enableConfirmButton();
                } else {
                    swal.disableConfirmButton();
                }
            })
        }
    }

    async __renderPaymentComponents() {
        const response = await this.__getProfileConfig();
        const htmlItems = [];
        if (response.code === '200') {
            this.paymentMethod = response.data.configuration.payment_methods[0];
            if (response.data.paymethods.length > 0) {
                htmlItems.push(this.__generateCardList(response.data.paymethods));
                htmlItems.push(this.__generateNewCardInputs(true));
                return htmlItems.join('');
            }
            this.formState = 'new-card';
            return this.__generateNewCardInputs();
        }
        return '';
    }

    __getProfileConfig() {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: '/api/user/payment-information',
                success: resolve,
                error: reject
            })
        })
    }
    __generateCardList(cardList) {
        const htmlItems = ['<div class="form-group card-select">'];
        cardList.forEach(card => {
            htmlItems.push(`<div class="card-select-item clearfix">
            <label for="radio-card-item-${card.id}">
                <span class="card-radio"><input type="radio" name="payment-id" value="${card.id}" class="card-select-radio" id="radio-card-item-${card.id}"></span>
                <span class="card-number">${card.label}</span>
                <span class="card-logo">
                <object data="/build/images/card-logos/${card.data.brand.toLowerCase().replace(' ', '-')}.png" alt="${card.data.brand}"  width="50px" title="${card.data.brand}">
                    <img src="/build/images/card-logos/undefine.png" alt="${card.data.brand}">
                </object>
                </span>
            </label>
        </div>`)
        })
        htmlItems.push('</div>')
        return htmlItems.join('')
    }
    __preparePaymentFlow() {
        if (this.paymentMethod.type === "Stripe") {
            const sc_loader = new ScriptLoader(['https://js.stripe.com/v3/']);
            const submitBtn = getElement('.wa-pop-up .swal2-confirm');

            const newSubmitBtn = submitBtn.cloneNode(true);
            submitBtn.parentNode.replaceChild(newSubmitBtn, submitBtn);

            sc_loader.load().then(() => {
                this.paymentProviderInstance = new StripeProvider(this.paymentMethod.data.stripe_key, '#add-details-form', newSubmitBtn);

                this.paymentProviderInstance.setChallengeToken(this.__getChallengeToken.bind(this, this.paymentMethod.id));
                this.paymentProviderInstance.onSuccess(async (response) => {
                    if (response.hasOwnProperty('error')) {

                        this.__modal.setOptions({
                            title: response.error.message,
                            allowOutsideClick: false
                        });
                        this.__modal.render().then(() => { });
                    }

                    const cardResponse = await this.__createCard(
                        {
                            '_token': csrfToken,
                            'payload': {
                                "type": this.paymentMethod.type,
                                "payment_method_id": this.paymentMethod.id,
                                "data": {
                                    "token": response.setupIntent.payment_method,
                                },
                            }
                        });
                    if (cardResponse.code === '200') {
                        // swal.close(); 
                        this.payload.cardId = cardResponse.data.paymethods.id;
                        console.log(this.payload);

                    }
                    this.__loader.hide();
                })
                this.paymentProviderInstance.onStartProcessing(() => {
                    this.__loader.show()
                });
                this.paymentProviderInstance.onEndProcessing(() => {
                });
                this.paymentProviderInstance.onValidForm(() => {
                    this.validForm = true;
                    this.__checkCanSubmit(newSubmitBtn);
                });
                this.paymentProviderInstance.onInvalidForm(() => {
                    this.validForm = false;
                    this.__checkCanSubmit(newSubmitBtn);
                });
            });
        }
    }

    __generateNewCardInputs(hidden = false) {
        if (this.paymentMethod.type === "Stripe") {
            return `
            ${hidden ? `
            <div class='push-up-2'>
                <a href="#" data-drawer="1" class="wa-flex wa-items-centered wa-items-center wa-cta" data-target="#add-new-form" data-icon-target="#card-icon">
                <span class="glyphicon glyphicon-plus-sign" id="card-icon"></span>
                <span style="padding-left: 5px">Add card</span></a>
            </div>
            `: ''}
            <div class='row'>
                <div id="add-new-form"  class="col-md-12 push-up-2"  style="display:${hidden ? 'none' : 'block'};">
                    <div class="background-gray b-radius-5 inner-offset-2">
                        <div class="row">
                            <div class="acceptable-pay-methods row reset-margin">
                                <div class="col-sm-12 top-buffer">
                                    <ul class="pay-method list-unstyled wa-flex">
                                        <li class="pay-method-title" style="margin: 0 5px;">
                                            <span>
                                                We accept:
                                            </span>
                                        </li>
                                        <li style="margin: 0 5px;">
                                            <img alt="Visa" src="/images/card-logos/visa.png" title="Visa">
                                        </li>
                                        <li style="margin: 0 5px;">
                                            <img alt="Master Card" src="/images/card-logos/master.png" title="Master Card">
                                        </li>
                                        <li style="margin: 0 5px;">
                                            <img alt="Maestro" src="/images/card-logos/maestro.png" title="Maestro">
                                        </li>
                                        <li style="margin: 0 5px;">
                                            <img src="/images/card-logos/stripe.png" alt="Stripe" title="Stripe">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 push-up push-down-2" id="add-details-form"></div>
                        <div class="row push-up-3">
                            <div class="col-sm-9">
                                <div class="wa-flex pci-badged-text">
                                    <span>
                                        <img src="/images/icons/lock-icon.png" alt="" class="wa-badged-image">
                                    </span>
                                    <span>
                                        <h3 class="bold wa-badged-title text-align-left">Secure Card Payment</h3>
                                        <p class="wa-badged-description text-align-left">This is a secure 2048-bit SSL encrypted payment.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-3 text-md-center text-sm-center text-lg-right push-xs-up push-md-up">
                                <a style="display:block"
                                    href="//www.securitymetrics.com/site_certificate?id=1621957&amp;tk=36220e073d0218521ada10aaeb4ffb3d"
                                    target="_blank" rel="noopener noreferrer" class="noloader">
                                    <img class="full-width" style="max-width:100px;"
                                        src="https://www.securitymetrics.com/static/apps/super/assets/img/Credit_Card_Safe_light.png"
                                        alt="SecurityMetrics Credit Card Safe">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            `
        }
    }

    __getChallenge(payment_method_id, card_id = null) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/paymethods/${payment_method_id}/challenge`,
                type: 'GET',
                success: result => {
                    resolve(result.data.three_d_security_two_challenge)
                }
            })
        });
    }
    __getChallengeToken(payment_method_id, card_id = null) {
        return new Promise(async (resolve, reject) => {
            const response = await this.__getChallenge(payment_method_id, card_id);
            resolve(response.challenge_token)
        });
    }
    __checkCanSubmit(submitBtn) {
        if (!this.validForm) {
            submitBtn.setAttribute('disabled', '1')
            swal.disableConfirmButton()
        } else {
            submitBtn.removeAttribute('disabled')
            swal.enableConfirmButton()
        }
    }
    __createCard(requestData) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/api/account-settings/paymethods',
                type: 'POST',
                data: requestData,
                success: resolve,
                error: reject
            });
        })
    }
    __attachPaymentFlowEventListeners() {
        const container = getElement(`.wa-pop-up`)
        const submitBtn = getElement('.wa-pop-up .swal2-confirm');
        if (container) {
            const expandBtn = findIn(container, `[data-drawer]`);
            if (expandBtn) {
                expandBtn.addEventListener('click', (e) => {
                    const selectedCard = findIn(container, '.card-select input[type="radio"]:checked');
                    if (selectedCard && this.shouldResetCheckboxes) {
                        selectedCard.checked = false;
                        this.validForm = false;
                        this.payload.cardId = null;
                    }
                    this.shouldResetCheckboxes = true
                    this.__checkCanSubmit(submitBtn);
                    const expandableElement = findIn(container, `[drawer-state='opened']`);
                    this.formState = expandableElement ? 'old-card' : 'new-card';
                    this.__updatePaymentFlow();
                })
            }
            const cardsList = findAllIn(container, '.card-select input[type="radio"]');
            if (cardsList.length) {
                cardsList.forEach(cardEl => {
                    cardEl.addEventListener('change', (e) => {
                        const expandableElement = findIn(container, `[drawer-state='opened']`);
                        this.payload.cardId = e.target.value;
                        this.validForm = true;
                        this.__checkCanSubmit(submitBtn);
                        this.formState = 'old-card';
                        this.__updatePaymentFlow();
                        if (expandableElement) {
                            this.shouldResetCheckboxes = false
                            expandBtn.click();
                        }
                    });
                })
            }
            submitBtn.addEventListener('click', e => {
                if (this.formState === 'old-card') {
                    console.log(this.payload);
                }
            })
        }
    }
    __updatePaymentFlow() {
        if (this.formState === 'old-card') {
            this.paymentProviderInstance.setShouldSubmit(false);
        } else if (this.formState === 'new-card') {
            this.paymentProviderInstance.setShouldSubmit(true);
        }
    }

}
(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('RescheduleTask')) {
        WebAccounts.RescheduleTask = {
            elements: $('[data-trigger="reschedule"]'),
            apiEndpoint: '/api/booking/reschedule_reasons',
            reasonsNodes: "",
            requestPayload: {},
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    if (!WebAccounts.RescheduleTask.hasOwnProperty('exist') ||
        !WebAccounts.RescheduleTask.exist()) {
        return;
    }

    /** Constructor */
    WebAccounts.RescheduleTask.construct = function () {
        WebAccounts.RescheduleTask.elements.on('click', WebAccounts.RescheduleTask.openReasonsModal);
    }

    /**
     * @param {Event} e 
     */
    WebAccounts.RescheduleTask.openReasonsModal = function (e) {
        WebAccounts.RescheduleTask.requestPayload = {
            addressId: null,
        }

        const currentServiceCard = $(e.currentTarget).parents(".service-meta-information"),
            bookingId = currentServiceCard.data('bookingId');
        WebAccounts.RescheduleTask.reschedule(bookingId, currentServiceCard.data('addressId')).then((result) => {
            if (result) {
                $('[data-book-btn=true]')[0].click();
            }
        });
    }


    /**
     * Builds the obfOptions
     * 
     * @param {int} bookingId 
     * @param {string} addresses 
     */
    WebAccounts.RescheduleTask.reschedule = function (bookingId, addresses) {
        let addressId = undefined;
        try {
            addressId = JSON.parse(JSON.stringify(addresses))[0];
        } catch (error) {
            console.log(`Couldn't parse ${addresses}`)
        }

        return new Promise((resolve, reject) => {
            if (typeof obfOptions === 'object') {
                obfOptions.bookingEditType = "serviceAvailability";
                obfOptions.bookingId = bookingId;
                obfOptions.addressId = addressId;
            }
            
            resolve(true);
        });
    }

    WebAccounts.RescheduleTask.countReasons = function () {
        return !!this.reasonsNodes.length;
    }

    WebAccounts.RescheduleTask.construct();

})(window.WebAccounts = window.WebAccounts || {}, jQuery);

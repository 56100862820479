const { trans } = require("../common/helpers");

(function (WebAccounts, $, undefined) {
    WebAccounts.Settings.CommunicationPreferences = {
        el: $('.communication-preferences-form'),
        savePreferences: null,
        endpointSavePreferences: 'api/account-settings/saveCommunicationPreferences',
        exists: function () {
            return !!this.el.length;
        }
    };

    if (!WebAccounts.Settings.CommunicationPreferences.exists()) {
        return;
    }

    /**
     * Adds event listeners to the given elements
     *
     * @return void
     */
    WebAccounts.Settings.CommunicationPreferences.ini = function () {
        this.savePreferences = this.el.find('#saveCommunicationPreferences');
        this.savePreferences.on('click', WebAccounts.Settings.CommunicationPreferences.save)
    };

    /**
     * Makes request to the WebAccounts API requesting save with all chosen options
     *
     * @return void
     */
    WebAccounts.Settings.CommunicationPreferences.save = function () {
        $.ajax({
            url: WebAccounts.Settings.CommunicationPreferences.endpointSavePreferences,
            type: 'POST',
            data: WebAccounts.Settings.CommunicationPreferences.el.serialize(),
            success: function (result) {
                if (result.hasOwnProperty('errors')) {
                    WebAccounts.Settings.CommunicationPreferences.handleErrors();
                    return;
                }

                WebAccounts.Utilities.ShowModal({
                    title: 'Success!',
                    text: result['successes']['preferences']['success']['message']||'Your Communacation preferences has been saved.',
                    type: 'success',
                });
            },
            error: function (result) {
                WebAccounts.Settings.CommunicationPreferences.handleErrors();
            }
        });
    };

    /**
     * Handler for errors that come with success response
     * Mostly from XRM
     * 
     * @return void
     */
    WebAccounts.Settings.CommunicationPreferences.handleErrors = function () {
        WebAccounts.Utilities.ShowModal({
            title: trans('general.errors.generic'),
            type: 'warning',
        });
    }

    //Initialize the address settings card
    WebAccounts.Settings.CommunicationPreferences.ini();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
import { WALoader } from "../common/wa-loader";
import { trans } from "../common/helpers";

(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('CancelTask')) {
        WebAccounts.CancelTask = {
            elements: $('[data-trigger="cancel"]'),
            apiEndpoint: '/api/booking/cancellation_info',
            radioBtnText: undefined,
            isDetailsPage: false,
            cancelReasonsNodes: "",
            cancelAdditionalInfo: "",
            cachedBookingId: null,
            cancellingRefNum: undefined,
            isV2: true, // Compatibility issues
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    if (!WebAccounts.CancelTask.hasOwnProperty('exist') ||
        !WebAccounts.CancelTask.exist()) {
        return;
    }

    /** Constructor */
    WebAccounts.CancelTask.construct = function () {
        WebAccounts.CancelTask.elements.on('click', this.openReasonsModal);
    }

    /**
     * @param {Event} e 
     */
    WebAccounts.CancelTask.openReasonsModal = function (e) {
        const currentServiceCard = $(e.currentTarget).parents('.service-meta-information');
        // Re-initialize requestPayload for every click event
        WebAccounts.CancelTask.requestPayload = {
            reasonId: undefined,
            hasClientReason: false,
            bookingId: undefined,
            comment: undefined
        };

        // Set request payload 
        WebAccounts.CancelTask.requestPayload.bookingId = currentServiceCard.data('bookingId');

        //show loader
        WebAccounts.loaderElement.addClass('visible');

        // Retrieve Cancel reasons from WA
        const loadingReasons = new Promise(WebAccounts.CancelTask.loadTexts);

        // Determine if the user is in Detailed page or at listing page
        WebAccounts.CancelTask.isDetailsPage = currentServiceCard.data('detailsPage') || false;

        // Retrieve reference number of the cancel booking
        WebAccounts.CancelTask.cancellingRefNum = currentServiceCard.data('refNumber');

        // After Promise is resolved (reasons are loaded) fill modal and show it 
        loadingReasons.then(() => {
            let ModalTitle = `<p class="push-up">${trans('cancelTask.booking.title')}</p>`,
                ModalConfirmText = trans('cancelTask.booking.confirmBtn');

            WebAccounts.CancelTask.isSession = $(e.currentTarget).hasClass('skip-session-btn');

            if (WebAccounts.CancelTask.isSession) {
                ModalTitle = `<p class="push-up">${trans('cancelTask.session.title')}</p>`;
                ModalConfirmText = trans('cancelTask.session.confirmBtn');
            }

            const popupContainer = WebAccounts.CancelTask.composeContainer();
            // Removes WA
            WebAccounts.loaderElement.removeClass('visible');

            WebAccounts.Utilities.ShowModal({
                customClass: 'wa-pop-up wa-cancel-modal',
                title: ModalTitle,
                html: popupContainer,
                preConfirm: () => { return WebAccounts.CancelTask.buildRequestPayload().validate() },
                confirmButtonText: ModalConfirmText,
            }).then((result) => {
                if (result) {
                    WebAccounts.CancelTask.cancel();
                }
            });
            swal.disableConfirmButton()

            const listItems = $(".modal-container ul li");

            listItems.on('click', 'label>input', WebAccounts.CancelTask.modifyCheckBoxes)
        });
    }

    /**
     * @return {Promise}
     */
    WebAccounts.CancelTask.validate = function () {
        let webAccData = WebAccounts.CancelTask.requestPayload;

        return new Promise((resolve, reject) => {
            if (webAccData.hasClientReason && webAccData.comment === "") {
                dataLayer.push({
                    event: "failedSubmit"
                });

                reject('Please specify cancel reason!')
            }
            resolve(true);
        });
    }

    WebAccounts.CancelTask.composeContainer = () => {

        return `<div class="modal-container">
            ${WebAccounts.CancelTask.cancelReasonsNodes}
            ${WebAccounts.CancelTask.cancelAdditionalInfo}
            </div>`;
    }

    /**
     * Get custom cancel reason and adds it to the payload
     * 
     * @return {WebAccounts.CancelTask}
     */
    WebAccounts.CancelTask.buildRequestPayload = function () {
        let cancelReason = $('.modal-other-reason-holder input.cancel-reason');

        if (cancelReason.length) {
            cancelReason = cancelReason.val() || "";
            WebAccounts.CancelTask.requestPayload.comment = cancelReason.trim();
        }

        return WebAccounts.CancelTask;
    }

    // Give checkboxes functionality
    WebAccounts.CancelTask.modifyCheckBoxes = function (e) {
        swal.enableConfirmButton()

        const checkBox = $(e.currentTarget),
            checkBoxContainer = checkBox.parents('li');

        let otherReasonInput = checkBoxContainer.parent().find('div.modal-other-reason-holder');

        let shouldAddNew = WebAccounts.CancelTask.requestPayload.reasonId != checkBox.data('id');
        let shouldRemoveTextField = (shouldAddNew
            && WebAccounts.CancelTask.requestPayload.reasonId
            && WebAccounts.CancelTask.requestPayload.hasClientReason);

        WebAccounts.CancelTask.requestPayload.reasonId = checkBox.data('id');
        WebAccounts.CancelTask.radioBtnText = checkBox.val();

        if (checkBox.data("client-reason")) {
            if (shouldRemoveTextField) {
                otherReasonInput.slideUp(() => {
                    otherReasonInput.remove();
                    otherReasonInput = WebAccounts.CancelTask.slideNewTextField(checkBoxContainer)
                });
            } else if (shouldAddNew) {
                otherReasonInput = WebAccounts.CancelTask.slideNewTextField(checkBoxContainer)
            }

            WebAccounts.CancelTask.requestPayload.hasClientReason = true;
        } else {
            otherReasonInput.slideUp(() => {
                WebAccounts.CancelTask.requestPayload.hasClientReason = false;
                otherReasonInput.remove();
            });
        }
    }
    WebAccounts.CancelTask.slideNewTextField = function (container) {
        container.append(`<div class="modal-other-reason-holder" style='display:none;'> <input type='text' class='cancel-reason' placeholder='Please specify'></div>`);
        let otherReasonInput = container.find('div.modal-other-reason-holder');
        otherReasonInput.slideDown();

        return otherReasonInput;
    }
    /**
     * Check if we have cached cancellation reasons for the given booking
     */
    WebAccounts.CancelTask.checkCache = () => {
        return WebAccounts.CancelTask.cachedBookingId == WebAccounts.CancelTask.requestPayload.bookingId;
    }


    /**
     * Load cancel reasons from the API
     * 
     * @return {void}
     */
    WebAccounts.CancelTask.loadTexts = function (resolve, reject) {
        // if there are already load stop execution
        if (WebAccounts.CancelTask.countReasons() && WebAccounts.CancelTask.checkCache()) {
            return resolve();
        }
        const bookingId = WebAccounts.CancelTask.requestPayload.bookingId;
        WebAccounts.CancelTask.cachedBookingId = bookingId;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': csrfToken
            }
        });

        // make request to WebAccounts API;
        $.ajax({
            type: "POST",
            url: WebAccounts.CancelTask.apiEndpoint,
            data: { "bookingId": bookingId },
            success: response => {
                WebAccounts.CancelTask.handleResponse(response);
                resolve();
            }
        });
    }

    /**
     * Handles errors if there are any
     * triggers buildReasons with the data from the response
     * 
     * @param {JSON} response 
     */
    WebAccounts.CancelTask.handleResponse = function (response) {
        if (response.hasOwnProperty('errors')) {
            response.errors.forEach(element => {
                console.log(new Error(element.message));
            });
            return;
        }

        this.buildReasonsNodes(response)
        this.buildAdditionalInfoNode(response)
    }

    /**
     * Fill cancel reasons nodes with information from the response object
     * 
     * @param {JSON} response 
     */
    WebAccounts.CancelTask.buildReasonsNodes = function (response) {
        let listStart = `<ul class="unstyled-list text-align-left modal-reasons-list push-down">`,
            listEnd = `</ul>`;

        let sortedReasons = response.cancel_reasons.sort((a, b) => {
            return a.sort > b.sort;
        });

        sortedReasons.forEach((item, key) => {
            listStart += this.fillNodeTemplate(item, key + 1);
        });

        this.cancelReasonsNodes = listStart + listEnd;
    }
    /**
     * Fill cancel reasons nodes with information from the response object
     * 
     * @param {JSON} response 
     */
    WebAccounts.CancelTask.buildAdditionalInfoNode = function (response) {
        try {

            this.cancelAdditionalInfo = `<div class="wa-flex wa-items-centered wa-info-msg ">
                                    <span class="wa-info-icon"> i </span>
                                    <p class="text-left">
                                    ${response.cancellation_terms.description} 
                                    <a href="${response.cancellation_terms.policy.link}" class="noloader" target="_blank">
                                        <u>${response.cancellation_terms.policy.title}</u>></a>
                                    </p>
                                </div>`;
        } catch (error) {
            this.cancelAdditionalInfo = "";
        }
    }

    /**
     * Fills string template with data from the response data
     * 
     * @param {HTMLElement} item 
     * @param {number} key 
     * 
     * @return {String}
     */
    WebAccounts.CancelTask.fillNodeTemplate = function (item, key) {
        return `<li>
                    <label class="cancelReasonItem custom-radio wa-flex wa-items-centered" for="reason-${key}">
                    <input class="cancel-reason" id="reason-${key}" type="radio" name="cancel_reasons" value="${item['name']}" data-id="${item['id']}" data-client-reason="${item['requires_comment']}">
                    <span class="wa-radio"></span>
                        <span class="cancelReasonName">${item['name']}</span>
                    </label>
                </li>`
    };

    /**
     * Make request to the Backend and delete the booking card if there is such
     */
    WebAccounts.CancelTask.cancel = function () {
        WebAccounts.loaderElement.addClass('visible');
        dataLayer.push({
            'event': 'successfulSubmit',
            'radioChoice': WebAccounts.CancelTask.radioBtnText,
            'additionalNotes': WebAccounts.CancelTask.requestPayload.comment
        });
        $.ajax({
            url: '/cancel/booking',
            type: 'DELETE',
            data: WebAccounts.CancelTask.requestPayload,
            success: function (result) {
                if (!result) {
                    return;
                }

                try {
                    result = JSON.parse(JSON.stringify(result));
                } catch (e) {
                    console.log(result);
                    console.log('Error parsing above result as JSON');
                    return;
                }


                if (result.hasOwnProperty('status') && result.status === "failed") {
                    return WebAccounts.Utilities.ShowModal({
                        title: 'Whoops... Something went wrong'
                    });
                }

                if (result.hasOwnProperty('cancel') && result.cancel && result.cancel.length) {
                    const loaderEl = new WALoader();
                    return WebAccounts.Utilities.ShowModal({
                        title: result.cancel[0].message
                    }).then(() => {
                        loaderEl.hide();
                    }).catch(() => {
                        loaderEl.hide();
                    });
                }
                let failed = false;
                let modalOptions = {}
                if (WebAccounts.CancelTask.isV2) {
                    if (result.hasOwnProperty('successes') && result['successes']) {
                        modalOptions = {
                            title: `<span class="">${result['successes']['cancel']['success']['message']}</span>`,
                            allowOutsideClick: false
                        };
                    } else if (result.hasOwnProperty('errors')) {
                        modalOptions = {
                            title: `<span class="">${result['errors']['cancel'][0]['message']}</span>`,
                            allowOutsideClick: false
                        };
                        failed = true;
                    } else {
                        return WebAccounts.Utilities.ShowModal({
                            title: 'Whoops... Something went wrong'
                        });
                    }
                } else {
                    modalOptions = {
                        title: undefined,
                        text: undefined,
                        allowOutsideClick: false
                    };
                    if (WebAccounts.CancelTask.isSession) {
                        modalOptions.title = `<span class="">${trans('cancelTask.session.success.title')}</span>`;
                        // modalOptions.text = 'Thank you for notifying us in advance. We do not charge a cancellation fee for early cancellations.';
                    } else {
                        modalOptions.title = `<span class="">${trans('cancelTask.booking.success.title')}</span>`;
                    }
                }

                let modalPromise = WebAccounts.Utilities.ShowModal(modalOptions);
                WebAccounts.loaderElement.removeClass('visible');

                if(!failed){
                    dataLayer.push({ 'event': 'bookingCancelled', 'canceledRefNum': WebAccounts.CancelTask.cancellingRefNum });
                    modalPromise.then(() => {
                        return location.href = "/bookings";
                    }).catch(() => {
                        return location.href = "/bookings";
                    });
                }
            },
            error: function (result) {

            }
        });
    }

    /**
     * @return {boolean}
     */
    WebAccounts.CancelTask.countReasons = function () {
        return !!this.cancelReasonsNodes.length;
    }

    /**
     * Decrements all bookings counter
     * 
     * @return {Promise}
     */
    WebAccounts.CancelTask.updateBookingsCounter = function () {
        return new Promise((resolve, reject) => {
            const bookingCounterElements = $(".bookings-counter");
            let bookingsCount = parseInt((bookingCounterElements.length) ? $(bookingCounterElements[0]).text() : 0);

            (bookingsCount) ? bookingsCount-- : null;

            for (let index = 0; index < bookingCounterElements.length; index++) {
                $(bookingCounterElements[index]).text(bookingsCount);
            };

            resolve();
        })

    }

    WebAccounts.CancelTask.construct();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

import { extractResponseMessages, getProfileConfig, getElement, makeElement, trans } from "../common/helpers";
import { WAModal } from "../common/wa-modal";
import { TipTask } from "./tip-task";


(function (WebAccounts, $, undefined) {
    // (new TipTask()).render();
    if (!WebAccounts.hasOwnProperty('RateTask')) {
        WebAccounts.RateTask = {
            elements: $('[data-trigger="rate"]'),
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    //Check if any service card items exist
    if (!WebAccounts.RateTask.hasOwnProperty('exist') ||
        !WebAccounts.RateTask.exist()) {
        return;
    }

    //Elements, used within the layout whenever a rating is going to be submitted
    WebAccounts.RateTask.trustpilotLink = undefined;
    WebAccounts.RateTask.currentRatingScore = undefined;
    WebAccounts.RateTask.targetedServiceCard = undefined;
    WebAccounts.RateTask.selectedTags = [];

    WebAccounts.RateTask.construct = function () {
        WebAccounts.RateTask.elements.on('click', WebAccounts.RateTask.openModal);
    };

    /**
     * @param {Event} event 
     */
    WebAccounts.RateTask.openModal = async (e) => {
        e.preventDefault();
        WebAccounts.loaderElement.addClass('visible');
        const currentServiceButton = $(e.currentTarget),
            currentServiceCard = currentServiceButton.parents('.service-meta-information');

        WebAccounts.RateTask.targetedServiceCard = currentServiceCard;

        const rateTags = await WebAccounts.RateTask.getTags(currentServiceCard.data('bookingId'));
        WebAccounts.RateTask.parsedRateTags = WebAccounts.RateTask.parseTags(rateTags);

        WebAccounts.loaderElement.removeClass('visible');

        let confirmBtn = trans('rateTask.default.confirmBtn');
        WebAccounts.RateTask.trustpilotLink = currentServiceCard.data('trustpilotLink') || null;

        let modalTitle = `<p class="">${trans('rateTask.default.modalTitle')}</p>`;

        WebAccounts.Utilities.ShowModal({
            customClass: 'wa-pop-up wa-rate-booking-modal wa-pop-up-free',
            title: modalTitle,
            html: `<div class="service-card-rate-holder">
                    <div class="rate"></div>
                  </div>`,
            confirmButtonText: confirmBtn,
        }).then((result) => {
            if (result) {
                if (!(currentServiceCard.attr('data-score') >= 1)) {
                    const currentRateTags = WebAccounts.RateTask.parsedRateTags[WebAccounts.RateTask.newRateScore];
                    const shouldRenderTags = (currentRateTags.length !== 0);
                    const modal = new WAModal();
                    modal.appendClasses(['wa-pop-up', 'wa-rate-booking-modal', 'wa-pop-up-free']);
                    modal.setOptions({
                        title: modalTitle,
                        html: `
                        <div class="service-card-rate-holder">
                            <div class="rate"></div>
                        </div>
                        <h3 class="title text-center push-up-2" id='rating-tags-title' style='line-height: 1.5em;'></h3>
                                ${shouldRenderTags ? `<div id="rating-tags-container" class="push-up-2 push-down  wa-rating-tags wa-flex wa-items-center  wa-items-wrap"></div>` : ``}
                                <div style="display:inline-block">
                                    <a href="#" data-drawer="1" class="wa-flex wa-items-centered wa-items-center wa-cta" data-target="#comment-area" data-icon-target="#comment-icon">
                                    <span class="glyphicon glyphicon-plus-sign" id="comment-icon"></span>
                                    <span style="padding-left: 5px">${trans('rateTask.feedback.cta')}</span></a>
                                </div>
                                <div class="client-reason-text-area" style='display:none' id='comment-area'>
                                    <form >
                                        <div class="input-holder wa-floating-label">
                                            <input class="form-element  wa-form-input" type="text" id="wa-rate-comment" name="wa-rate-comment" placeholder="${trans('rateTask.tags.placeholder')}" value="">
                                            <label class="wa-label" for="wa-rate-comment">${trans('rateTask.tags.placeholder')}</label>
                                        </div>
                                    </form>
                               </div>`,
                        confirmButtonText: trans('general.continue')
                    })
                    modal.render().then((success) => {
                        if (success) {
                            WebAccounts.RateTask.rate(currentServiceCard.data('bookingId'));
                        }
                    }).catch(e => {
                        WebAccounts.RateTask.rate(currentServiceCard.data('bookingId'));
                    });
                    const rateComment = getElement('[name="wa-rate-comment"]');
                    if (rateComment) {
                        rateComment.addEventListener('keydown', (e) => {
                            if (e.code === 'Enter') {
                                const el = getElement('button.swal2-confirm.btn.swal-custom-btn')
                                el.click()
                                e.preventDefault()
                                e.stopPropagation()
                            }
                        })
                    }
                    WebAccounts.Drawer.initInElement(getElement('.wa-rate-booking-modal'));
                    if (shouldRenderTags) {
                        WebAccounts.RateTask.renderTags();
                    }

                    WebAccounts.RateTask.renderTagsTitle();

                    WebAccounts.RateTask.renderRaty(
                        parseInt(WebAccounts.RateTask.newRateScore)
                    );
                } else {
                    WebAccounts.RateTask.rate(currentServiceCard.data('bookingId'));
                }

            }
        })
        swal.disableConfirmButton();


        WebAccounts.RateTask.renderRaty(
            parseInt(currentServiceCard.attr('data-score'))
        );
    }

    WebAccounts.RateTask.renderTags = () => {
        const cont = document.querySelector("#rating-tags-container");
        if (cont) {
            cont.innerHTML = "";
            WebAccounts.RateTask.generateTags(WebAccounts.RateTask.parsedRateTags[WebAccounts.RateTask.newRateScore], cont)
        }
    }
    WebAccounts.RateTask.renderTagsTitle = () => {
        const cont = document.querySelector("#rating-tags-title");
        if (cont) {
            cont.innerHTML = WebAccounts.RateTask.newRateScore <= 3 ? trans('rateTask.tags.title.negative') : trans('rateTask.tags.title.positive');
        }
    }

    /**
     * Configures Raty plug-in
     * docs
     * @link {https://github.com/wbotelhos/raty}
     * 
     * @param {int} score
     */
    WebAccounts.RateTask.renderRaty = function (score) {
        let currentRate = $(".service-card-rate-holder .rate");

        currentRate.raty('destroy');
        $.fn.raty.defaults.path = '/images/icons';

        let rateParams = {
            starOff: 'rate-star-base.svg',
            hints: ['Very poor', 'Poor', 'Avarage', 'Good', 'Fantastic']
        };

        rateParams.readOnly = false;
        rateParams.starOn = 'rate-star-selected.svg';
        rateParams.score = score;
        rateParams.click = function (newScore, event) {
            WebAccounts.RateTask.selectedTags = [];
            swal.enableConfirmButton();
            WebAccounts.RateTask.newRateScore = newScore;
            WebAccounts.RateTask.renderTags();
            WebAccounts.RateTask.renderTagsTitle();
        };

        currentRate.raty('set', rateParams);
    };

    /**
     * Make request to WA
     * 
     * @param {int} bookingId 
     */
    WebAccounts.RateTask.rate = function (bookingId) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': csrfToken
            }
        });

        dataLayer.push({
            'event': "successfulSubmit"
        });

        let rateComment = $(`[name="wa-rate-comment"]`);

        let rateCommentText = (rateComment.length) ? rateComment.val() : null;

        let requestData = {
            "booking": bookingId,
            "rating": WebAccounts.RateTask.newRateScore,
            "ratingText": rateCommentText,
            "rateTags": WebAccounts.RateTask.selectedTags
        };


        WebAccounts.loaderElement.addClass('visible');
        $.ajax({
            url: '/rate-booking',
            type: 'post',
            data: requestData,
            success: async (result) => {
                if (result.hasOwnProperty('errors')) {
                    let errMsg = trans('general.errors.generic');
                    try {
                        errMsg = result['errors']['rate'][0]['message'];
                    } catch (e) { }
                    WebAccounts.Utilities.ShowModal({
                        title: errMsg
                    });
                    return;
                }

                if (requestData.rating >= 4) {
                    const waConf = await getProfileConfig();
                    if (waConf.code === "200") {
                        const rateCompleteScreen = waConf.data.configuration.rate_service_screens.screens.find(item => item.screen === 'positive_rate_complete');
                        if (rateCompleteScreen) {
                            let opt = {
                                customClass: 'wa-pop-up wa-rate-booking-modal wa-pop-up-free',
                                html: `<div class="wa-rate-booking-modal-content wa-font">`
                            }

                            rateCompleteScreen.items.forEach((item, index) => {
                                if (item.id === 'title') {
                                    opt.title = `<p>${item.properties.title.replace('{{rating_value}}', requestData.rating)}</p>`
                                    return;
                                }
                                if (['trust_pilot', 'invite'].includes(item.id)) {
                                    opt = WebAccounts.RateTask.layoutButton(rateCompleteScreen.items, item.id, index, opt)
                                }
                                if (item.id === 'done') {
                                    opt.confirmButtonText = item.properties.title
                                }
                            })

                            opt.html += '</div>';

                            WebAccounts.Utilities.ShowModal(opt).then(result => {
                                // (new TipTask()).render();
                            })
                        }

                    }

                } else {
                    WebAccounts.Utilities.ShowModal({
                        customClass: 'wa-pop-up wa-rate-booking-modal wa-pop-up-free',
                        title: trans('rateTask.lowRate.title'),
                        confirmButtonText: trans('rateTask.lowRate.confirmBtn'),
                    })
                }
                WebAccounts.loaderElement.removeClass('visible');

                const imageHolder = WebAccounts.RateTask.targetedServiceCard.find('[data-imageholder]');
                const rebookBtnContainer = WebAccounts.RateTask.targetedServiceCard.find('[data-container="rebook"]');
                const rateBtnContainer = WebAccounts.RateTask.targetedServiceCard.find('[data-container="rate"]');
                const newRatingElement = makeElement(`<span class="service-booking-rated"></span>`);

                for (let index = 1; index <= 5; index++) {
                    newRatingElement.appendChild(makeElement(`<img style="width:20px;margin: 0px 5px;" src="${index > WebAccounts.RateTask.newRateScore ? '/build/images/icons/rate-star-base.svg' : '/build/images/icons/rate-star-selected.svg'}">`))
                }
                imageHolder.prepend(newRatingElement);

                WebAccounts.RateTask.targetedServiceCard.attr('data-score', WebAccounts.RateTask.newRateScore);
                WebAccounts.RateTask.trustpilotLink = null;
                WebAccounts.RateTask.targetedServiceCard = undefined;
                if (rateBtnContainer) {
                    rateBtnContainer.remove();
                }
                if (rebookBtnContainer) {
                    rebookBtnContainer.removeClass('col-xs-6')
                    rebookBtnContainer.addClass('col-xs-12')
                }
            },
            error: function (result) {
                WebAccounts.loaderElement.removeClass('visible');
                WebAccounts.Utilities.ShowModal({
                    title: trans('general.errors.generic')
                });
                WebAccounts.RateTask.targetedServiceCard = undefined;
            }
        });
    }

    WebAccounts.RateTask.layoutButton = (layoutData, type, idx, opt) => {
        let html = `<div class='push-up-2'>
                        `
        for (let index = idx; index < layoutData.length; index++) {
            const element = layoutData[index];
            if (element.id && element.id !== type) {
                break;
            }
            if (element.type == 'ButtonItem') {
                html += `<div style='width: 100%;padding: 10px 25px;display: inline-flex;max-width: 310px'>
                    <a class='cursor-pointer base-btn noloader' target='${element.properties.target || '_self'}' href='${element.properties.function ? WebAccounts.RateTask[element.properties.function]() : element.properties.url}'>
                    <span  style="padding-right:10px">
                        ${element.properties.url_image ? `<img src="${element.properties.url_image}" style="width:20px; height:20px"/>` : ''}
                    </span> ${element.properties.title}
                    </a>
                </div>`
                continue;
            }
            if (element.type == 'TextItem') {
                html += `<p class='push-up'>
                ${element.properties.title}
            </p>`
                continue;
            }

        }
        html += `</div>`;
        opt.html += html;
        return opt;
    }
    WebAccounts.RateTask.generateTrustpilot = () => {
        return WebAccounts.RateTask.targetedServiceCard.data('trustpilotLink');
    }
    WebAccounts.RateTask.parseTags = (tags) => {
        let rateTags = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: []
        };
        try {
            tags.forEach(tag => {
                let tagData = { title: tag['name'], id: tag['id'] };
                tag.applies_for_ratings.forEach(applied_id => {
                    rateTags[applied_id].push(tagData);
                });

            });

        } catch (error) { }

        return rateTags;
    }

    WebAccounts.RateTask.getTags = (booking_id) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: "GET",
                url: window.location.origin + `/api/rate/${booking_id}/tags`,
                success: (response) => {
                    if (
                        response.hasOwnProperty('errors')
                    ) {
                        reject(extractResponseMessages(response)); // TO DO TEST
                    }
                    try {
                        resolve(response.data.rate_tags);
                    } catch (error) {
                        resolve([]);
                    }
                }
            })
        })
    }

    WebAccounts.RateTask.generateTags = (tags, tagsContainer) => {
        if (tags.length == 0) {
            return;
        }
        tags.forEach(tag => {
            const label = makeElement(`<div class="wa-rate-tag" data-tag-id="${tag.id}">${tag.title}</div>`);
            label.addEventListener('click', (event) => {
                event.stopPropagation();
                event.preventDefault();
                const addedTagIndex = WebAccounts.RateTask.selectedTags.findIndex(tagId => tagId == event.currentTarget.dataset.tagId);

                if (addedTagIndex === -1) {
                    WebAccounts.RateTask.selectedTags.push(event.target.dataset.tagId);
                    event.currentTarget.classList.add('tag-added');
                    event.currentTarget.appendChild(makeElement(`<span class="glyphicon glyphicon-ok" style='padding-left:10px; font-size:0.8em'></span>`))
                } else {
                    WebAccounts.RateTask.selectedTags.splice(addedTagIndex, 1);
                    event.currentTarget.classList.remove('tag-added');
                    (event.currentTarget.querySelector('.glyphicon')).remove();
                }
            });

            tagsContainer.appendChild(label);
        });
    }

    WebAccounts.RateTask.construct();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
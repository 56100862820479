import { MembershipReminders } from "./common/membership-reminders";
import { PageInteractor } from "./common/page-interactor";
import { MobileAppBanner } from "./common/mobile-app-banner";
import { getMetaTagContent, isMobile, getElements, setCookie, getCookie, getElement, deleteElement, addHandlers, getQueryStringValue, hexToRgb, rgbTint, rgbShade, toRGBStyle, generateVisibleTextColor } from "./common/helpers";
import { OBFManager } from "./obf-manager";
import Locale from "./common/locale";
import { WAHandlers } from "./common/wa-handlers";
import { WALoader } from "./common/wa-loader";

(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode !== null)
                    this.parentNode.removeChild(this);
            }
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

const setupLocale = async () => {
    return new Promise(async (resolve, reject) => {
        Promise.all([
            window.appLocale.load(getMetaTagContent('lang-locale')),
            window.appLocale.loadFallback('en')
        ]).then(e => resolve('done')).catch(err => reject(err))
    })
}
const setupTheme = () => {
    return new Promise((resolve, reject) => {
        let root = document.documentElement;
        let theme = JSON.parse(getMetaTagContent("theme-data"));
        if (theme) {
            const primaryColor = hexToRgb(theme.primary_color);
            const accentColor = theme.accent_color
                ? hexToRgb(theme.accent_color)
                : rgbTint(primaryColor, 0.2);
            const complimentaryColor = theme.complimentary_color
                ? hexToRgb(theme.complimentary_color)
                : rgbTint(primaryColor, 0.8);
            const menuColor = theme.menu_color
                ? hexToRgb(theme.menu_color)
                : primaryColor;
            const menuTextColor = rgbTint(menuColor, 0.8);
            const menuActiveColor = theme.menu_active_color
                ? hexToRgb(theme.menu_active_color)
                : complimentaryColor;
            const menuTextActiveColor = rgbShade(menuActiveColor, 0.6);

            const font = theme.font || null;

            const accentColorLighter1 = rgbTint(accentColor, 0.8);
            const accentColorLighter2 = rgbTint(accentColor, 0.85);
            const accentColorLighter3 = rgbTint(accentColor, 0.9);



            root.style.setProperty("--primary-color", toRGBStyle(primaryColor));
            root.style.setProperty("--accent-color", toRGBStyle(accentColor));
            root.style.setProperty(
                "--accent-light-1-color",
                toRGBStyle(accentColorLighter1)
            );
            root.style.setProperty(
                "--accent-light-2-color",
                toRGBStyle(accentColorLighter2)
            );
            root.style.setProperty(
                "--accent-light-3-color",
                toRGBStyle(accentColorLighter3)
            );
            root.style.setProperty(
                "--complimentary-color",
                toRGBStyle(complimentaryColor)
            );
            root.style.setProperty("--menu-color", toRGBStyle(menuColor));
            root.style.setProperty("--menu-text-color", toRGBStyle(menuTextColor));
            root.style.setProperty(
                "--menu-active-color",
                toRGBStyle(menuActiveColor)
            );
            root.style.setProperty(
                "--menu-text-active-color",
                toRGBStyle(menuTextActiveColor)
            );
            root.style.setProperty(
                "--service-label-txt-color",
                generateVisibleTextColor(complimentaryColor)
            );
            if (font) root.style.setProperty('--font-name', font);
            if (window.obfOptions && !(['fantastic-services', 'uspaah'].includes(window.waTmp))) {
                window.obfOptions.theme = {
                    "primary-color": toRGBStyle(primaryColor),
                    "secondary-color": toRGBStyle(accentColor),
                    "loader-outside-circle": toRGBStyle(accentColor),
                    "loader-middle-circle": toRGBStyle(primaryColor),
                    "loader-inside-circle": toRGBStyle(complimentaryColor),
                };
            }
            resolve("done");
        }

        resolve("Theme not found");
    });
};


(async function (WebAccounts, $, undefined) {
    let pageInteractor = new PageInteractor();

    document.addEventListener("waConfigurationsLoaded", () => {
        (new WALoader()).hide();
        if (pageInteractor.getSectionName() != 'renew') {
            new MembershipReminders(window.membershipObject, getMetaTagContent('locale', "UK")).render();
        }
    });


    document.addEventListener("DOMContentLoaded", () => {
        window.appLocale = new Locale(window.waTmp);
        let promises = [setupLocale(), setupTheme()]
        Promise.all(promises).catch(e => {
            console.log(e);
        }).finally((r) => {
            document.dispatchEvent(new Event('waConfigurationsLoaded'));
        });
    });


    addHandlers(new WAHandlers());
    /**
     * NOTE! The javascript didn't get a complete rewrite. Just a 
     * refactoring and rewrote some knots. Тhe forms javascript as of
     * 28/9/2017. 
     * - MN
     */

    /**
     * The body element
     */
    WebAccounts.bodyElement = $('body'),

        /**
         * The blacked out overlay jQuery element.
         */
        WebAccounts.overlayElements = $('.overlay');

    /**
     * Displayed on different actions, the loader gif is contained within.
     */
    WebAccounts.loaderElement = $('#loader-wrapper');

    WebAccounts.showLoader = function () {
        if (WebAccounts.loaderElement.lenght) {
            WebAccounts.loaderElement.addClass('visible');
        }
    }

    WebAccounts.hideLoader = function () {
        if (WebAccounts.loaderElement.lenght) {
            WebAccounts.loaderElement.removeClass('visible');
        }
    }

    /**
     * When passed an element fades it in, and adds 
     * the 'visible' class, to it.
     * @param {Object} jQueryElement - The manipulated element
     */
    WebAccounts.fadeInElement = function (jQueryElement) {
        jQueryElement.stop().fadeIn(200, function () {
            jQueryElement.addClass('visible');
        });
    };

    /**
     * Fades out and removes the 'visible' class from a passed element
     * @param {Object} jQueryElement - The manipulated element 
     */
    WebAccounts.fadeOutElement = function (jQueryElement) {
        jQueryElement.stop().fadeOut(200, function () {
            jQueryElement.removeClass('visible');
        });
    }

    /**
     * Removes the whitespaces if any are passed.
     */
    WebAccounts.removeWhiteSpaces = function (value) {
        return value ? value.toString().replace(/\s/g, '') : '';
    }

    WebAccounts.imageExists = function (image_url) {
        return $.get(image_url)
            .done(function () {
                return true;
            })
            .fail(function () {
                return false;
            });
    }
    document.addEventListener("waConfigurationsLoaded", () => {

        if (pageInteractor.getSectionName() !== 'reschedule') {
            pageInteractor.simulate('click', (e) => {
                e.simulate('scrollTo');
            });

        } else if (pageInteractor.getSectionName() === 'expand') {
            pageInteractor.click();
        } else {
            let backupRescheduleExecute = setTimeout(() => {
                pageInteractor.simulate('click');
            }, 2000);

            document.addEventListener('obf-client-loaded', (e) => {
                clearTimeout(backupRescheduleExecute);
                pageInteractor.simulate('click');
            })
        }
        if (window.location.pathname == '/create-account' && getQueryStringValue('token')) {
            window.location.href = `/setup-your-online-account${window.location.search}`;
        }
        try {
            (new OBFManager()).open();
        } catch (error) {
            // console.error(error);
        }
        // List of pages that should render the Mobile App banner
        const mobileBannerWhiteList = [
            '/login'
        ];
        const mobileBannerEnabledTemplates = [
            'fantastic-services',
            'uspaah',
        ];

        if (getMetaTagContent('locale') !== "UK") {
            var smart_app_banner_path = window.origin + "/js/libs/smart-app-banner.js";
            var smart_app_script = $("script[src*='" + smart_app_banner_path + "']").length;
            if (smart_app_script) {
                loadSmartBanner();
            }
        } else if (getCookie('go-fantastic-banner') === null
            && mobileBannerWhiteList.includes(window.location.pathname)
            && mobileBannerEnabledTemplates.includes(window.waTmp)
        ) {

            let templateName = 'mobile-banner.html';
            if (window.waTmp == 'uspaah') {
                templateName = 'uspaah-mobile-banner.html';
            }
            // console.log(templateName);/

            let mobileBanner = new MobileAppBanner("js/templates/" + templateName);

            mobileBanner.addBeforeLoadHandlers((context) => {
                const emailField = context.querySelector('input#email'),
                    passwordField = context.querySelector('input#password');

                emailField && emailField.classList.add('invisible');
                passwordField && passwordField.classList.add('invisible');

            });
            mobileBanner.afterClose = (context) => {
                const emailField = context.querySelector('input#email'),
                    passwordField = context.querySelector('input#password');
                passwordField && passwordField.classList.remove('invisible');
                emailField && emailField.classList.remove('invisible') && emailField.focus();
                setCookie('go-fantastic-banner', true, 0.2)
                emailField.focus()
            };

            mobileBanner.handle();
        }

        if (['/create-account', '/login'].includes(window.location.pathname)) {
            const emailField = getElement('input#email');
            const nameField = getElement('input#firstName');
            emailField && emailField.focus();
            nameField && nameField.focus();
        }

        const elements = getElements('[data-wa-action="close-section"]')
        elements && elements.forEach(el => {
            const target = getElement(el.dataset.waTarget);
            el.addEventListener('click', e => {
                e.preventDefault()
                e.stopPropagation();
                deleteElement(target);
            })
        })

        function loadSmartBanner() {
            new SmartBanner({
                daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
                daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
                appStoreLanguage: 'uk', // language code for the App Store (defaults to user's browser language)
                title: 'GoFantastic',
                author: '1st Online Solutions',
                button: 'VIEW',
                store: {
                    ios: 'On the App Store',
                    android: 'In Google Play',
                    windows: 'In Windows store'
                },
                price: {
                    ios: 'FREE',
                    android: 'FREE',
                    windows: 'FREE'
                },
                theme: 'ios' // put platform type ('ios', 'android', etc.) here to force single theme on all device
                // , icon: '' // full path to icon image if not using website icon image
                // , force: 'android' // Uncomment for platform emulation
            });

            $('.smartbanner-button').addClass('noloader'); // fix loader bug
            $('.smartbanner-close').addClass('noloader'); // fix loader bug
        }
        /** Async show all elements that should be visible only on mobile */
        (function () {
            if (isMobile()) {
                let mobileOnlyElements = getElements('[data-mobile-only="true"]');
                let smallBtn = getElements('.button-holder-sm');
                mobileOnlyElements.forEach((e) => {
                    e.classList.remove('hidden');
                })
                smallBtn.forEach((e) => {
                    e.classList.remove('button-holder-sm');
                })
            }
        })()

    });
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

export class WAModal {
    constructor(customOptions = {}) {
        /** Basic options for SWAL modal that implement WA design */
        this.options = {
            customClass: 'wa-pop-up',
            cancelButtonClass: 'btn swal-custom-btn',
            confirmButtonClass: 'btn filled swal-custom-btn',
            buttonsStyling: false,
            reverseButtons: true,
            showCloseButton: true
        };

        this.setOptions(customOptions)
    }

    /**
     * 
     * @param {Array} classes
     */
    appendClasses(classes = []){
        if (classes != null && classes != undefined && classes.length >=1) {
            classes.forEach(cl => {
                this.options.customClass = `${this.options.customClass} ${cl}`;
            });
        }
        
        return this;
    }

    /**
     * Trigger for SWAL modal with given options
     * example {
     *      text: "Yea you`ve done it!"
     * }
     * for more information 
     * @link {https://sweetalert2.github.io/} official SWAL2 documentation
     * 
     * @param {JSON} customOptions 
     */
    setOptions(customOptions = {}) {
        this.options = Object.assign({}, this.options, customOptions);
        return this;
    }
    
    render() {
        return swal(this.options);
    }
}
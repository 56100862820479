(function(WebAccounts, $, undefined) {    
    WebAccounts.dismissButton = $('.notification-dismiss-btn');

    //SECTION
    //Dismiss button
    if (WebAccounts.dismissButton.length < 1) {
        return;    
    }

    /**
     * Dismisses the clicked notification. Slides it up,
     * and sends a request to the "removeNotification" endpoint
     * on the WebAccounts platform. 
     */
    WebAccounts.dismissButtonClick = function(event) {
        WebAccounts.loaderElement.addClass('visible');
        var element = $(this),
            notificationElement = $(element.parents('.row')[0]),
            notificationsHolder = $(element.parents('.content-inner-holder')),
            notificationItems = notificationsHolder.find('.content-inner-item-holder > .row'),
            notificationCounter = notificationsHolder.find('.notification-counter'),
            notificationSidebarCounter = $('.icon-notification-counter.notifications-counter');

        if (notificationElement.length > 0) {
            var messageType = notificationElement
                .find('.notification-message-holder')
                .data('message-type');

            var objectId = '';
            var objectIdContainer = notificationElement.find('.notification-message-holder').data('object-id');
            if(objectIdContainer !== 'undefined'){
                objectId = objectIdContainer
            }

            var requestData = {
                "_token": csrfToken,
                "objectId": objectId, // needed for unratedBookings
                "messageType": messageType,
            };

            $.ajax({
                url: 'removeNotification',
                type: 'POST',
                data: requestData,
                dataType: "json",
                async: true,
                success: function(result) {
                    WebAccounts.loaderElement.removeClass('visible');
                    if (result.status == 'success') {
                        if (notificationItems.length > 1) {
                            notificationElement.slideUp(400, function () {
                                notificationElement.remove();
                                notificationCounter.text(notificationCounter.text() - 1);
                                notificationSidebarCounter.text(notificationItems.length - 1);
                            });
                        } else if (notificationItems.length === 1) {
                            notificationsHolder.slideUp(400, function(){
                                notificationsHolder.remove();
                                notificationSidebarCounter.text(notificationItems.length - 1);
                                if(notificationSidebarCounter.text() < 1){
                                    $('.wa-icon-notifications').removeClass('new-notifications');
                                    $('.empty-notification-page-wrapper').addClass('visible');
                                    notificationSidebarCounter.hide();
                                }

                            });
                        }
                    }
                },
                error: function(result) {
                    WebAccounts.loaderElement.removeClass('visible');
                }
            });

        }

        $('.modal-content').fadeOut();
        $('.overlay').css('display','none');
    };

    WebAccounts.dismissButton.on('click', WebAccounts.dismissButtonClick);
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
const mapTypeToProvider = {
    "braintree_create_3ds": {
        name: 'braintree',
        options: {
            _3ds: true
        }
    },
    "braintree_validate_3ds": {
        name: 'braintree',
        options: {
            _3ds: true
        }
    },
    "braintree_create": {
        name: 'braintree',
        options: {
            _3ds: false
        }
    },
    "stripe_setup_create": {
        name: 'stripe',
        options: {
            intent: 'setup'
        }
    },
    "stripe_payment_create": {
        name: 'stripe',
        options: {
            intent: 'payment'
        }
    },
    "stripe_payment_validate": {
        name: 'stripe',
        options: {
            intent: 'payment'
        }
    }
}

export const getProviderByType = (type) => {
    return mapTypeToProvider.hasOwnProperty(type) ? mapTypeToProvider[type] : {
        name: 'unknown',
        options: null
    };
}
(function(WebAccounts, $, undefined) {

	WebAccounts.sidebarButton = $('.sidebar-button');
	WebAccounts.sidebarElement = $('.sidebar-holder');

	if (WebAccounts.sidebarButton.length < 1) {
		return;
	}

	/**
	 * The sidebar displayed on the mobile/tablet navigation
	 * The following function opens it up.
	 */
	WebAccounts.sidebarButtonClick = function(event) {
		var isOverlayAnimated = WebAccounts.overlayElements.is(':animated'),
			isOverlayVisible = WebAccounts.overlayElements.hasClass('visible');

		if (WebAccounts.sidebarElement.is(':animated')) {
			return;
		}

		if (!WebAccounts.sidebarElement.hasClass('visible')) {
			WebAccounts.sidebarElement.addClass('visible');
			WebAccounts.bodyElement.addClass('sidebar-opened');

			if (!isOverlayAnimated && !isOverlayVisible) {
				WebAccounts.overlayElements.stop().fadeIn(200);
			}

			return;
		}
		
		WebAccounts.sidebarElement.removeClass('visible');
		WebAccounts.bodyElement.removeClass('sidebar-opened');

		if (!isOverlayAnimated && !isOverlayVisible) {
			WebAccounts.overlayElements.stop().fadeOut(200);
		}
	};
	

    WebAccounts.sidebarButton.on('click', WebAccounts.sidebarButtonClick);

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
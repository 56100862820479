(function(WebAccounts, $, undefined) {
    $('.account-settings-holder').on('mouseenter', 'span[class*=remove]', function(e){
		var isLast = $('span[class*=remove]').length <= 1,
			tooltipHolder = $('.tooltip-holder');

		if (isLast) {
			tooltipHolder.stop().fadeIn(200, function() {
				tooltipHolder.addClass('visible');
			});
		}
	});


	$('.account-settings-holder').on('mouseleave', 'span[class*=remove]', function(e){
		var isLast = $('span[class*=remove]').length <= 1,
			tooltipHolder = $('.tooltip-holder');

		if (isLast) {
			tooltipHolder.stop().fadeOut(200, function() {
				tooltipHolder.removeClass('visible');
			});
		}
	});
})(window.WebAccounts = window.WebAccounts || {}, jQuery);
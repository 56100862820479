import { addHandlers, getElement } from "./common/helpers";
(function (WebAccounts, $, undefined) {
    WebAccounts.FreeServices = {
        el: $(".mail-share-handlers"),
        recomendedCount: 0,
        emailType: null,
        isMobile: true,
        othersCount: 0,
        contactList: [],
        contactedListCount: 0,
        customEmailList: [],
        maxRetries: 4,
        waModal: $('.wa-modal'),
        waModalControls: $('.wa-modal-controls'),
        waModalLoader: $('.mini-share-loader'),
        previewMessage: $('#previewMessage'),
        sendButtonDesktop: $('#sendButtonDesktop'),
        contactsWrapper: $("#contactsWrapper"),
        contactRecCount: $("#contactRecCount"),
        contactOthersCount: $("#contactOthersCount"),
        previewButton: $("#previewButton"),
        sendButton: $("#sendButton"),
        selectButton: $(".selectButton"),
        searchBox: $("#emailSearchBox"),
        loadContactsBtn: $("#loadContactsBtn"),
        closeButton: $(".closeButton"),
        allSelected: false,
        refferalURL: $("#reffelarURL").val(),
        GoogleApiConfigs: {
            'client_id': '',
            'scope': ['https://www.googleapis.com/auth/contacts.readonly', 'https://www.googleapis.com/auth/gmail.send']
        },
        EmailContent: {
            'subject': '£10 OFF your first booking with Fantastic Services',
            'body': "Hey,\n\n\
Check out Fantastic Services - the one-stop shop for all home services, where you can book anything from domestic cleaning to handyman, gardening, removal services and more!\n\n\
If you sign up for a Fantastic Account with my link, you'll get £10 OFF your first service.\n\n\
Give it a try! Just click the link or paste the address in your browser.\n\n "
        },
        exists: function () {
            return this.el.length;
        }
    }
    if (!WebAccounts.FreeServices.exists()) {
        return;
    };
    /**
     *
     */
    WebAccounts.FreeServices.initialize = function () {
        // WebAccounts.FreeServices.checkIsMobile();
        WebAccounts.FreeServices.el.each((key, element) => {
            $(element).on('click', WebAccounts.FreeServices.openInviteModal);
        });
        addHandlers(WebAccounts.FreeServices);

        WebAccounts.FreeServices.searchBox.on('keyup', WebAccounts.FreeServices.searchContacts);
        WebAccounts.FreeServices.searchBox.on('focusout', WebAccounts.FreeServices.searchContacts);
        WebAccounts.FreeServices.sendButton.on('click', WebAccounts.FreeServices.sendEmail);
        WebAccounts.FreeServices.sendButtonDesktop.on('click', WebAccounts.FreeServices.sendTo);
        WebAccounts.FreeServices.previewButton.on('click', WebAccounts.FreeServices.previewMail);
        WebAccounts.FreeServices.selectButton.on('click', WebAccounts.FreeServices.toggleAllItems);
        WebAccounts.FreeServices.closeButton.on('click', WebAccounts.FreeServices.toggleModal);
        WebAccounts.FreeServices.contactsWrapper.on('click', WebAccounts.FreeServices.toggleModal).children().click(function (e) {
            return false;
        });
        WebAccounts.FreeServices.waModalDesktop();
        // $('.wa-share-button').on('click', WebAccounts.FreeServices.sendEmail);


        navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? $('body').css("cursor", "pointer") : null;
        let customEmailsInput = getElement("#listCustomEmails");

        if (customEmailsInput) {
            let regex = new RegExp("^w?.+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$");
            customEmailsInput.addEventListener("focusout", function (e) {
                let customEmailList = e.currentTarget.value.split(/[,\/ ]/);
                WebAccounts.FreeServices.customEmailList = customEmailList.filter(email => {
                    return email.length > 1 && regex.test(email);
                });
                WebAccounts.FreeServices.canProceedToPreview();

            })
        }
    };

    WebAccounts.FreeServices.checkIsMobile = function () {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) WebAccounts.FreeServices.isMobile = true;
    }

    WebAccounts.FreeServices.waModalDesktop = function () {
        if (!WebAccounts.FreeServices.isMobile) {
            WebAccounts.FreeServices.waModal.addClass("desktop_mode");
        }
    }
    /**
     *
     */
    WebAccounts.FreeServices.toggleAllItems = function (event) {
        event.preventDefault();
        let flag = true;
        if (WebAccounts.FreeServices.allSelected) {
            flag = false;
            WebAccounts.FreeServices.allSelected = false;
        } else {
            WebAccounts.FreeServices.allSelected = true;
        }
        for (var i = 0; i < WebAccounts.FreeServices.contactList.length; i++) {
            WebAccounts.FreeServices.contactList[i].added = flag;
        }
        $(".contacts-row").find('input[type="checkbox"]').prop('checked', flag)
        $(".contacts-row").removeClass('wa-active');
        $(this).text("Select all");
        if (flag) {
            $(".contacts-row").addClass('wa-active');
            $(this).text("Select none");
        }
    };
    /**
     *
     */
    WebAccounts.FreeServices.getContacts = function (event) {
        event.preventDefault();
        WebAccounts.FreeServices.getContactsList();

    };
    /**
     *
     */
    WebAccounts.FreeServices.searchContacts = function (event) {
        event.preventDefault();
        if (event.which == 13 || (event.hasOwnProperty('type') && event.type == 'focusout')) {
            let bufferArray = [];
            let searchText = $(this).val().toLowerCase();
            if (searchText.length) {
                for (var i = 0; i < WebAccounts.FreeServices.contactList.length; i++) {
                    if (WebAccounts.FreeServices.contactList[i].name.toLowerCase().search(searchText) != -1 || WebAccounts.FreeServices.contactList[i].email.toLowerCase().search(searchText) != -1) {
                        bufferArray.push(WebAccounts.FreeServices.contactList[i]);
                    }
                }
                WebAccounts.FreeServices.generateList([$("#contactsListRec"), $("#contactsListOther")], bufferArray)
            } else {
                WebAccounts.FreeServices.generateList([$("#contactsListRec"), $("#contactsListOther")], WebAccounts.FreeServices.contactList)
            }
        }
    };
    /**
     *
     */
    WebAccounts.FreeServices.getContactsList = function (retries = 1) {
        gapi.auth.authorize(WebAccounts.FreeServices.GoogleApiConfigs, function (gResponse) {

            if (gResponse.hasOwnProperty('error')
                && gResponse['error'] == "popup_closed_by_user") {
                return;
            }

            WebAccounts.FreeServices.fetchContacts(gapi.auth.getToken(), retries);
        });
    };
    /**
     *
     */
    WebAccounts.FreeServices.sendEmail = function () {
        switch (WebAccounts.FreeServices.emailType) {
            case "gmail":
                gapi.auth.authorize(WebAccounts.FreeServices.GoogleApiConfigs, function () {
                    WebAccounts.FreeServices.iniSend(gapi.auth.getToken());
                });
                break;
            case "email":
                WebAccounts.FreeServices.iniSendEmail();
                break;

            default:
                break;
        }

    };
    /**
     *
     */
    WebAccounts.FreeServices.fetchContacts = function (token, retries) {
        let isRecomended;
        let id = 0;

        if (token === null) {
            retries++;
            if (retries <= WebAccounts.FreeServices.maxRetries) {
                WebAccounts.FreeServices.getContactsList(retries);
            } else {
                WebAccounts.Utilities.ShowModal({
                    text: 'Whoops!\n Something went wrong with Google connection!\n Please, try again later!',
                    type: 'error',
                    confirmButtonText: 'OK'
                });
            }

            return false;
        }

        delete token['g-oauth-window'];

        $.ajax({
            url: 'https://www.google.com/m8/feeds/contacts/default/full?alt=json&v=3&max-results=9999',
            // dataType: 'jsonp',
            data: token
        }).done(function (response) {
            WebAccounts.FreeServices.contactList = [];
            WebAccounts.FreeServices.searchBox.removeClass('hidden');
            WebAccounts.FreeServices.loadContactsBtn.addClass('hidden');
            for (var i = 0; i < response.feed.entry.length; i++) {
                if (response.feed.entry[i].gd$email == undefined) {
                    continue
                };
                isRecomended = (!(id % 7)) ? false : false;
                WebAccounts.FreeServices.contactList.push({
                    id: id,
                    recomended: isRecomended,
                    name: response.feed.entry[i].title.$t,
                    email: response.feed.entry[i].gd$email[0].address,
                    added: false
                });
                id++;
            }
            WebAccounts.FreeServices.generateList([$("#contactsListRec"), $("#contactsListOther")], WebAccounts.FreeServices.contactList)
        });
    };
    /**
     *
     */
    WebAccounts.FreeServices.iniSend = function (token) {
        let list = WebAccounts.FreeServices.mailTo();
        WebAccounts.FreeServices.sendButton.attr('disabled', 'disabled')
        WebAccounts.FreeServices.waModalControls.addClass('hidden')
        WebAccounts.FreeServices.waModalLoader.addClass('visible')
        let editedSubject = $('#waPreviewSubject').val();
        let editedMessage = $('#waPreviewMessage').val();

        let message = [
            'To: ', list, '\r\n',
            'Subject: ', '=?UTF-8?B?', window.btoa(editedSubject), '?=', '\r\n\r\n',
            editedMessage
        ].join('');

        delete token['g-oauth-window'];

        $.ajax({
            type: "POST",
            url: "https://www.googleapis.com/upload/gmail/v1/users/me/messages/send?uploadType=multipart",
            headers: {
                Authorization: 'Bearer ' + token.access_token
            },
            contentType: "message/rfc822",
            data: message
        }).always(function () {
            window.dataLayer.push({ "event": "successfulShare", "shareType": "gmail", "shareRecipientsCount": WebAccounts.FreeServices.contactedListCount })

            WebAccounts.FreeServices.toggleModal();
            WebAccounts.FreeServices.searchBox.val('');
            WebAccounts.Utilities.ShowModal({
                text: "Your e-mail has been sent",
                type: 'success',
                confirmButtonText: 'OK'
            });
            WebAccounts.FreeServices.sendButton.removeAttr('disabled')
            WebAccounts.FreeServices.waModalControls.removeClass('hidden')
            WebAccounts.FreeServices.waModalLoader.removeClass('visible')
            WebAccounts.FreeServices.contactedListCount = 0;
        })
    };
    
    /**
     *
     */
    WebAccounts.FreeServices.iniSendEmail = function () {
        WebAccounts.FreeServices.sendButton.attr('disabled', 'disabled')
        WebAccounts.FreeServices.waModalControls.addClass('hidden')
        WebAccounts.FreeServices.waModalLoader.addClass('visible')
        let list = WebAccounts.FreeServices.mailToAsArray();

        let editedSubject = $('#waPreviewSubject').val();
        let editedMessage = $('#waPreviewMessage').val();

        let payload = {
            sendTo: list,
            subject: editedSubject,
            message: editedMessage
        }

        $.ajax({
            type: "POST",
            headers: {
                'X-CSRF-TOKEN': csrfToken,
            },
            url: `${window.location.origin}/api/share`,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(payload)
        }).done(function (response) {
            if (response.data == "success") {
                window.dataLayer.push({ "event": "successfulShare", "shareType": "email", "shareRecipientsCount": WebAccounts.FreeServices.contactedListCount })

                WebAccounts.FreeServices.toggleModal();
                WebAccounts.FreeServices.searchBox.val('');
                WebAccounts.Utilities.ShowModal({
                    text: "Your e-mail has been sent",
                    type: 'success',
                    confirmButtonText: 'OK'
                });
            } else {
                WebAccounts.FreeServices.toggleModal();
                WebAccounts.FreeServices.searchBox.val('');
                WebAccounts.Utilities.ShowModal({
                    text: "Whoops... something went wrong!",
                    type: 'error',
                    confirmButtonText: 'OK'
                });
            }
            WebAccounts.FreeServices.sendButton.removeAttr('disabled')
            WebAccounts.FreeServices.waModalControls.removeClass('hidden')
            WebAccounts.FreeServices.waModalLoader.removeClass('visible')
            WebAccounts.FreeServices.contactedListCount = 0;
        });
    };
    /**
     *
     */
    WebAccounts.FreeServices.generateList = function (containers, list) {
        WebAccounts.FreeServices.recomendedCount = 0;
        WebAccounts.FreeServices.othersCount = 0;
        for (var i = 0; i < containers.length; i++) {
            containers[i].empty()
        }
        for (var i = 0; i < list.length; i++) {
            if (list[i].recomended) {
                WebAccounts.FreeServices.recomendedCount++;
                containers[0].append(`<div class="contacts-row row ${list[i].added ? 'wa-active' : ''}"  data-index="${list[i].id}"><div class="col-xs-1 pos-absolute sm-margin-right-1"><input id="checkBox_${i + 1}" value="checkBox_${i + 1}"  class="checkboxIdent" type="checkbox" ${list[i].added ? 'checked="true"' : ''}></div> <!--  .col-xs-1 --><div class="col-md-3 wa-height-reset padding-col-1">${list[i].name}</div> <!--.col-xs-3 --><div class="col-md-8  padding-col-1-sm">${list[i].email}</div> <!--.col-xs-8 --></div> <!--  .contacts-row -->`)
                continue;
            }
            WebAccounts.FreeServices.othersCount++;
            containers[1].append(`<div class="contacts-row row ${list[i].added ? 'wa-active' : ''}"  data-index="${list[i].id}"><div class="col-xs-1 pos-absolute sm-margin-right-1"><input id="checkBox_${i + 1}" value="checkBox_${i + 1}"  class="checkboxIdent" type="checkbox" ${list[i].added ? 'checked="true"' : ''}></div> <!--  .col-xs-1 --><div class="col-md-3 wa-height-reset padding-col-1">${list[i].name}</div> <!--.col-xs-3 --><div class="col-md-8  padding-col-1-sm">${list[i].email}</div> <!--.col-xs-8 --></div> <!--  .contacts-row -->`)
        }
        $(".contacts-row").on('click', WebAccounts.FreeServices.addToSendingList);
        WebAccounts.FreeServices.contactRecCount.html(WebAccounts.FreeServices.recomendedCount);
        WebAccounts.FreeServices.contactOthersCount.html(WebAccounts.FreeServices.othersCount);
    };

    WebAccounts.FreeServices.mailToAsArray = function () {
        WebAccounts.FreeServices.contactedListCount = 0;
        let list = [];
        for (var i = 0; i < WebAccounts.FreeServices.contactList.length; i++) {
            if (WebAccounts.FreeServices.contactList[i].added) {
                list.push(WebAccounts.FreeServices.contactList[i].email);
                WebAccounts.FreeServices.contactedListCount++;
            }
        }
        WebAccounts.FreeServices.contactedListCount += WebAccounts.FreeServices.customEmailList.length;

        return [...list, ...WebAccounts.FreeServices.customEmailList];
    };

    WebAccounts.FreeServices.mailTo = function () {
        WebAccounts.FreeServices.contactedListCount = 0;

        let list = '';
        for (var i = 0; i < WebAccounts.FreeServices.contactList.length; i++) {
            if (WebAccounts.FreeServices.contactList[i].added) {
                list += `,${WebAccounts.FreeServices.contactList[i].email}`;
                WebAccounts.FreeServices.contactedListCount++;
            }
        }

        WebAccounts.FreeServices.customEmailList.forEach((email) => {
            list += `,${email}`;
        })

        WebAccounts.FreeServices.contactedListCount += WebAccounts.FreeServices.customEmailList.length;

        list = list.substring(1)

        return list;
    };
    /**
     *
     */
    WebAccounts.FreeServices.openInviteModal = function (e) {
        e.preventDefault();
        $(document).scrollTop();

        WebAccounts.FreeServices.emailType = e.currentTarget.getAttribute("data-share-type");

        WebAccounts.FreeServices.toggleModal();
    };
    /**
     *
     */
    WebAccounts.FreeServices.toggleModal = function () {
        $("body").toggleClass('modal-opened')
        WebAccounts.FreeServices.contactsWrapper.toggleClass('hidden');

        WebAccounts.FreeServices.canProceedToPreview();

        WebAccounts.FreeServices.waModal.removeClass('preview_mode');
    };

    WebAccounts.FreeServices.canProceedToPreview = function () {
        WebAccounts.FreeServices.previewButton.removeAttr("disabled")
        if (WebAccounts.FreeServices.mailTo().length == 0) {
            WebAccounts.FreeServices.previewButton.attr("disabled", "1")
        }
    }
    /**
     *
     */
    WebAccounts.FreeServices.addToSendingList = function (e) {
        let $check = $(this).find('input[type="checkbox"]');
        if (!WebAccounts.FreeServices.contactList[$(this).data('index')].added) {
            WebAccounts.FreeServices.contactList[$(this).data('index')].added = true;
        } else {
            WebAccounts.FreeServices.contactList[$(this).data('index')].added = false;
        }
        $check.prop('checked', WebAccounts.FreeServices.contactList[$(this).data('index')].added);
        $(this).toggleClass('wa-active');
        WebAccounts.FreeServices.canProceedToPreview()
    };
    /**
     *
     */
    WebAccounts.FreeServices.previewMail = function (e) {
        e.preventDefault();


        WebAccounts.FreeServices.waModal.toggleClass('preview_mode');
        WebAccounts.FreeServices.previewMessage.empty();
        WebAccounts.FreeServices.previewMessage.append(`<input type='text' class="wa-input push-up push-down" id="waPreviewSubject" value="${gmailReferral.subject}">`);
        WebAccounts.FreeServices.previewMessage.append(`<p class="pre_wrap_enable"><textarea class="wa-textarea wa-input push-up" id="waPreviewMessage">${gmailReferral.body}</textarea></p>`);

    };

    WebAccounts.FreeServices.sendTo = function (e) {
        e.preventDefault();
        let list = WebAccounts.FreeServices.mailTo();

        if (gmailReferral === null) {
            window.open(`//mail.google.com/mail/?view=cm&fs=1&tf=1&to=${list}&su=${encodeURI(WebAccounts.FreeServices.EmailContent.subject)}&body=${encodeURI(WebAccounts.FreeServices.EmailContent.body)}${WebAccounts.FreeServices.refferalURL}`);
        } else {
            window.open(`//mail.google.com/mail/?view=cm&fs=1&tf=1&to=${list}&su=${encodeURI(gmailReferral.subject)}&body=${encodeURI(gmailReferral.body)}`);
        }

        WebAccounts.FreeServices.toggleModal();
        WebAccounts.FreeServices.searchBox.val('');
    };

    /**
     *
     */
    WebAccounts.FreeServices.setGoogleId = function (googleKey) {
        WebAccounts.FreeServices.GoogleApiConfigs.client_id = (googleKey) ? googleKey : '251206388-sqduuu5r3r6k04u0j4120u3kpa52flri.apps.googleusercontent.com';
    };
    /**
     *
     */
    WebAccounts.FreeServices.initialize();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

(function (WebAccounts, $, undefined) {
    WebAccounts.Drawer = {
        drawers: $("[data-drawer]"),
        exists: function () {
            return true;
        }
    }
    if (!WebAccounts.Drawer.exists()) {
        return;
    };

    /**
     * Initialize callback click function on every drawer
     */
    WebAccounts.Drawer.ini = function () {
        this.drawers.on('click', WebAccounts.Drawer.clickHandler);
    };
    WebAccounts.Drawer.initInElement = (element) => {
        let elements = element.querySelector('[data-drawer]');
        elements = Array.isArray(elements) ? elements : [elements];
        if (elements.length) {
            elements.forEach(el => {
                if (el) {
                    el.addEventListener('click', WebAccounts.Drawer.clickHandler);
                }
            })
        }
    }

    WebAccounts.Drawer.clickHandler = (e) => {
        if (e.currentTarget.dataset.drawerType !== 'blocked') {
            e.preventDefault();
            const handler = $(e.currentTarget);
            let target = $(handler.data('target')),
                icon = WebAccounts.Drawer.getIconElement(handler),
                customIconClass = $(handler.attr('target-css-icon')),
                customClass = handler.attr('custom-class');

            if (target.attr('drawer-state') == 'opened') {
                const closedText = handler.attr('data-drawer-closed-text');

                WebAccounts.Drawer.rotateIcon(icon, 0);
                WebAccounts.Drawer.toggleCustomClass(customIconClass, customClass);
                target.attr('drawer-state', 'closed');
                target.slideUp(200);
                if (closedText) {
                    handler.val(closedText);
                    handler.html(closedText);
                }
            } else {
                const openText = handler.attr('data-drawer-open-text');
                WebAccounts.Drawer.rotateIcon(icon, 45);
                WebAccounts.Drawer.toggleCustomClass(customIconClass, customClass);
                target.attr('drawer-state', 'opened');
                target.slideDown(200);
                if (openText) {
                    handler.val(openText);
                    handler.html(openText);
                }
            }

            if (handler.attr('data-drawer-type') === 'once') {
                handler.attr('data-drawer-type', 'blocked')
            }
        }
    }

    /**
     * @param {jQueryElement} handler 
     * 
     * @return {jQueryElement} icon
     */
    WebAccounts.Drawer.getIconElement = function (handler) {
        let icon = $(handler.find('span.icon-plus'));
        if (!icon.length) {
            icon = $(handler.data('icon-target'));
        }
        return icon;
    }

    /**
     * @param {jQueryElement} target 
     * @param {Integer} degrees 
     */
    WebAccounts.Drawer.rotateIcon = function (target, degrees) {
        if (!target.length) {
            return;
        }
        degrees = (degrees !== undefined) ? degrees : 45;
        target.css({ WebkitTransform: 'rotate(' + degrees + 'deg)' });
        // For Mozilla browser: e.g. Firefox
        target.css({ '-moz-transform': 'rotate(' + degrees + 'deg)' });
    }

    /**
     * @param {jQueryElement} target 
     * @param {Integer} degrees 
     */
    WebAccounts.Drawer.toggleCustomClass = function (target, customClass) {
        if (!target.length) {
            return;
        }
        if (target.hasClass(customClass)) {
            target.removeClass(customClass);
        } else {
            target.addClass(customClass);
        }
    }

    WebAccounts.Drawer.ini();
})(window.WebAccounts = window.WebAccounts || {}, jQuery);

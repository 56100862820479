import { isCallable } from "../common/helpers";

export class StripeProvider {
    constructor(pub_key, container = null, submitBtn = null) {
        this.containerElement = container;
        this.submitBtn = submitBtn;
        this.shouldSubmit = true;
        this.instance = undefined;
        this.cardModule = undefined;
        this.elementsModule = undefined;
        this.successHandler = undefined;
        this.errorHandler = undefined;
        this.validFormHandler = undefined;
        this.invalidFormHandler = undefined;
        this.startedProcessing = undefined;
        this.endProcessing = undefined;
        this.customValidator = undefined;
        this.__intent = '__processSetup' // default Setup intent | compatibility 
        this.__canSubmit = false;
        this.__boot(pub_key);
    }

    onStartProcessing(callback) {
        this.startedProcessing = callback;
    }

    onEndProcessing(callback) {
        this.endProcessing = callback;
    }

    setChallengeToken(callback) {
        this.__getClientSecret = callback;
    }

    setIntent(intent) {
        switch (intent) {
            case 'setup':
                this.__intent = '__processSetup'
                break
            case 'payment':
                this.__intent = '__processPayment'
                break
            default:
                break

        }

    }

    onSuccess(callback) {
        this.successHandler = callback;
    }

    onError(callback) {
        this.errorHandler = callback;
    }

    onValidForm(callback) {
        this.validFormHandler = callback;
    }

    onInvalidForm(callback) {
        this.invalidFormHandler = callback;
    }

    setShouldSubmit(flag) {
        this.shouldSubmit = flag;
    }

    setCustomValidator(callback) {
        this.customValidator = callback;
    }

    processSecret = (secret) => { // Currently this method can be use only for processing Card Payment that is returned configured with card
        return this.instance.confirmCardPayment(secret);
    }

    __boot(pub_key) {
        // Initialize Stripe lib with public key
        this.instance = Stripe(pub_key)
        if (this.containerElement !== null && this.submitBtn !== null) {
            // Get the elements module from the Stripe instance
            this.elementsModule = this.instance.elements();
            // Create the card module
            this.cardModule = this.elementsModule.create('card', { hidePostalCode: true });
            // Add handler
            this.cardModule.on('change', this.__validation.bind(this));
            // Render the card module in specific HTML node
            this.cardModule.mount(this.containerElement);
            // Add Event handlers for card submission
            this.__attachEventHandlers();
        }
    }

    __attachEventHandlers() {
        this.submitBtn.addEventListener('click', this.__processCard.bind(this));
    }

    async __processCard(event) {
        event.preventDefault()
        event.stopPropagation()
        if (this.__canSubmit === false || this.shouldSubmit === false) {
            return;
        }
        let clientSecretToken = this.__getClientSecret;
        if (isCallable(this.__getClientSecret)) {
            clientSecretToken = await this.__getClientSecret();
        }

        if (isCallable(this.startedProcessing)) {
            this.startedProcessing()
        }

        if (clientSecretToken && isCallable(this[this.__intent])) {
            try {
                let response = await this[this.__intent](clientSecretToken)
                if (isCallable(this.successHandler)) {
                    this.successHandler(response)
                }
            } catch (error) {
                if (isCallable(this.errorHandler)) {
                    this.errorHandler(error)
                }
            }
        }

        if (isCallable(this.endProcessing)) {
            this.endProcessing()
        }
    }

    __processSetup = (secret) => {
        return this.instance.confirmCardSetup(secret,
            {
                payment_method: {
                    card: this.cardModule,
                }
            }
        );
    }
    __processPayment = (secret) => {
        return this.instance.confirmCardPayment(secret, {
            payment_method: {
                card: this.cardModule,
            }
        });
    }
    __validation(event) {
        this.submitBtn.classList.add("disabled")
        this.__canSubmit = false;
        let customValidationResult = true
        if (isCallable(this.invalidFormHandler)) {
            this.invalidFormHandler();
        }
        if (isCallable(this.customValidator)) {
            customValidationResult = this.customValidator(event);
        }

        if (event.complete === true && customValidationResult) {
            this.__canSubmit = true;
            this.submitBtn.classList.remove("disabled");
            this.submitBtn.style.disable = null;
            if (isCallable(this.validFormHandler)) {
                this.validFormHandler();
            }
        }
    }
    unblock() { }
    activate() { }
    deactivate() { }
}

(function (WebAccounts, $, undefined) {
    if (!WebAccounts.hasOwnProperty('RebookTask')) {
        WebAccounts.RebookTask = {
            elements: $('[data-trigger="rebook"]'),
            exist: function () {
                return this.elements.length > 0;
            }
        };
    }

    if (!WebAccounts.RebookTask.hasOwnProperty('exist') ||
        !WebAccounts.RebookTask.exist()) {
        return;
    }

    /** Constructor */
    WebAccounts.RebookTask.construct = function () {
        WebAccounts.RebookTask.elements.on('click', WebAccounts.RebookTask.rebook);
    }

    /**
     * Activates rebook OBF with given obfOptions
     * 
     * @param {Event} event 
     */
    WebAccounts.RebookTask.rebook = function (event) {
        event.preventDefault();

        const currentRebookButton = $(event.currentTarget),
            currentRebookCard = currentRebookButton.parents('.service-meta-information'),
            currentServiceCategoryId = (currentRebookCard.data('serviceCategoryId') === undefined) ? "" : currentRebookCard.data('serviceCategoryId'),
            currentServiceTypeId = currentRebookCard.data('serviceTypeId') || "",
            addressIdRegex = /\[[\d\,]*\]/g;

        let currentAddressId = currentRebookCard.data('addressId') || "";

        if (addressIdRegex.test(currentAddressId)) {
            currentAddressId = JSON.parse(currentAddressId);
        } else {
            currentAddressId = "";
        }

        if (Array.isArray(currentAddressId)) {
            currentAddressId = currentAddressId[0];
        }

        if (currentServiceCategoryId.toString().length && currentServiceTypeId) {
            
            if (typeof obfOptions === 'object') {
                obfOptions.defaultServiceCategoryId = currentServiceCategoryId;
                obfOptions.defaultServiceTypeId = currentServiceTypeId;
                obfOptions.addressId = currentAddressId;
            }

            currentRebookCard.attr('data-book-btn', "true").click().removeAttr('data-book-btn');
        }
    };

    WebAccounts.RebookTask.construct();

})(window.WebAccounts = window.WebAccounts || {}, jQuery);
